import base from "./base";

// used to query the API
// injected into translations
// used to query lp banner image at:
// public/images/{{country.name}}/banner.jpg
// public/images/{{country.name}}/banner@2x.jpg
const country = {
  country: {
    isos: {
      country: "Europe+",
      network: "MT",
    },
    isRegional: true,
    name: "Malta",
    region: "Europe",
  },
};

// used to personalize theme colors
const theme = {
  theme: {
    colors: {
      logo: {
        country: "#d43939",
        frame: "#3A4155",
        primary: "#d43939",
        secondary: "#9b6c0a",
      },
      lp: {
        backgroundPattern: "linear-gradient(121deg, #d43939 20%, #9b6c0a 80%)",
        benefitsIconDetail: "#FFC107",
        primaryButton: "#dd880c",
        primaryButtonHover: "#b26b05",
        purchaseFormLineDetail: "#dd880c",
      },
    },
    custom: {
      backgroundColor: "#F7F7F7",
      banner: {
        backgroundPosition: "right -35px",
        backgroundPositionLarge: "right -35px",
        backgroundPositionMedium: "right -35px",
        useBannerAsPattern: {
          backgroundPosition: "center -35px",
          isEnabled: "true",
          opacity: "0.4",
        },
      },
      footerColor: "#444444",
      foregroundColor: "#F3F3F3",
      headerColor: "#FFF",
    },
  },
};

export default {
  ...base,
  ...country,
  ...theme,
};
