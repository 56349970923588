import { useStyletron } from "baseui";
import { Badge, COLOR } from "baseui/badge";
import { Block } from "baseui/block";
import React from "react";

import { usePromotions } from "../../hooks/use-promotions";

export const PromoButton = ({
  bottom,
  children,
  content,
  left,
  right,
  style,
  top,
}: {
  bottom?: string;
  children: React.ReactNode;
  content: string;
  left?: string;
  right?: string;
  style?: React.CSSProperties;
  top?: string;
}): React.ReactNode => {
  const [, theme] = useStyletron();
  const { shouldSurfacePromos } = usePromotions();

  return (
    <Block
      style={{
        marginTop: theme.sizing.scale600,
        paddingLeft: theme.sizing.scale900,
        paddingRight: theme.sizing.scale900,
        paddingTop: theme.sizing.scale600,
        position: "relative",
        width: "fit-content",
        ...style,
      }}
    >
      {shouldSurfacePromos() ? (
        <Badge
          color={COLOR.negative}
          content={content}
          overrides={{
            Badge: {
              style: {
                border: "2px solid #FFF",
                bottom: bottom || "inherit",
                display: "flex",
                justifyContent: "center",
                left: left || "inherit",
                minHeight: "24px",
                position: "absolute",
                right: right || "0",
                top: top || "0",
              },
            },
          }}
        />
      ) : null}
      {children}
    </Block>
  );
};
