import { TOptionsBase } from "i18next";
import { $Dictionary } from "i18next/typescript/helpers";
import { useTranslation as useBaseTranslation } from "react-i18next";

export const useTranslation = () => {
  const { t } = useBaseTranslation();
  return {
    translate: (
      key: string,
      options?: (TOptionsBase & $Dictionary) | undefined,
    ) => {
      const customOptions = {
        country: t("country.name"),
        ...(options || {}),
      };
      return t(key, customOptions);
    },
  };
};
