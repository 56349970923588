import { Avatar } from "baseui/avatar";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { FormControl } from "baseui/form-control";
import { HeadingSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { PurchaseHistoryResponseT } from "../../api/types";
import { useTranslation } from "../../hooks/translate";
import { Column, Row } from "../containers";
import If from "../if";
import Loading from "../loading";

export const CustomerCard = ({
  isDeclined,
  isParentLoading,
  logout,
  orderId,
  purchaseDetails,
}: {
  isDeclined: boolean;
  isParentLoading: boolean;
  logout: (sessionExpired: boolean, redirectTo: string) => void;
  orderId?: string;
  purchaseDetails: PurchaseHistoryResponseT | undefined;
}): React.ReactNode => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      title={
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <HeadingSmall margin={0}>
            {translate("manage.cards.customer.title")}
          </HeadingSmall>
          <Button kind="primary" onClick={() => logout(false, "/")} size="mini">
            {translate("manage.buttons.logout")}
          </Button>
        </Row>
      }
    >
      <Loading isLoading={isParentLoading && !purchaseDetails}>
        <StyledBody>
          <Row>
            <Column style={{ marginRight: "16px" }}>
              <Avatar
                name={purchaseDetails?.customer?.name || ""}
                size={"scale1200"}
              />
            </Column>
            <Column>
              <ParagraphMedium margin={0}>
                {purchaseDetails?.customer?.name}
              </ParagraphMedium>
              <LabelMedium margin={0}>
                {purchaseDetails?.customer?.email}
              </LabelMedium>
            </Column>
          </Row>
        </StyledBody>
        <If condition={!isDeclined}>
          <StyledAction>
            <FormControl
              label={() => translate("manage.cards.customer.orders")}
            >
              <Row style={{ flexWrap: "wrap" }}>
                {purchaseDetails?.orders?.map((order) => (
                  <Button
                    key={order.id}
                    kind={order.id === orderId ? KIND.primary : KIND.secondary}
                    onClick={() => {
                      navigate(`/order/${order.id}`, { replace: true });
                    }}
                    shape={SHAPE.pill}
                    size={SIZE.mini}
                    style={{ marginBottom: "8px", marginRight: "8px" }}
                  >
                    {order.id}
                  </Button>
                ))}
              </Row>
            </FormControl>
          </StyledAction>
        </If>
      </Loading>
    </Card>
  );
};
