import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { HeadingLarge, ParagraphMedium } from "baseui/typography";
import * as React from "react";

import { Pattern } from "../../../app/pattern";
import { useTranslation } from "../../../hooks/translate";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import {
  FontFamily,
  LP_BUTTON_PRIMARY,
  STRONG_TEXT_SHADOW,
  USE_FONT,
} from "../../overrides";
import { PromoButton } from "../../promo-button";
import { STYLES } from "./styles";

export const LpBannerSection = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();

  const useBanner = Boolean(
    translate("theme.custom.banner.useBannerAsPattern.isEnabled") === "true",
  );

  return (
    <Row className={css(STYLES.wrapper)}>
      <Column className={css(STYLES.pattern(theme))}>
        <Pattern useBanner={useBanner} />
        <Column className={css(STYLES.contentWrapper(theme))}>
          <HeadingLarge
            color={theme.colors.contentInversePrimary}
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "38px"),
              marginBottom: theme.sizing.scale300,
              textShadow:
                screenWidth > theme.breakpoints.small
                  ? theme.lighting.shadow700
                  : STRONG_TEXT_SHADOW("#696969"),
            }}
          >
            {translate("lp.banner.headingFirstLine")}
            <br />
            {translate("lp.banner.headingSecondLine")}
          </HeadingLarge>
          <ParagraphMedium
            color={theme.colors.contentInversePrimary}
            style={{
              ...USE_FONT(FontFamily.DMSans, 400, "24px", "20px"),
              textShadow:
                screenWidth > theme.breakpoints.small
                  ? theme.lighting.shadow700
                  : STRONG_TEXT_SHADOW("#696969"),
            }}
          >
            {translate("lp.banner.caption")}
          </ParagraphMedium>
          <Row
            style={{
              width: "100%",
            }}
          >
            <PromoButton
              content="Special Offer"
              style={{ paddingLeft: "0", paddingRight: theme.sizing.scale1200 }}
            >
              <Button
                onClick={() => {
                  Mixpanel.track("CTA", {
                    action: "click",
                    element: "get-your-esim-today",
                    section: "banner",
                  });
                  scrollWithOffset("#purchase-form", -240);
                }}
                overrides={LP_BUTTON_PRIMARY(theme, {
                  ...USE_FONT(FontFamily.DMSans, 500, "24px", "18px"),

                  width: "fit-content",
                })}
              >
                {translate("lp.banner.button")}
              </Button>
            </PromoButton>
          </Row>
        </Column>
      </Column>
      <Column
        className={css(
          STYLES.banner(
            theme,
            `${window.location.origin}/images/${translate("country.name").toLocaleLowerCase()}/banner.jpg`,
            `${window.location.origin}/images/${translate("country.name").toLocaleLowerCase()}/banner@2x.jpg`,
            translate("theme.custom.banner.backgroundPosition"),
            translate("theme.custom.banner.backgroundPositionLarge"),
            translate("theme.custom.banner.backgroundPositionMedium"),
          ),
        )}
      />
    </Row>
  );
};
