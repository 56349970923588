import { Theme } from "baseui";

export const STYLES = {
  benefitsWrapper: (theme: Theme) => ({
    borderRadius: theme.sizing.scale600,
    marginLeft: theme.sizing.scale300,
    marginTop: theme.sizing.scale300,
    [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
      backgroundColor: "#FDF0CC",
      maxWidth: "unset",
      padding: theme.sizing.scale900,
    },
    [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      backgroundColor: "#FDF0CC",
      padding: theme.sizing.scale600,
    },
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: "none",
    },
  }),
  container: (theme: Theme) => ({
    justifyContent: "center",
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale900}`,
  }),
  heading: (theme: Theme) => ({
    [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
      maxWidth: "480px",
    },
    [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      maxWidth: "320px",
    },
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      maxWidth: "100%",
    },
  }),
  headingWrapper: (theme: Theme) => ({
    maxWidth: "100%",
    [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
      alignItems: "start",
      marginBottom: theme.sizing.scale600,
      marginLeft: theme.sizing.scale600,
      marginTop: "0px",
      maxWidth: "697px",
      textAlign: "left",
    },
    [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      alignItems: "start",
      marginLeft: theme.sizing.scale600,
      marginTop: "0px",
      maxWidth: "697px",
      textAlign: "left",
    },
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      alignItems: "center",
      marginTop: "unset",
      textAlign: "center",
    },
  }),
  image: (theme: Theme) => ({
    [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
      height: "400px",
      width: "258px",
    },
    [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      height: "266px",
      width: "172px",
    },
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      height: "266px",
      marginTop: theme.sizing.scale900,
      width: "172px",
    },
  }),
  textWrapper: (theme: Theme) => ({
    height: "100%",
    justifyContent: "center",
    maxWidth: "1284px",
    [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
      marginLeft: theme.sizing.scale600,
      marginTop: "0px",
    },
    [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      marginLeft: theme.sizing.scale600,
      marginTop: "0px",
    },
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      marginLeft: "0px",
      marginTop: theme.sizing.scale600,
    },
  }),
  wrapper: (theme: Theme) =>
    ({
      maxWidth: "1578px",
      [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
        flexDirection: "row",
      },
      [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
        flexDirection: "row",
      },
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "center",
        flexDirection: "column-reverse",
      },
    }) as const,
};
