import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme) => ({
    alignItems: "center",
    background: "#FFF",
    justifyContent: "center",
    padding: theme.sizing.scale950,
  }),
  link: {
    ":hover": {
      color: "blue", // Change color to blue on hover
      textDecoration: "underline", // Keep underline on hover
    },
    color: "#000", // Set hyperlink color to black by default
    cursor: "pointer",
    textDecoration: "underline", // Keep the underline
  },
  stepImageDiv: (theme: Theme) => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.sizing.scale900,
    marginTop: theme.sizing.scale300,
  }),
  stepList: (theme: Theme) => ({
    ...theme.typography.ParagraphLarge,
    paddingLeft: "5px",
  }),
  textWrapper: (theme: Theme) => ({
    marginBottom: theme.sizing.scale600,
  }),
  wrapper: {
    maxWidth: "700px",
  },
};
