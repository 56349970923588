import React from "react";

import { StripeWalletContextT } from "./types";

export const StripeWalletContext = React.createContext<StripeWalletContextT>({
  setSupportWallet: (supportWallet) => {
    console.log(supportWallet);
  },
  supportWallet: false,
});

export const StripeWalletProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [supportWallet, setSupportWallet] = React.useState(false);

  return (
    <StripeWalletContext.Provider
      value={{
        setSupportWallet,
        supportWallet,
      }}
    >
      {children}
    </StripeWalletContext.Provider>
  );
};
