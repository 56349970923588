import { useStyletron } from "baseui";
import { Accordion, Panel } from "baseui/accordion";
import { Block } from "baseui/block";
import { FILL, Tab, Tabs } from "baseui/tabs-motion";
import { HeadingMedium, HeadingSmall, ParagraphLarge } from "baseui/typography";
import * as React from "react";

import { CompatibleDevices } from "../../components/compatible-devices";
import { Column, Row } from "../../components/containers";
import { FaqSection } from "../../components/landing-page/faq-section";
import MasterPage from "../../components/master-page";
import { FontFamily, USE_FONT } from "../../components/overrides";
import { PhoneFrame } from "../../components/phone-frame";
import { Mixpanel } from "../../utils/mixpanel";
import * as images from "./images"; // Import all images here
import { STYLES } from "./styles";

export const EsimInstallationGuide = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const [activeTabKey, setActiveTabKey] = React.useState<React.Key>("0");
  const [isCompatibleDeviceListOpen, setIsCompatibleDeviceListOpen] =
    React.useState(false);

  return (
    <MasterPage>
      <CompatibleDevices
        isOpen={isCompatibleDeviceListOpen}
        setIsOpen={setIsCompatibleDeviceListOpen}
      />
      <Row className={css(STYLES.container(theme))}>
        <Column className={css(STYLES.wrapper)}>
          <Column className={css(STYLES.textWrapper(theme))}>
            <div
              style={{
                marginBottom: theme.sizing.scale600,
                textAlign: "center", // Centers the text horizontally within the container
              }}
            >
              <HeadingMedium margin={0}>
                {"eSIM Installation Guide"}
              </HeadingMedium>
            </div>
            <ParagraphLarge marginBottom={0}>
              {"The easiest way to install your eSIM is to "}
              <strong>
                use your phone camera to scan the installation QR code
              </strong>
              {
                " provided by us via email or through our website, and follow the steps from there. We also have detailed guides below."
              }
            </ParagraphLarge>
            <Column>
              <ParagraphLarge marginBottom={0}>
                {
                  "First, check the points below to make sure your device is ready for the eSIM installation."
                }
              </ParagraphLarge>
              <ul>
                <li style={{ marginBottom: theme.sizing.scale600 }}>
                  <ParagraphLarge margin={0}>
                    <strong>A strong internet connection</strong>
                    {
                      " - Ideally you will need to be connected to Wi-Fi or have a good internet connection."
                    }
                  </ParagraphLarge>
                </li>
                <li style={{ marginBottom: theme.sizing.scale600 }}>
                  <ParagraphLarge margin={0}>
                    <strong>An eSIM compatible device</strong>
                    {
                      " - If you are unsure whether your device is compatible with eSIMs, please check our "
                    }
                    <a
                      className={css(STYLES.link)}
                      onClick={() => {
                        setIsCompatibleDeviceListOpen(true);
                      }}
                    >
                      Device Compatibility Guide
                    </a>
                    {"."}
                  </ParagraphLarge>
                </li>
                <li style={{ marginBottom: theme.sizing.scale600 }}>
                  <ParagraphLarge margin={0}>
                    <strong>The device is network unlocked</strong>
                    {
                      " - if you are unsure whether or not your device is locked to a certain network, please check with your carrier."
                    }
                  </ParagraphLarge>
                </li>
              </ul>
            </Column>
            <ParagraphLarge marginTop={0}>
              {"Do not forget to "}
              <strong>turn ON Data Roaming</strong>
              {
                "on your Stay Connected eSIM once the installation is complete, and to "
              }
              <strong>turn OFF Data and Data Roaming</strong>
              {" on your original SIM to avoid overseas charges."}
            </ParagraphLarge>
          </Column>
          <Column>
            <Tabs
              activateOnFocus
              activeKey={activeTabKey}
              fill={FILL.fixed}
              onChange={({ activeKey }) => {
                setActiveTabKey(activeKey);
              }}
            >
              <Tab title={"iOS Devices"}>
                <HeadingSmall
                  marginBottom={theme.sizing.scale600}
                  marginTop={0}
                >
                  {"iOS eSIM Installation Guides"}
                </HeadingSmall>
                <Accordion
                  onChange={({ expanded }) => {
                    if (expanded && expanded.length) {
                      Mixpanel.track("InstallGuideExpand", {
                        action: "click",
                        element: "accordion",
                        id: expanded[0],
                      });
                    }
                  }}
                  overrides={{
                    Header: {
                      style: {
                        ...USE_FONT(
                          FontFamily.DMSansMedium,
                          500,
                          "20px",
                          "16.5px",
                        ),
                        border: `1px solid #000`,
                        borderRadius: theme.sizing.scale300,
                      },
                    },
                    PanelContainer: {
                      style: {
                        borderBottomStyle: "unset",
                        marginBottom: theme.sizing.scale300,
                      },
                    },
                  }}
                >
                  <Panel
                    key={"qr-install-ios"}
                    title={"[iOS] Install with QR code"}
                  >
                    <Column>
                      <ParagraphLarge
                        style={{
                          fontWeight: "bold",
                          marginBottom: theme.sizing.scale900,
                          marginTop: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {
                          "Follow the steps below to install your eSIM on iOS with the QR code"
                        }
                      </ParagraphLarge>
                      <Block>
                        <ol className={css(STYLES.stepList(theme))}>
                          <li>
                            {"Go to "}
                            <strong>{"Settings"}</strong>
                            {" → "}
                            <strong>{"Mobile Data"}</strong>
                            {" or "}
                            <strong>{"Cellular Data"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall1}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Add eSIM"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall2}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Use QR Code"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall3}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Scan the QR code you received."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall4}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Continue"}</strong>
                            {" and wait for up to 10 minutes for activation. "}
                            <strong>
                              {
                                "Do NOT close this window else the installation may fail."
                              }
                            </strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall5}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Done"}</strong>
                            {" to complete the installation."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall6}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Configure the following settings:"}
                            {" Set "}
                            <strong>{"Default Line"}</strong>
                            {" to "}
                            <strong>{"Primary"}</strong>
                            {" → Tap "}
                            <strong>{"Continue"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall7}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Set "}
                            <strong>{"Mobile Data"}</strong>
                            {" to your eSIM (e.g., 'Business')."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall8}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Go back to "}
                            <strong>{"Settings"}</strong>
                            {" → Tap "}
                            <strong>{"Mobile Data"}</strong>
                            {" or "}
                            <strong>{"Cellular Data"}</strong>
                            {
                              ". Tap on the eSIM you want to check Data Roaming."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall9}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Toggle "}
                            <strong>{"Data Roaming"}</strong>
                            {" to "}
                            <strong>{"On"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall10}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Ensure that "}
                            <strong>{"Data Roaming"}</strong>
                            {
                              " is correctly configured (set to ON as below) for your eSIM."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosQrInstall11}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"The eSIM is now installed and ready for use."}
                          </li>
                        </ol>
                      </Block>
                    </Column>
                  </Panel>
                  <Panel
                    key={"manual-install-ios"}
                    title={"[iOS] Install manually"}
                  >
                    <Column>
                      <ParagraphLarge
                        style={{
                          fontWeight: "bold",
                          marginBottom: theme.sizing.scale900,
                          marginTop: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {
                          "Follow the steps below to manually install your eSIM on iOS"
                        }
                      </ParagraphLarge>
                      <Block>
                        <ol className={css(STYLES.stepList(theme))}>
                          <li>
                            {"Go to "}
                            <strong>{"Settings"}</strong>
                            {" → "}
                            <strong>{"Mobile Data or Cellular Data"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall1}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Add eSIM"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall2}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Enter Details Manually"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall3}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Enter the "}
                            <strong>{"SMDP+ Address"}</strong>
                            {" and "}
                            <strong>{"SMDP+ Activation Code"}</strong>
                            {
                              ", which you have received via email (you can also check on your Manage Order page). The code will end up looking something similar to the screenshot below. Leave the confirmation code section blank."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall4}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Continue"}</strong>
                            {" to begin the installation process. "}
                            <strong>
                              {
                                "Do NOT close this window or the installation may fail."
                              }
                            </strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall5}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Allow up to 10 minutes for the eSIM to activate."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall6}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Done"}</strong>
                            {" to complete the installation."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall7}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Set "}
                            <strong>{"Default Line"}</strong>
                            {" to "}
                            <strong>{"Primary"}</strong>
                            {" and tap "}
                            <strong>{"Continue"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall8}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Set "}
                            <strong>{"Mobile Data"}</strong>
                            {" to your chosen eSIM (e.g., 'Travel')."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall9}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Go back to "}
                            <strong>{"Settings"}</strong>
                            {" → "}
                            <strong>{"Mobile Data or Cellular Data"}</strong>
                            {", and tap on the eSIM to check "}
                            <strong>{"Data Roaming"}</strong>
                            {"."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall10}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"Toggle "}
                            <strong>{"Data Roaming"}</strong>
                            {" to "}
                            <strong>{"On"}</strong>
                            {". It should appear as the below image."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.iosManualInstall11}
                                type="iPhone"
                              />
                            </div>
                          </li>
                          <li>
                            {"The eSIM is now installed and ready for use."}
                          </li>
                        </ol>
                      </Block>
                    </Column>
                  </Panel>
                </Accordion>
              </Tab>
              <Tab title={"Android Devices"}>
                <HeadingSmall
                  marginBottom={theme.sizing.scale600}
                  marginTop={0}
                >
                  {"Android eSIM Installation Guides"}
                </HeadingSmall>
                <Accordion
                  onChange={({ expanded }) => {
                    if (expanded && expanded.length) {
                      Mixpanel.track("InstallGuideExpand", {
                        action: "click",
                        element: "accordion",
                        id: expanded[0],
                      });
                    }
                  }}
                  overrides={{
                    Header: {
                      style: {
                        ...USE_FONT(
                          FontFamily.DMSansMedium,
                          500,
                          "20px",
                          "16.5px",
                        ),
                        border: `1px solid #000`,
                        borderRadius: theme.sizing.scale300,
                      },
                    },
                    PanelContainer: {
                      style: {
                        borderBottomStyle: "unset",
                        marginBottom: theme.sizing.scale300,
                      },
                    },
                  }}
                >
                  <Panel
                    key={"qr-install-android"}
                    title={"[Android] Install with QR code"}
                  >
                    <Column>
                      <ParagraphLarge
                        style={{
                          fontWeight: "bold",
                          marginBottom: theme.sizing.scale900,
                          marginTop: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {
                          "Follow the steps below to automatically install your eSIM on Android with the QR code"
                        }
                      </ParagraphLarge>
                      <Block>
                        <ol className={css(STYLES.stepList(theme))}>
                          <li>
                            {"Go into your Settings and tap on "}
                            <strong>{"Network & internet."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall1}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap the "}
                            <strong>{"+"}</strong>
                            {" next to "}
                            <strong>{"Mobile network."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall2}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"On this screen, tap "}
                            <strong>{"Don't have a SIM card?"}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall3}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Next."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall4}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            <strong>{"Scan QR code "}</strong>
                            {"provided."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall5}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"On the "}
                            <strong>{"Data eSIM"}</strong>
                            {" screen, tap "}
                            <strong>{"Activate "}</strong>
                            {"and wait for it to finish."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall6}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {
                              "Do not close this window else the installation may stop."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall7}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {
                              "Once the eSIM has been successfully installed, you will be brought to a screen where you can choose which sim you would like to use for your number. We recommend that you use your "
                            }
                            <strong>{"primary SIM card"}</strong>
                            {" for this section."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall8}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {
                              "Once you have completed these steps, please make sure your "
                            }
                            <strong>{"Data Roaming"}</strong>
                            {" is toggled "}
                            <strong>{"On"}</strong>
                            {" for your newly installed eSIM."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall9}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"The "}
                            <strong>{"Data Roaming"}</strong>
                            {
                              " toggle should appear as below on the installed eSIM."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidQrInstall10}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>{"The eSIM is now installed and set up."}</li>
                        </ol>
                      </Block>
                    </Column>
                  </Panel>
                  <Panel
                    key={"manual-install-android"}
                    title={"[Android] Install manually"}
                  >
                    <Column>
                      <ParagraphLarge
                        style={{
                          fontWeight: "bold",
                          marginBottom: theme.sizing.scale900,
                          marginTop: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {
                          "Follow the steps below to automatically install your eSIM on Android"
                        }
                      </ParagraphLarge>
                      <Block>
                        <ol className={css(STYLES.stepList(theme))}>
                          <li>
                            {"Go into your "}
                            <strong>{"Settings"}</strong>
                            {" and tap on "}
                            <strong>{"Network & internet."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall1}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap the "}
                            <strong>{"+"}</strong>
                            {" next to "}
                            <strong>{"Mobile network."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall2}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"On this screen, tap "}
                            <strong>{"Don't have a SIM card?"}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall3}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Next."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall4}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"When presented with "}
                            <strong>{"Scan QR Code"}</strong>
                            {" > Tap "}
                            <strong>{"Need help?"}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall5}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Enter it manually."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall6}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {
                              "To manually install the eSIM, you will need your "
                            }
                            <strong>{"SMDP+ Address"}</strong>
                            {" and "}
                            <strong>{"SMDP+ Activation Code"}</strong>
                            {
                              ", which you have received via email (you can also see it on your Manage Order page). Enter it in the format of '1$SMDP+ Address$SMDP+ Activation Code'. The code will look similar to the screenshot below."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall7}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap "}
                            <strong>{"Activate"}</strong>
                            {" to start the installation process. "}
                            <strong>
                              {
                                "Note: Do NOT close this window or the installation may fail."
                              }
                            </strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall8}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {
                              "Please allow up to 10 minutes for the eSIM to activate."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall9}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Once completed, go back to "}
                            <strong>{"Network and Internet."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall10}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Tap into "}
                            <strong>{"Mobile Networks."}</strong>
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall11}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"Make sure your "}
                            <strong>{"Data Roaming"}</strong>
                            {" is toggled "}
                            <strong>{"On"}</strong>
                            {" for your newly setup eSIM."}
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall12}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>
                            {"The "}
                            <strong>{"Data Roaming"}</strong>
                            {
                              " toggle should appear as shown below on the installed eSIM."
                            }
                            <div className={css(STYLES.stepImageDiv(theme))}>
                              <PhoneFrame
                                image={images.androidManualInstall13}
                                type="Android"
                              />
                            </div>
                          </li>
                          <li>{"The eSIM is now installed and set up."}</li>
                        </ol>
                      </Block>
                    </Column>
                  </Panel>
                </Accordion>
              </Tab>
            </Tabs>
          </Column>
        </Column>
      </Row>
      <FaqSection
        maxWidth="700px"
        padding={`0px ${theme.sizing.scale900} ${theme.sizing.scale1200} ${theme.sizing.scale900}`}
        showAccordion={false}
      />
    </MasterPage>
  );
};
