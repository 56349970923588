import * as React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";

import { Notifications } from "../components/notifications";
import { EsimInstallationGuide } from "../pages/esim-installation-guide";
import MainPage from "../pages/main-page";
import ManageOrderPage from "../pages/manage-order-page";
import OrderAccessPage from "../pages/order-access-page";
import ThankYouPage from "../pages/thank-you-page";
import { Analytics } from "./analytics";

const AppRoutes = (): React.ReactNode => {
  return (
    <BrowserRouter>
      <Notifications>
        <Analytics>
          <Routes>
            <Route element={<MainPage />} path="/" />
            <Route element={<MainPage />} path="/bundle/:id" />
            <Route element={<ThankYouPage />} path="/thank-you" />
            <Route element={<OrderAccessPage />} path="/access" />
            <Route element={<OrderAccessPage />} path="/access/:id" />
            <Route element={<OrderAccessPage />} path="/access/:id/resend" />
            <Route element={<ManageOrderPage />} path="/order" />
            <Route element={<ManageOrderPage />} path="/order/:id" />
            <Route
              element={<EsimInstallationGuide />}
              path="/esim-installation-guide"
            />
          </Routes>
        </Analytics>
      </Notifications>
    </BrowserRouter>
  );
};

export default AppRoutes;
