import { useStyletron } from "baseui";
import { Hide } from "baseui/icon";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";

const EmptyState = ({
  children,
  isEmpty,
  subtitle,
  title,
}: {
  children?: React.ReactNode;
  isEmpty: boolean;
  subtitle?: string;
  title?: string;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();

  if (!isEmpty) {
    return children || null;
  }

  return (
    <div
      className={css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      })}
    >
      <Hide size={theme.sizing.scale1600} />
      <div
        className={css({
          ...theme.typography.HeadingXSmall,
          paddingBlockEnd: theme.sizing.scale500,
          paddingBlockStart: theme.sizing.scale650,
        })}
      >
        {title || translate("empty_state.title")}
      </div>
      <div
        className={css({
          ...theme.typography.ParagraphMedium,
          paddingBlockEnd: theme.sizing.scale650,
        })}
      >
        {subtitle || translate("empty_state.subtitle")}
      </div>
    </div>
  );
};

export default EmptyState;
