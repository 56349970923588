export const DEVICES = [
  {
    devices: [
      "iPhone 15 Pro Max",
      "iPhone 15 Plus",
      "iPhone 15 Pro",
      "iPhone 15",
      "iPhone 14 Pro Max",
      "iPhone 14 Pro",
      "iPhone 14 Plus",
      "iPhone 14",
      "iPhone SE 3 (2022)",
      "iPhone 13 Pro Max",
      "iPhone 13 Pro",
      "iPhone 13 Mini",
      "iPhone 13",
      "iPhone 12 Pro Max",
      "iPhone 12 Pro",
      "iPhone 12 Mini",
      "iPhone 12",
      "iPhone SE 2 (2020)",
      "iPhone 11 Pro Max",
      "iPhone 11 Pro",
      "iPhone 11",
      "iPhone XS Max",
      "iPhone XS",
      "iPhone XR",
    ],
    manufacturer: "Apple",
    os: "iOS",
  },
  {
    devices: [
      "Google Pixel Fold",
      "Google Pixel 8 Pro",
      "Google Pixel 8",
      "Google Pixel 7 Pro",
      "Google Pixel 7",
      "Google Pixel 7a",
      "Google Pixel 6 Pro",
      "Google pixel 6a",
      "Google Pixel 6",
      "Google Pixel 5a",
      "Google Pixel 5",
      "Google Pixel 4 XL",
      "Google Pixel 4a",
      "Google Pixel 4",
      "Google Pixel 3a XL",
      "Google Pixel 3a (Some versions may be incompatible with eSIMs — check the phone's manual)",
      "Google Pixel 3 XL",
      "Google Pixel 3 (Some versions may be incompatible with eSIMs — check the phone's manual)",
      "Google Pixel 2 XL",
      "Google Pixel 2 (Some versions may be incompatible with eSIMs — check the phone's manual)",
    ],
    manufacturer: "Google",
    os: "Android",
  },
  {
    devices: [
      "Samsung Galaxy S24 Ultra",
      "Samsung Galaxy S24+",
      "Samsung Galaxy S24",
      "Samsung Galaxy S23 FE (Some versions may be incompatible with eSIMs — check the phone's manual)",
      "Samsung Galaxy S23 Ultra",
      "Samsung Galaxy S23+",
      "Samsung Galaxy S23",
      "Samsung Galaxy S22 Ultra",
      "Samsung Galaxy S22+",
      "Samsung Galaxy S22",
      "Samsung Galaxy S21+ Ultra 5G",
      "Samsung Galaxy S21+ 5G",
      "Samsung Galaxy S21",
      "Samsung Galaxy S20 Ultra 5G",
      "Samsung Galaxy S20 Ultra",
      "Samsung Galaxy S20+ 5G",
      "Samsung Galaxy S20+",
      "Samsung Galaxy S20",
      "Samsung Galaxy Z Fold5 5G",
      "Samsung Galaxy Z Fold4",
      "Samsung Galaxy Z Fold3 5G",
      "Samsung Galaxy Z Fold2 5G",
      "Samsung Galaxy Fold",
      "Samsung Galaxy Z Flip5 5G",
      "Samsung Galaxy Z Flip4",
      "Samsung Galaxy Z Flip3 5G",
      "Samsung Galaxy Z Flip",
      "Samsung Galaxy A54 (Some versions may be incompatible with eSIMs — check the phone's manual)",
      "Samsung Galaxy Note 20 Ultra 5G",
      "Samsung Galaxy Note 20",
    ],
    manufacturer: "Samsung",
    os: "Android",
  },
  {
    devices: [
      "Xiaomi 13T Pro",
      "Xiaomi 13 Pro",
      "Xiaomi 13 Lite",
      "Xiaomi 13",
      "Xiaomi 12T Pro",
    ],
    manufacturer: "Xiaomi",
    os: "Android",
  },
  {
    devices: [
      "Motorola Edge 40 Neo",
      "Motorola Edge 40 Pro",
      "Motorola Edge 40",
      "Motorola Edge+",
      "Motorola Razr+",
      "Motorola Razr 40 Ultra",
      "Motorola Razr 40",
      "Motorola Razr 5G",
      "Motorola Razr 2019",
      "Motorola G52J 5G",
      "Motorola G52J 5G Ⅱ",
      "Motorola G53J 5G",
      "Moto G54 5G",
      "Motorola G84",
    ],
    manufacturer: "Motorola",
    os: "Android",
  },
  {
    devices: [
      "Sony Xperia 10 III Lite",
      "Sony Xperia 10 IV",
      "Sony Xperia 10V",
      "Sony Xperia 1 IV",
      "Sony Xperia 5 IV",
      "Sony Xperia 1 V",
      "Sony Xperia Ace III",
      "Sony Xperia 5 V",
    ],
    manufacturer: "Sony",
    os: "Android",
  },
  {
    devices: ["Huawei Mate 40 Pro", "Huawei P40 Pro", "Huawei P40"],
    manufacturer: "Huawei",
    os: "Android",
  },
  {
    devices: ["Nokia G60 5G", "Nokia X30", "Nokia XR21"],
    manufacturer: "Nokia",
    os: "Android",
  },
  {
    devices: [
      "Oppo Find X3 Pro",
      "Oppo Find N2 Flip",
      "Oppo Reno 5A",
      "Oppo Reno 6 Pro 5G",
      "Oppo Reno 9A",
      "Oppo Find X5",
      "Oppo Find X5 Pro",
      "Oppo A55s 5G",
    ],
    manufacturer: "Oppo",
    os: "Android",
  },
  {
    devices: [
      "Sharp AQUOS R8 Pro",
      "Sharp AQUOS R8",
      "Sharp AQUOS R7",
      "Sharp Simple Sumaho 6",
      "Sharp AQUOS zero6",
      "Sharp AQUOS wish3",
      "Sharp AQUOS wish 2 SHG08",
      "Sharp AQUOS wish",
      "Sharp AQUOS sense7 plus",
      "Sharp AQUOS sense7",
      "Sharp AQUOS sense6s",
      "Sharp AQUOS sense4 lite",
    ],
    manufacturer: "Sharp",
    os: "Android",
  },
  {
    devices: ["Surface Duo"],
    manufacturer: "Surface",
    os: "Android",
  },
  {
    devices: [
      "Honor 50 (Some versions may be incompatible with eSIMs — check the phone's manual)",
      "Honor X8",
      "Honor 90",
      "Honor Magic5 Pro",
      "Honor Magic4 Pro",
    ],
    manufacturer: "Honor",
    os: "Android",
  },
  {
    devices: [
      "Rakuten Hand 5G",
      "Rakuten Hand",
      "Rakuten Big",
      "Rakuten Big-S",
      "Rakuten Mini",
    ],
    manufacturer: "Rakuten",
    os: "Android",
  },
  {
    devices: ["Nuu X5"],
    manufacturer: "Nuu",
    os: "Android",
  },
];
