import * as Firebase from "@firebase/app";
import { getMessaging, getToken } from "@firebase/messaging";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { LabelLarge, LabelSmall, ParagraphSmall } from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import { ApiContext } from "../../providers/api-provider";
import { Mixpanel } from "../../utils/mixpanel";
import { Column, Row } from "../containers";
import { LP_BUTTON_PRIMARY } from "../overrides";
import { STYLES } from "./styles";

export const Notifications = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();
  const [isHidden, setIsHidden] = React.useState(false);
  const [serviceWorkerState, setServiceWorkerState] = React.useState<
    string | undefined
  >(undefined);
  const apiContext = React.useContext(ApiContext);

  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  const shouldShowBanner =
    isSupported() &&
    Notification.permission !== "granted" &&
    Notification.permission !== "denied" &&
    !isHidden &&
    serviceWorkerState === "activated";

  const firebaseConfig = {
    apiKey: "AIzaSyBpaWvhvuXRjRg4fzGe3JzvQ87g2i12TuA",
    appId: "1:774241142390:web:4d4e78338c45a6ecf3c157",
    authDomain: "stay-connected-840be.firebaseapp.com",
    messagingSenderId: "774241142390",
    projectId: "stay-connected-840be",
    storageBucket: "stay-connected-840be.appspot.com",
  };

  React.useEffect(() => {
    if (isSupported()) {
      startPoolingServiceWorkerState();
    }
  }, []);

  React.useEffect(() => {
    if (isSupported()) {
      if (serviceWorkerState === "activated") {
        requestToken(0);
      }
    }
  }, [serviceWorkerState]);

  const startPoolingServiceWorkerState = React.useCallback(async () => {
    const interval = setInterval(async () => {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          if (
            registration.active?.scriptURL.endsWith("firebase-messaging-sw.js")
          ) {
            if (registration.active.state === "activated") {
              clearInterval(interval);
            }
            setServiceWorkerState(registration.active.state);
          }
        }
      } catch {
        clearInterval(interval);
      }
    }, 1500);
  }, []);

  const requestPermission = React.useCallback(async () => {
    setIsHidden(true);
    // Request permission to send notifications
    if (Notification.permission === "granted") {
      requestToken(0);
      return;
    }
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        requestToken(0);
      }
    });
  }, [serviceWorkerState]);

  const requestToken = React.useCallback(
    async (currentTry: number) => {
      if (Notification.permission !== "granted") {
        return;
      }
      if (currentTry > 4) {
        return;
      }
      try {
        const app = Firebase.initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        // Get the token
        const currentToken = await getToken(messaging);
        if (apiContext.storeFirebaseMessagingToken) {
          await apiContext.storeFirebaseMessagingToken({ token: currentToken });
        }
      } catch {
        setTimeout(() => requestToken(currentTry + 1), 250);
      }
    },
    [apiContext],
  );

  return (
    <React.Fragment>
      {children}
      {shouldShowBanner && (
        <Block className={css(STYLES.container(theme))}>
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Column
              style={{ marginRight: theme.sizing.scale600, maxWidth: "280px" }}
            >
              <LabelLarge marginBottom={theme.sizing.scale200}>
                {translate("notification.title")}
              </LabelLarge>
              <ParagraphSmall margin={0}>
                {translate("notification.subtitle")}
              </ParagraphSmall>
            </Column>
            <Column style={{ alignItems: "center" }}>
              <Button
                onClick={() => {
                  Mixpanel.track("CTA", {
                    action: "click",
                    element: "allow-notifications",
                    section: "notification-banner",
                  });
                  requestPermission();
                }}
                overrides={LP_BUTTON_PRIMARY(theme)}
              >
                {translate(
                  screenWidth > theme.breakpoints.small
                    ? "notification.allow"
                    : "notification.allowSmall",
                )}
              </Button>
              <LabelSmall
                onClick={() => {
                  Mixpanel.track("CTA", {
                    action: "click",
                    element: "maybe-later",
                    section: "notification-banner",
                  });
                  setIsHidden(true);
                }}
                style={{ cursor: "pointer", marginTop: theme.sizing.scale600 }}
              >
                {translate(
                  screenWidth > theme.breakpoints.small
                    ? "notification.later"
                    : "notification.laterSmall",
                )}
              </LabelSmall>
            </Column>
          </Row>
        </Block>
      )}
    </React.Fragment>
  );
};
