import { Theme } from "baseui";

export const STYLES = {
  bestValueWrapper: (theme: Theme, isBestValue: boolean) =>
    ({
      alignItems: "center",
      display: isBestValue ? "flex" : "none",
      justifyContent: "center",
      margin: theme.sizing.scale500,
    }) as const,
  bundleAttributesWrapper: (theme: Theme) =>
    ({
      alignItems: "center",
      height: "55%",
      justifyContent: "center",
      padding: theme.sizing.scale600,
      width: "100%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "center",
        minHeight: "100%",
        minWidth: "45%",
        textAlign: "center",
      },
    }) as const,
  bundleItem: (theme: Theme, screenWidth: number) =>
    ({
      alignItems: "center",
      background: "#FFF",
      borderRadius: theme.sizing.scale600,
      boxShadow: theme.lighting.shadow600,
      cursor: "pointer",
      height: "325px",
      justifyContent: "center",
      overflow: "hidden",
      width: "240px",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        flexDirection: "row",
        height: "fit-content",
        maxWidth: `420px`,
        minWidth: "280px",
        width: `calc(${screenWidth}px - 64px)`,
      },
    }) as const,
  bundlePricingWrapper: (theme: Theme) =>
    ({
      alignItems: "center",
      background: "#F8DFCC",
      height: "45%",
      justifyContent: "center",
      padding: theme.sizing.scale600,
      width: "100%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        maxWidth: "45%",
        minHeight: "150px",
        paddingTop: theme.sizing.scale1000,
      },
    }) as const,
  bundleWrapper: (theme: Theme, isBestOption: boolean, isLastItem: boolean) =>
    ({
      alignItems: "center",
      background: isBestOption ? "#F7B500" : "transparent",
      borderRadius: isBestOption ? theme.sizing.scale600 : "0px",
      marginRight: isLastItem ? "0px" : theme.sizing.scale900,
      maxWidth: "fit-content",
      padding: isBestOption ? theme.sizing.scale300 : "0px",
      scrollSnapAlign: "center",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        marginBottom: isLastItem ? "0px" : theme.sizing.scale800,
        marginRight: "0px",
      },
    }) as const,
  headingWrapper: (theme: Theme) =>
    ({
      marginBottom: theme.sizing.scale900,
      paddingLeft: theme.sizing.scale900,
      paddingRight: theme.sizing.scale900,
      textAlign: "center",
      zIndex: "1",
    }) as const,
  pattern: (theme: Theme) =>
    ({
      justifyContent: "center",
      paddingBottom: theme.sizing.scale2400,
      paddingTop: theme.sizing.scale2400,
      position: "relative",
      width: "100%",
      zIndex: 0,
    }) as const,
  scrollable: (theme: Theme) =>
    ({
      "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
      ["::-webkit-scrollbar"]: {
        display: "none",
      },
      overflowX: "scroll",
      scrollbarWidth: "none" /* Firefox */,
      scrollSnapType: "x mandatory",
      width: "calc(100% - 128px)",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        overflowX: "hidden",
        width: "calc(100% - 32px)",
      },
    }) as const,
  scrollableContent: (theme: Theme, hasScroll: boolean) =>
    ({
      justifyContent: "center",
      minWidth: "fit-content",
      paddingLeft: hasScroll ? "32px" : "0px",
      paddingRight: hasScroll ? "32px" : "0px",
      width: "100%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "center",
        flexDirection: "column",
        overflowX: "hidden",
      },
    }) as const,
};
