import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import {
  HeadingMedium,
  HeadingSmall,
  LabelMedium,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import * as React from "react";

import { Column } from "../containers";
import { STYLES } from "./styles";
import { tableData, tableDataI } from "./tableData";

// TO-DO: make table look nicer (same font as rest of page?)

export const DataUsageGuide = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): React.ReactNode => {
  const [css, theme] = useStyletron();

  return (
    <Modal
      animate
      autoFocus
      closeable
      isOpen={isOpen}
      mountNode={document.querySelector("#modal-mount-node") as HTMLElement}
      onClose={() => setIsOpen(false)}
      role={ROLE.dialog}
      size={SIZE.auto}
    >
      <ModalHeader>
        <HeadingMedium margin={0}>{"Data Usage Guide"} </HeadingMedium>
      </ModalHeader>
      <ModalBody>
        <Column className={css(STYLES.container)}>
          <Column className={css(STYLES.wrapper)}>
            <Column style={{ marginBottom: theme.sizing.scale600 }}>
              <ParagraphLarge marginTop={0}>
                {
                  "Here's a quick guide on how much data popular online activities typically use. "
                }
              </ParagraphLarge>
              <ParagraphLarge
                dangerouslySetInnerHTML={{
                  __html:
                    "<strong>Please note</strong> that your accommodation and other locations will likely offer Wi-Fi. Consider using Wi-Fi over your eSIM data plan when doing high-consumption tasks such as video-calls so you can make the most of your data plan.",
                }}
                margin={0}
              />
            </Column>
            <Column>
              <HeadingSmall margin={0}>{"Data usage by service"}</HeadingSmall>
              <ParagraphSmall style={{ margin: theme.sizing.scale300 }}>
                {"*Considering 1 hour of usage per day"}
              </ParagraphSmall>
              <TableBuilder
                data={tableData}
                divider="grid"
                overrides={{
                  Root: {
                    style: {
                      marginBottom: theme.sizing.scale600,
                      maxWidth: "calc(100vw - 80px)",
                    },
                  },
                  TableBodyCell: {
                    style: {
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingTop: "8px",
                      verticalAlign: "center",
                    },
                  },
                  TableHeadCell: {
                    style: {
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingTop: "8px",
                    },
                  },
                }}
              >
                <TableBuilderColumn header="Activity">
                  {(row: tableDataI) => row["Activity"]}
                </TableBuilderColumn>
                <TableBuilderColumn
                  header={
                    <Column style={{ alignItems: "center" }}>
                      <LabelMedium>One</LabelMedium>
                      <LabelMedium>Week</LabelMedium>
                    </Column>
                  }
                >
                  {(row: tableDataI) => (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: row["1 week (1h a day)"],
                      }}
                      style={{
                        display: "block",
                        textAlign: "center",
                        width: "100%",
                      }}
                    />
                  )}
                </TableBuilderColumn>
                <TableBuilderColumn
                  header={
                    <Column style={{ alignItems: "center" }}>
                      <LabelMedium>Two</LabelMedium>
                      <LabelMedium>Weeks</LabelMedium>
                    </Column>
                  }
                >
                  {(row: tableDataI) => (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: row["2 weeks (1h a day)"],
                      }}
                      style={{
                        display: "block",
                        textAlign: "center",
                        width: "100%",
                      }}
                    />
                  )}
                </TableBuilderColumn>
                <TableBuilderColumn
                  header={
                    <Column style={{ alignItems: "center" }}>
                      <LabelMedium>One</LabelMedium>
                      <LabelMedium>Month</LabelMedium>
                    </Column>
                  }
                >
                  {(row: tableDataI) => (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: row["1 month (1h a day)"],
                      }}
                      style={{
                        display: "block",
                        textAlign: "center",
                        width: "100%",
                      }}
                    />
                  )}
                </TableBuilderColumn>
              </TableBuilder>
            </Column>
            <ParagraphSmall>
              <strong>Please note</strong>
              {
                ": these figures are averages, and actual data usage can vary from person to person. *Streaming in standard definition is assumed. Streaming in HD or UHD will consume significantly more data. **Sending images and videos will increase data consumption when texting."
              }
            </ParagraphSmall>
            <HeadingSmall margin={0}>{"Using mobile data abroad"}</HeadingSmall>
            <ParagraphLarge>
              {
                "The average person spends about three hours a day online, but this can quickly increase when you're in a new place. While that may not seem like much, it can quickly add up—if you're roaming with your regular carrier, you could be charged up to $15 per MB! A single video call could end up costing hundreds!"
              }
            </ParagraphLarge>
            <HeadingSmall margin={0}>{"Never run out of data"}</HeadingSmall>
            <ParagraphLarge>
              {
                "We'll send you regular usage updates (at 50%, 80% and 100% of usage) so you know how much data you're using while you're off on your adventures."
              }
            </ParagraphLarge>
            <ParagraphLarge margin={0}>
              {
                "You can also top-up manually at any time or enable auto top-up and we will recharge you automatically once you hit 80% usage of your plan."
              }
            </ParagraphLarge>
          </Column>
        </Column>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)} style={{ width: "100%" }}>
          {"Got It"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
