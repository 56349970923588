export const scrollWithOffset = (
  selector: string,
  yOffset = 0,
  behavior: ScrollBehavior = "smooth",
) => {
  const el = document.querySelector(selector);
  if (!el) {
    return;
  }
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ behavior, top: y });
};
