import { useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { toaster } from "baseui/toast";
import { LabelMedium, ParagraphXSmall } from "baseui/typography";
import * as React from "react";

import { API } from "../../api";
import If from "../../components/if";
import {
  EnumPurchaseFormType,
  PurchaseForm,
  PurchaseFormSubmitT,
  PurchaseTopUpRequestWithExtrasT,
} from "../../components/purchase-form";
import { useTranslation } from "../../hooks/translate";
import { Column } from "../containers";
import Loading from "../loading";

export const TopUpCard = ({
  customerName,
  isAutoTopUpEnabled,
  isParentLoading,
  logout,
  orderId,
  reloadPurchase,
}: {
  customerName: string;
  isAutoTopUpEnabled: boolean;
  isParentLoading: boolean;
  logout: (sessionExpired: boolean, redirectTo: string) => void;
  orderId: string;
  reloadPurchase: () => void;
}): React.ReactNode => {
  const { translate } = useTranslation();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cardState, setCardState] = React.useState(1);

  const onSubmit = React.useCallback(
    async ({ elements, form, walletCallback }: PurchaseFormSubmitT) => {
      try {
        setIsLoading(true);

        const purchaseBody: PurchaseTopUpRequestWithExtrasT = {
          ...(form as PurchaseTopUpRequestWithExtrasT),
          order_id: orderId,
        };

        if (stripe && !form.payment_method_id) {
          const cardNumberElement = elements?.getElement("cardNumber");

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            element: cardNumberElement as StripeCardNumberElement,
            params: {
              billing_details: {
                name: customerName,
              },
            },
          });

          if (error) {
            throw error;
          }

          purchaseBody.payment_method_id = paymentMethod?.id as string;
        }

        if (!purchaseBody.payment_method_id) {
          toaster.negative("Error fetching payment token");
          setIsLoading(false);
          return false;
        }

        const data = await API.purchaseTopUp({ body: purchaseBody });
        if (walletCallback) {
          await walletCallback(data.status, data.clientSecret);
        }

        // redirect for 3ds cards
        if (data.next && data.next.redirect_to_url) {
          window.location.href = data.next.redirect_to_url.url;
        } else {
          toaster[data.status ? "positive" : "warning"](data.message);
          reloadPurchase();
          setCardState(3);
        }

        setIsLoading(false);
        return true;
      } catch (error) {
        setIsLoading(false);
        if (walletCallback) {
          walletCallback(false);
        }
        if ((error && (error as AxiosError)?.response?.status) === 401) {
          logout(true, "/access");
        } else {
          toaster.warning("Something went wrong, please try again.");
          console.error(error);
        }
        return false;
      }
    },
    [customerName, orderId, stripe],
  );

  return (
    <Card
      title={
        <Column>
          <LabelMedium margin={0}>
            {translate("manage.cards.manualTopUp.title")}
          </LabelMedium>
          <ParagraphXSmall margin={0}>
            {translate("manage.cards.manualTopUp.subtitle", { order: orderId })}
          </ParagraphXSmall>
        </Column>
      }
    >
      <Loading isLoading={isParentLoading}>
        <If condition={cardState === 1}>
          <StyledBody>
            {translate("manage.cards.manualTopUp.states.first.description")}
          </StyledBody>
          <StyledAction>
            <Button
              onClick={() => {
                setCardState(2);
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              {translate("manage.cards.manualTopUp.states.first.button")}
            </Button>
          </StyledAction>
        </If>
        <If condition={cardState === 2}>
          <StyledBody>
            <PurchaseForm
              formType={EnumPurchaseFormType.TopUp}
              isAutoTopUpEnabled={isAutoTopUpEnabled}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          </StyledBody>
          <StyledAction>
            <Button
              kind="tertiary"
              onClick={() => {
                setCardState(1);
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              {translate("manage.cards.manualTopUp.states.second.button")}
            </Button>
          </StyledAction>
        </If>
        <If condition={cardState === 3}>
          <StyledBody>
            {translate("manage.cards.manualTopUp.states.third.description")}
          </StyledBody>
          <StyledAction>
            <Button
              onClick={() => {
                setCardState(1);
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              {translate("manage.cards.manualTopUp.states.third.button")}
            </Button>
          </StyledAction>
        </If>
      </Loading>
    </Card>
  );
};
