import { Theme } from "baseui";

export const STYLES = {
  container: {
    background: "#FFF",
    maxHeight: "max(60vh, calc(100vh - 350px))",
    overflowY: "scroll",
    overscrollBehavior: "contain",
  } as const,
  link: {
    ":hover": {
      color: "blue", // Change color to blue on hover
      textDecoration: "underline", // Keep underline on hover
    },
    color: "#000", // Set hyperlink color to black by default
    textDecoration: "underline", // Keep the underline
  },
  textWrapper: (theme: Theme) => ({
    marginBottom: theme.sizing.scale600,
  }),
  wrapper: {
    maxWidth: "550px",
  },
};
