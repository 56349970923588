import { Theme } from "baseui";

import { FontFamily, USE_FONT } from "../overrides";

export const STYLES = {
  inputWithError: (hasError: boolean) => {
    return hasError
      ? {
          color: "#eb1c26",
        }
      : {};
  },
  stepperInput: (theme: Theme) =>
    ({
      ...USE_FONT(FontFamily.DMSans, 500, "42px", "36px"),
      fontWeight: "normal",
      marginBottom: `0`,
      marginLeft: theme.sizing.scale950,
      marginRight: theme.sizing.scale950,
      marginTop: `0`,
      minWidth: "30px",
      textAlign: "center",
    }) as const,
};
