export interface tableDataI {
  "1 hour": string;
  "1 month (1h a day)": string;
  "1 week (1h a day)": string;
  "2 weeks (1h a day)": string;
  Activity: string;
}

export const tableData: tableDataI[] = [
  {
    "1 hour": "10 MB",
    "1 month (1h a day)": "0.3<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "0.1<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "0.14<span style='font-size:8px'>GB</span>",
    Activity: "WhatsApp Messages or iMessage**",
  },
  {
    "1 hour": "60 MB",
    "1 month (1h a day)": "1.7<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "0.4<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "0.8<span style='font-size:8px'>GB</span>",
    Activity: "Google* or Google Maps",
  },
  {
    "1 hour": "72 MB",
    "1 month (1h a day)": "2<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "0.5<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "1<span style='font-size:8px'>GB</span>",
    Activity: "Spotify",
  },
  {
    "1 hour": "500 MB",
    "1 month (1h a day)": "14<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "3.5<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "7<span style='font-size:8px'>GB</span>",
    Activity: "Instagram",
  },
  {
    "1 hour": "150 MB",
    "1 month (1h a day)": "4<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "1<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "2<span style='font-size:8px'>GB</span>",
    Activity: "WhatsApp calls",
  },
  {
    "1 hour": "700 MB",
    "1 month (1h a day)": "20<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "5<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "10<span style='font-size:8px'>GB</span>",
    Activity: "Netflix*",
  },
  {
    "1 hour": "1<span style='font-size:8px'>GB</span>",
    "1 month (1h a day)": "33<span style='font-size:8px'>GB</span>",
    "1 week (1h a day)": "8<span style='font-size:8px'>GB</span>",
    "2 weeks (1h a day)": "16<span style='font-size:8px'>GB</span>",
    Activity: "YouTube*",
  },
];
