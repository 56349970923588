const env = process.env.NODE_ENV as string;
const enabled = ["production"];
const userAgent = navigator.userAgent;
// exclude events from non-production envs and from headless browsers
const isEnabled =
  enabled.includes(env) && !userAgent.toLowerCase().includes("headless");

const actions = {
  ga4: {
    conversion: (id: string, value: number, currency: string) => {
      if (isEnabled) {
        if (typeof window.gtag !== "undefined") {
          gtag("event", "conversion", {
            currency: currency.toUpperCase(),
            send_to: ["AW-16704651466/VRXjCMGSstMZEMqBs50-", "G-GRZ2V4XLDM"],
            transaction_id: id,
            value,
          });
        }
      }
    },
  },
  meta: {
    conversion: (id: string, value: number, currency: string) => {
      if (isEnabled) {
        if (typeof window.fbq !== "undefined") {
          fbq("track", "Purchase", {
            content_ids: [id],
            content_type: "product",
            currency: currency.toUpperCase(),
            value,
          });
        }
      }
    },
  },
};

export const Trackers = actions;
