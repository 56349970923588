import * as React from "react";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [scroll, setScroll] = React.useState({
    scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    const onScroll = () => {
      setScroll({
        scrollTop:
          document.documentElement.scrollTop || document.body.scrollTop,
      });
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scroll]);

  return { ...screenSize, ...scroll };
};
