import { useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { useStyletron } from "baseui";
import { toaster } from "baseui/toast";
import { HeadingSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { API } from "../../api";
import { Column } from "../../components/containers";
import If from "../../components/if";
import { LpBannerSection } from "../../components/landing-page/banner-section";
import { LpBenefitsSection } from "../../components/landing-page/benefits-section";
import { LpDescriptionSection } from "../../components/landing-page/description-section";
import { FaqSection } from "../../components/landing-page/faq-section";
import { LpHeroSection } from "../../components/landing-page/hero-section";
import { PreHeaderOffer } from "../../components/landing-page/pre-header-offer";
import { LpPricingSection } from "../../components/landing-page/pricing-section";
import MasterPage from "../../components/master-page";
import { FontFamily, USE_FONT } from "../../components/overrides";
import {
  EnumPurchaseFormType,
  PurchaseForm,
  PurchaseFormSubmitT,
  PurchaseRequestExtrasT,
} from "../../components/purchase-form";
import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import { ApiContext } from "../../providers/api-provider";
import { Trackers } from "../../utils/trackers";
import { getLineDetail } from "./assets/lines";

const MainPage = (): React.ReactNode => {
  const [, theme] = useStyletron();
  const { translate } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const apiContext = React.useContext(ApiContext);
  const stripe = useStripe();
  const navigate = useNavigate();
  const { width: screenWidth } = useScreenSize();
  const detailColor = translate("theme.colors.lp.purchaseFormLineDetail");
  const isRegional = Boolean(translate("country.isRegional"));

  const onSubmit = React.useCallback(
    async ({ elements, form, walletCallback }: PurchaseFormSubmitT) => {
      try {
        setIsLoading(true);

        const purchaseBody: PurchaseRequestExtrasT = {
          ...(form as PurchaseRequestExtrasT),
        };

        if (stripe && !form.payment_method_id) {
          const cardNumberElement = elements?.getElement("cardNumber");

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            element: cardNumberElement as StripeCardNumberElement,
            params: {
              billing_details: {
                name: purchaseBody.customer_name,
              },
            },
          });

          if (error) {
            throw error;
          }

          purchaseBody.payment_method_id = paymentMethod?.id as string;
        }

        if (!purchaseBody.payment_method_id) {
          toaster.negative("Error fetching payment token");
          setIsLoading(false);
          return false;
        }

        const data = await API.purchase({ body: purchaseBody });
        if (walletCallback) {
          await walletCallback(data.status, data.clientSecret);
        }

        // store to use on typ
        data.expiresAt = moment().add(6, "hours");
        localStorage.setItem("purchase-data", JSON.stringify(data));

        try {
          Trackers.ga4.conversion(
            data?.purchase?.payment?.id,
            data?.purchase?.payment?.price,
            data?.purchase?.payment?.currency,
          );
          Trackers.meta.conversion(
            data?.purchase?.payment?.id,
            data?.purchase?.payment?.price,
            data?.purchase?.payment?.currency,
          );
        } catch (err) {
          console.error("unexpected error: ", err);
        }

        if (apiContext.storeFirebaseMessagingToken) {
          await apiContext.storeFirebaseMessagingToken({
            customerId: data?.purchase?.customer?.id,
          });
        }

        // redirect for 3ds cards
        if (data.next && data.next.redirect_to_url) {
          window.location.href = data.next.redirect_to_url.url;
        } else {
          toaster[data.status ? "positive" : "warning"](data.message);
          navigate("/thank-you");
          window.scrollTo(0, 0);
        }
        setIsLoading(false);
        return true;
      } catch (error) {
        setIsLoading(false);
        if (walletCallback) {
          walletCallback(false, undefined);
        }
        toaster.warning("Something went wrong, please try again.");
        console.error(error);
        return false;
      }
    },
    [stripe, apiContext],
  );

  return (
    <MasterPage preHeader={<PreHeaderOffer />}>
      <LpBannerSection />
      <LpDescriptionSection />
      <LpBenefitsSection />
      <LpHeroSection />
      <LpPricingSection />
      <Column
        style={{
          background:
            screenWidth <= 1920
              ? `url(${getLineDetail(detailColor)}) calc(100px + 50vw) ${isRegional ? "200px" : "180px"} no-repeat`
              : "unset",
          padding:
            screenWidth > theme.breakpoints.medium
              ? theme.sizing.scale1600
              : `${theme.sizing.scale1600} ${theme.sizing.scale600}`,
        }}
      >
        <Column
          style={{
            margin: `0 auto ${theme.sizing.scale900} auto`,
            maxWidth: "608px",
            textAlign: "center",
          }}
        >
          <HeadingSmall
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "40px", "28px"),
            }}
          >
            {translate("purchase.heading")}
          </HeadingSmall>
          <If condition={isRegional}>
            <ParagraphMedium
              style={{
                ...USE_FONT(FontFamily.DMSans, 500, "20px", "16px"),
              }}
            >
              {translate("purchase.coverage", {
                region: translate("country.region"),
              })}
            </ParagraphMedium>
          </If>
        </Column>
        <Column
          style={{
            background: "#fff",
            borderRadius: theme.sizing.scale600,
            margin: "0 auto",
            maxWidth: "608px",
            padding: theme.sizing.scale600,
            width: "calc(100% - 32px)",
          }}
        >
          <PurchaseForm
            formType={EnumPurchaseFormType.Purchase}
            isAutoTopUpEnabled={true}
            isLoading={isLoading}
            onSubmit={onSubmit}
          />
        </Column>
      </Column>
      <FaqSection showAccordion={true} />
    </MasterPage>
  );
};
export default MainPage;
