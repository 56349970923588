import { AxiosError } from "axios";
import { Badge, COLOR } from "baseui/badge";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { FormControl } from "baseui/form-control";
import { Select } from "baseui/select";
import { toaster } from "baseui/toast";
import { LabelMedium, ParagraphXSmall } from "baseui/typography";
import * as React from "react";
import Swal from "sweetalert2";

import { API } from "../../api";
import { useTranslation } from "../../hooks/translate";
import { ApiContext } from "../../providers/api-provider";
import { Column } from "../containers";
import If from "../if";
import Loading from "../loading";

export const AutoTopUpCard = ({
  isDisabled,
  isParentLoading,
  logout,
  orderId,
  reloadPurchase,
}: {
  isDisabled: boolean;
  isParentLoading: boolean;
  logout: (sessionExpired: boolean, redirectTo: string) => void;
  orderId: string;
  reloadPurchase: () => void;
}): React.ReactNode => {
  const { translate } = useTranslation();
  const apiContext = React.useContext(ApiContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cardState, setCardState] = React.useState(1);
  const [form, setForm] = React.useState({
    bundle_name: "",
    confirm_auto_top_up: false,
  });

  const isSubmitDisabled = React.useCallback(() => {
    if (isDisabled) {
      return true;
    }
    if (!form.bundle_name) {
      return true;
    }
    if (!form.confirm_auto_top_up) {
      return true;
    }
    return false;
  }, [form, isDisabled]);

  const enableAutoTopUp = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await API.enableAutoTopUp({
        body: {
          bundle_name: form.bundle_name,
          confirm_auto_top_up: form.confirm_auto_top_up,
          order_id: orderId,
        },
      });
      if (result.status) {
        Swal.fire({
          confirmButtonColor: "#000",
          confirmButtonText: translate(
            "manage.cards.autoTopUp.states.third.button",
          ),
          icon: "success",
          text: translate("manage.cards.autoTopUp.states.third.description"),
          title: "Auto top-up enabled",
        });
        reloadPurchase();
      } else {
        toaster.warning("Something went wrong, please try again.");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if ((error && (error as AxiosError)?.response?.status) === 401) {
        logout(true, "/access");
      } else {
        console.error(error);
      }
    }
  }, [orderId, form]);

  return (
    <Card
      title={
        <Column>
          <LabelMedium margin={0}>
            {translate("manage.cards.autoTopUp.title")}
          </LabelMedium>
          <ParagraphXSmall margin={0}>
            {translate("manage.cards.autoTopUp.subtitle", { order: orderId })}
          </ParagraphXSmall>
          <If condition={isDisabled}>
            <Block>
              <Badge
                color={COLOR.positive}
                content={translate("manage.cards.autoTopUp.hint")}
              />
            </Block>
          </If>
        </Column>
      }
    >
      <Loading isLoading={isParentLoading}>
        <If condition={cardState === 1}>
          <StyledBody>
            {translate("manage.cards.autoTopUp.states.first.description")}
          </StyledBody>
          <StyledAction>
            <Button
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() => {
                setCardState(2);
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              {translate("manage.cards.autoTopUp.states.first.button")}
            </Button>
          </StyledAction>
        </If>
        <If condition={cardState === 2}>
          <Column>
            <FormControl
              caption={() =>
                translate("manage.cards.autoTopUp.states.second.caption")
              }
              label={() =>
                translate("manage.cards.autoTopUp.states.second.bundle")
              }
            >
              <Select
                clearable={false}
                disabled={!apiContext.bundles}
                onChange={(e) =>
                  setForm((form) => ({
                    ...form,
                    bundle_name: e.value[0].id as string,
                  }))
                }
                options={apiContext.bundles?.map((bundle) => ({
                  id: bundle.name,
                  label: `${bundle.description} for $${bundle.price} each`,
                }))}
                value={[{ id: form.bundle_name }]}
              />
            </FormControl>
            <FormControl
              caption={() =>
                translate("manage.cards.autoTopUp.states.second.terms")
              }
            >
              <Checkbox
                checked={form.confirm_auto_top_up}
                labelPlacement={LABEL_PLACEMENT.right}
                onChange={(e) =>
                  setForm({ ...form, confirm_auto_top_up: e.target.checked })
                }
              >
                {translate("manage.cards.autoTopUp.states.second.check")}
              </Checkbox>
            </FormControl>
          </Column>
          <StyledAction>
            <Column>
              <Button
                disabled={isSubmitDisabled()}
                isLoading={isLoading}
                onClick={() => {
                  enableAutoTopUp();
                }}
                overrides={{ BaseButton: { style: { width: "100%" } } }}
              >
                {translate("manage.cards.autoTopUp.states.second.button")}
              </Button>
              <Button
                kind="tertiary"
                onClick={() => {
                  setCardState(1);
                }}
                overrides={{ BaseButton: { style: { width: "100%" } } }}
              >
                {translate("manage.cards.autoTopUp.states.second.dismiss")}
              </Button>
            </Column>
          </StyledAction>
        </If>
      </Loading>
    </Card>
  );
};
