import { Theme } from "baseui";

import { CustomTheme } from "../theme";

export const STYLES = {
  gradientBlock: (
    theme: Theme,
    isUsingBanner: boolean,
    gradientBannerOpacity: string,
  ) =>
    ({
      background: `${(theme as CustomTheme).colors.lp.backgroundPattern}`,
      bottom: "0",
      left: "0",
      mixBlendMode: "multiply",
      opacity: isUsingBanner ? gradientBannerOpacity : "1",
      position: "absolute",
      right: "0",
      top: "0",
    }) as const,
  imageBlock: (
    isUsingBanner: boolean,
    banner: string,
    banner2x: string,
    bannerBackgroundPosition: string,
    pattern: string,
    pattern2x: string,
  ) =>
    ({
      background: isUsingBanner
        ? `image-set(url(${banner}) 1x, url(${banner2x}) 2x) ${bannerBackgroundPosition} repeat`
        : `image-set(url(${pattern}) 1x, url(${pattern2x}) 2x) center repeat`,
      bottom: "0",
      left: "0",
      position: "absolute",
      right: "0",
      top: "0",
    }) as const,
};
