export const STYLES = {
  stripeElementWithCard: {
    paddingLeft: "12px",
    width: "100%",
  },
  stripeElementWithCardWrapper: {
    alignItems: "center",
    background: "rgb(238, 238, 238)",
    border: "2px solid rgb(238, 238, 238)",
    borderRadius: "8px",
    minHeight: "24px",
    overflow: "hidden",
    padding: "10px 14px",
  },
  stripeElementWrapper: {
    alignItems: "center",
    background: "rgb(238, 238, 238)",
    border: "2px solid rgb(238, 238, 238)",
    borderRadius: "8px",
    display: "block",
    minHeight: "24px",
    overflow: "hidden",
    padding: "10px 14px",
  },
};
