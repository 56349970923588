import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import {
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
  ParagraphLarge,
} from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";
import { Column } from "../containers";
import { DEVICES } from "./devices";
import { STYLES } from "./styles";

export const CompatibleDevices = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): React.ReactNode => {
  const { translate } = useTranslation();
  const [css, theme] = useStyletron();

  const iOS = DEVICES.filter((device) => device.os === "iOS");
  const android = DEVICES.filter((device) => device.os === "Android");

  const content = React.useCallback(
    (
      title: string,
      section: Array<{
        devices: Array<string>;
        manufacturer: string;
        os: string;
      }>,
    ) => {
      return (
        <React.Fragment>
          <HeadingSmall
            style={{ marginBottom: theme.sizing.scale600, marginTop: 0 }}
          >
            {title}
          </HeadingSmall>
          {section.map((brand) => (
            <Column
              className={css(STYLES.textWrapper(theme))}
              key={brand.manufacturer}
            >
              <HeadingXSmall
                style={{ marginBottom: theme.sizing.scale300, marginTop: 0 }}
              >
                {brand.manufacturer}
              </HeadingXSmall>
              <ul style={{ marginBottom: theme.sizing.scale300, marginTop: 0 }}>
                {brand.devices.map((device, index) => (
                  <li key={`${brand.manufacturer}-${index}`}>
                    <ParagraphLarge>{device}</ParagraphLarge>
                  </li>
                ))}
              </ul>
            </Column>
          ))}
        </React.Fragment>
      );
    },
    [],
  );

  return (
    <Modal
      animate
      autoFocus
      closeable
      isOpen={isOpen}
      mountNode={document.querySelector("#modal-mount-node") as HTMLElement}
      onClose={() => setIsOpen(false)}
      role={ROLE.dialog}
      size={SIZE.auto}
    >
      <ModalHeader>
        <HeadingMedium margin={0}>
          {translate("compatibility.title")}
        </HeadingMedium>
      </ModalHeader>
      <ModalBody>
        <Column className={css(STYLES.container)}>
          <Column className={css(STYLES.wrapper)}>
            <Column className={css(STYLES.textWrapper(theme))}>
              <Column style={{ marginBottom: theme.sizing.scale600 }}>
                <Column>
                  <HeadingXSmall margin={0}>{"iPhones & iPads"}</HeadingXSmall>
                  <ParagraphLarge>
                    {
                      "If you are using an iPhone XS(Max)/XR (2018) or a later model, your phone supports eSIM."
                    }
                  </ParagraphLarge>
                  <ParagraphLarge marginTop={0}>
                    {
                      "If you are using an iPad Pro (3rd Generation - 2018) or a later model, your device supports eSIM."
                    }
                  </ParagraphLarge>
                </Column>

                <Column>
                  <HeadingXSmall margin={0}>{"Google Pixel"}</HeadingXSmall>
                  <ParagraphLarge>
                    {
                      "If you are using a Pixel 3/3a (2018) or a later model, your phone supports eSIM."
                    }
                  </ParagraphLarge>
                </Column>

                <Column>
                  <HeadingXSmall margin={0}>{"Samsung Phones"}</HeadingXSmall>
                  <ParagraphLarge>
                    {
                      "If you are using a Galaxy S20 (2020), Galaxy Note 1 (2019), Galaxy Fold/Flip 3 (2021), Galaxy A53 5G (2022), Galaxy Watch 4 (2021), Galaxy Tab S9 (2023) or a later model, your device supports eSIM."
                    }
                  </ParagraphLarge>
                </Column>
              </Column>
              <Column>
                <HeadingSmall margin={0}>
                  {"Confirm your device capabilities"}
                </HeadingSmall>
                <ParagraphLarge
                  dangerouslySetInnerHTML={{
                    __html:
                      "Simply dial <mark>*#06#</mark> and if you have an <strong>EID</strong> code show up in the list on your screen, then your phone is eSIM compatible!",
                  }}
                />
                <ParagraphLarge margin={0}>
                  {"Check the video below to see an example!"}
                </ParagraphLarge>
              </Column>
              <Column
                style={{
                  alignItems: "center",
                }}
              >
                <Block
                  marginBottom={theme.sizing.scale800}
                  marginTop={theme.sizing.scale800}
                  style={{
                    aspectRatio: "0.56",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "48vh",
                    maxWidth: "60%",
                    width: "calc(100vw - 80px)",
                  }}
                >
                  <video
                    autoPlay
                    controls
                    loop
                    muted
                    playsInline
                    style={{
                      border: "none",
                      borderRadius: theme.sizing.scale600,
                      height: "100%",
                      width: "auto",
                    }}
                    tabIndex={-1}
                  >
                    <source
                      src="https://api.stayconnectedsim.com/public/videos/check_eid_dial.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Block>
              </Column>
              <Column>
                <HeadingMedium
                  style={{
                    marginBottom: theme.sizing.scale600,
                    marginTop: theme.sizing.scale600,
                  }}
                >
                  {"Device List"}
                </HeadingMedium>
                {content(translate("compatibility.ios"), iOS)}
                {content(translate("compatibility.android"), android)}
              </Column>
            </Column>
          </Column>
        </Column>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)} style={{ width: "100%" }}>
          {translate("compatibility.confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
