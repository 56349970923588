import { useStyletron } from "baseui";
import { Accordion, Panel } from "baseui/accordion";
import { Button } from "baseui/button";
import { HeadingLarge, ParagraphMedium } from "baseui/typography";
import * as React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "../../../hooks/translate";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import If from "../../if";
import { FontFamily, LP_BUTTON_PRIMARY, USE_FONT } from "../../overrides";
import { FAQ } from "./faq";
import { STYLES } from "./styles";

export const FaqSection = ({
  maxWidth,
  padding,
  showAccordion,
}: {
  maxWidth?: string | undefined;
  padding?: string | undefined;
  showAccordion: boolean;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();

  return (
    <Row className={css(STYLES.container(theme, padding))} id="lp-faq-section">
      <Column className={css(STYLES.wrapper(maxWidth))}>
        <Column
          style={{
            alignItems: "center",
            display: "flex",
            //height: "100vh", // Optional: Ensures full height centering
            justifyContent: "center",
            textAlign: "center", // Center text alignment
          }}
        >
          <HeadingLarge
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "32px"),
              marginBottom: theme.sizing.scale950,
            }}
          >
            {translate("lp.help.heading")}
          </HeadingLarge>
        </Column>
        <Column className={css(STYLES.textWrapper(theme))}>
          <HeadingLarge
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "32px", "24px"),
              marginBottom: theme.sizing.scale300,
            }}
          >
            {translate("lp.help.faq.heading")}
          </HeadingLarge>
          <ParagraphMedium
            style={USE_FONT(FontFamily.DMSans, 400, "20px", "14px")}
          >
            {translate("lp.help.faq.caption")}
          </ParagraphMedium>
        </Column>
        <Column>
          <If condition={showAccordion}>
            <Accordion
              accordion
              onChange={({ expanded }) => {
                if (expanded && expanded.length) {
                  Mixpanel.track("Faq", {
                    action: "click",
                    element: "accordion",
                    id: expanded[0],
                  });
                }
              }}
              overrides={{
                Content: {
                  style: {
                    ...USE_FONT(FontFamily.DMSans, 500, "20px", "15px"),
                  },
                },
                Header: {
                  style: {
                    ...USE_FONT(FontFamily.DMSansMedium, 500, "20px", "16px"),
                    border: `1px solid #000`,
                    borderRadius: theme.sizing.scale300,
                  },
                },
                PanelContainer: {
                  style: {
                    borderBottomStyle: "unset",
                    marginBottom: theme.sizing.scale300,
                  },
                },
              }}
            >
              {FAQ.items.map((item) => (
                <Panel key={item.id} title={item.question}>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                    style={USE_FONT(FontFamily.DMSans, 500, "20px", "16px")}
                  />
                  <If condition={Boolean(item.link)}>
                    <Column style={{ marginTop: theme.sizing.scale300 }}>
                      <Link
                        onClick={() =>
                          item.link?.shouldScroll && window.scrollTo(0, 0)
                        }
                        style={{
                          color: theme.colors.contentPrimary,
                          fontSize: "16px",
                        }}
                        to={item.link?.to as string}
                      >
                        {item.link?.label}
                      </Link>
                    </Column>
                  </If>
                </Panel>
              ))}
            </Accordion>
          </If>
          <Button
            onClick={() => {
              Mixpanel.track("Faq", {
                action: "click",
                element: "button",
                id: "read-full-faq-button",
              });
              window.open(translate("lp.help.faq.primaryButtonUrl"), "_blank");
            }}
            overrides={LP_BUTTON_PRIMARY(theme, {
              marginBottom: theme.sizing.scale300,
            })}
          >
            {translate("lp.help.faq.primaryButton")}
          </Button>
        </Column>
        <Column
          className={css(STYLES.textWrapper(theme))}
          style={{ marginTop: theme.sizing.scale800 }} // Adds space above the column
        >
          <HeadingLarge
            dangerouslySetInnerHTML={{
              __html: translate("lp.help.support.heading"),
            }}
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "32px", "24px"),
              marginBottom: theme.sizing.scale300,
            }}
          />
          <ParagraphMedium
            style={USE_FONT(FontFamily.DMSans, 400, "20px", "14px")}
          >
            {translate("lp.help.support.caption")}
          </ParagraphMedium>
        </Column>
        <Column>
          <Button
            kind="secondary"
            onClick={() => {
              Mixpanel.track("Faq", {
                action: "click",
                element: "button",
                id: "send-us-a-message-button",
              });
              window.open(
                translate("lp.help.support.secondaryButtonUrl"),
                "_blank",
              );
            }}
          >
            {translate("lp.help.support.secondaryButton")}
          </Button>
        </Column>
      </Column>
    </Row>
  );
};
