import { color } from "d3-color";
import React from "react";
//@ts-expect-error @types/react-liquid-gauge doesn't exist
import LiquidFillGauge from "react-liquid-gauge";

export const LiquidChart = ({
  margin,
  radius,
  value,
}: {
  margin: string;
  radius: number;
  value: number;
}): React.ReactNode => {
  const fontFamily = `DMSans-Regular, UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif`;
  const fillColor = "rgb(39, 110, 241)";
  const gradientStops = [
    {
      key: "0%",
      offset: "0%",
      stopColor: color(fillColor)?.darker(1).toString(),
      stopOpacity: 1,
    },
    {
      key: "50%",
      offset: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
    },
    {
      key: "100%",
      offset: "100%",
      stopColor: color(fillColor)?.brighter(2).toString(),
      stopOpacity: 0.5,
    },
  ];
  return (
    <LiquidFillGauge
      circleStyle={{
        fill: "#d0d0d0",
      }}
      gradient
      gradientStops={gradientStops}
      height={radius * 2}
      outerRadius={0.85}
      percent="%"
      riseAnimation
      style={{ margin }}
      textOffsetX={0}
      textOffsetY={2}
      textRenderer={(props: {
        height: number;
        percent: string;
        textSize: number;
        width: number;
      }) => {
        const currValue = parseInt(value.toString().split(".")[0]);
        const decimals = value.toString().includes(".")
          ? `.${value.toString().split(".")[1]}`
          : "";
        const radius = Math.min(props.height / 2, props.width / 2);
        const textPixels = (props.textSize * radius) / 2;
        const valueStyle = {
          fontSize: textPixels,
        };
        const percentStyle = {
          fontSize: textPixels * 0.6,
        };
        const remainingStyle = {
          fontSize: textPixels * 0.5,
        };

        return (
          <tspan>
            <tspan className="value" style={valueStyle}>
              {currValue}
            </tspan>
            <tspan style={percentStyle}>
              {decimals}
              {props.percent}
            </tspan>
            <tspan dy="1.2em" style={remainingStyle} x="0">
              Remaining
            </tspan>
          </tspan>
        );
      }}
      textSize={1}
      textStyle={{
        fill: color("#333")?.toString(),
        fontFamily,
      }}
      value={value}
      waveAmplitude={3}
      waveAnimation
      waveFrequency={2}
      waveStyle={{
        fill: color(fillColor)?.brighter(1).toString(),
      }}
      waveTextStyle={{
        fill: color("#fff")?.toString(),
        fontFamily,
      }}
      width={radius * 2}
    />
  );
};
