import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { ParagraphMedium } from "baseui/typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "../../hooks/translate";
import { ApiContext } from "../../providers/api-provider";
import { Column, Row } from "../containers";
import If from "../if";
import { Logo } from "../logo/logo";
import { FontFamily, USE_FONT } from "../overrides";
import facebook from "./assets/facebook.svg";
import instagram from "./assets/instagram.svg";
import { STYLES } from "./styles";

const Footer = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const apiContext = React.useContext(ApiContext);
  const { translate } = useTranslation();

  return (
    <Row className={css(STYLES.container(theme))} id="footer">
      <Column className={css(STYLES.innerContainer(theme))}>
        <Block className={css(STYLES.aboutContainer(theme))}>
          <Logo
            countryNameColor="#fff"
            frameColor="#fff"
            fullWidth={true}
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            primaryColor="#fff"
            secondaryColor="#fff"
            size={45}
          />
          <Column className={css(STYLES.aboutTextContainer(theme))}>
            <ParagraphMedium
              color="#fff"
              style={{
                ...USE_FONT(FontFamily.DMSansBold, 500, "17px", "14px"),
                marginBottom: theme.sizing.scale200,
              }}
            >
              {translate("footer.title")}
            </ParagraphMedium>
            <ParagraphMedium
              color="#cacaca"
              style={USE_FONT(FontFamily.DMSans, 500, "17px", "14px")}
            >
              {translate("footer.caption")}
            </ParagraphMedium>
          </Column>
        </Block>
        <Block className={css(STYLES.linksContainer(theme))}>
          <Row className={css(STYLES.linksItemContainer(theme, false))}>
            <ParagraphMedium
              color="#fff"
              style={USE_FONT(FontFamily.DMSansBold, 700, "15px", "14px")}
            >
              {translate("footer.social.title")}
            </ParagraphMedium>
            <Row className={css(STYLES.socialMediaContainer(theme))}>
              <img
                alt="instagram"
                height={theme.typography.ParagraphLarge.fontSize}
                onClick={
                  translate("footer.social.instagram")
                    ? () =>
                        window.open(
                          translate("footer.social.instagram"),
                          "_blank",
                        )
                    : undefined
                }
                src={instagram}
                style={{
                  cursor: translate("footer.social.instagram")
                    ? "pointer"
                    : "default",
                  marginLeft: theme.sizing.scale300,
                  marginRight: theme.sizing.scale300,
                }}
                width={theme.typography.ParagraphLarge.fontSize}
              />
              <img
                alt="facebook"
                height={theme.typography.ParagraphLarge.fontSize}
                onClick={
                  translate("footer.social.facebook")
                    ? () =>
                        window.open(
                          translate("footer.social.facebook"),
                          "_blank",
                        )
                    : undefined
                }
                src={facebook}
                style={{
                  cursor: translate("footer.social.facebook")
                    ? "pointer"
                    : "default",
                  marginLeft: theme.sizing.scale300,
                  marginRight: theme.sizing.scale300,
                }}
                width={theme.typography.ParagraphLarge.fontSize}
              />
            </Row>
          </Row>
          <Row className={css(STYLES.linksItemContainer(theme, true))}>
            <ParagraphMedium
              color="#fff"
              style={{
                ...USE_FONT(FontFamily.DMSansBold, 700, "21px", "14px"),
                marginRight: theme.sizing.scale600,
              }}
            >
              {translate("footer.copyright", {
                country: translate("country.name").toUpperCase(),
                year: new Date().getFullYear(),
              })}
            </ParagraphMedium>
            <StyledLink
              href={translate("footer.privacy.url")}
              style={{
                ...USE_FONT(FontFamily.DMSans, 500, "21px", "14px"),
                color: "#fff",
                marginRight: theme.sizing.scale600,
              }}
              target="_blank"
            >
              {translate("footer.privacy.label")}
            </StyledLink>
            <If condition={Boolean(apiContext.terms)} />
            <StyledLink
              href={apiContext.terms?.terms_url}
              style={{
                ...USE_FONT(FontFamily.DMSans, 500, "21px", "14px"),
                color: "#fff",
              }}
              target="_blank"
            >
              {translate("footer.terms")}
            </StyledLink>
          </Row>
        </Block>
      </Column>
    </Row>
  );
};

export default Footer;
