import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ArrowRight } from "baseui/icon";
import { LabelMedium } from "baseui/typography";
import React from "react";

import { usePromotions } from "../../../hooks/use-promotions";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { ApiContext } from "../../../providers/api-provider";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";

export const PreHeaderOffer = (): React.ReactNode => {
  const [, theme] = useStyletron();
  const apiContext = React.useContext(ApiContext);
  const { width: screenWidth } = useScreenSize();
  const { percentOff, shouldSurfacePromos } = usePromotions();

  const maxPercentage = React.useMemo(() => {
    if (apiContext.bundles?.length) {
      const percentages: Array<number> = apiContext.bundles?.map(
        (bundle, index) => percentOff(index, bundle.price),
      );
      return `${Math.max(...percentages)}%`;
    }
    return "-";
  }, [apiContext.bundles]);

  if (!shouldSurfacePromos()) {
    return null;
  }

  return (
    <Block
      style={{
        minHeight: "52px",
      }}
    >
      <Block
        style={{
          left: 0,
          minHeight: "52px",
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: 1,
        }}
      >
        <Column
          onClick={() => {
            Mixpanel.track("CTA", {
              action: "click",
              element: "timed-offer",
              section: "pre-header",
            });
            scrollWithOffset("#pricing-section");
          }}
          style={{
            alignItems: "center",
            background: "#bb1500",
            cursor: "pointer",
            justifyContent: "center",
            padding: "8px 16px",
          }}
        >
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "1366px",
              textDecorationColor: "#FFF",
              width: "100%",
            }}
          >
            <LabelMedium
              color={"#FFF"}
              style={{
                fontSize:
                  screenWidth > theme.breakpoints.small
                    ? theme.typography.LabelMedium.fontSize
                    : theme.typography.LabelSmall.fontSize,
              }}
            >
              {`Special offer, save up to ${maxPercentage}`}
            </LabelMedium>
            <ArrowRight color="#fff" size={30} />
            <LabelMedium
              color={"#FFF"}
              style={{
                background: "#5b0707",
                borderRadius: "8px",
                fontSize:
                  screenWidth > theme.breakpoints.small
                    ? theme.typography.LabelMedium.fontSize
                    : theme.typography.LabelSmall.fontSize,
                marginLeft: theme.sizing.scale300,
                padding: "8px 16px",
              }}
            >
              {"Buy Now"}
            </LabelMedium>
          </Row>
        </Column>
      </Block>
    </Block>
  );
};
