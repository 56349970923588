import { styled } from "baseui";
import { Block } from "baseui/block";
import * as React from "react";

type ElementPropsT = React.ComponentProps<typeof Block>;
type RefT = React.Ref<typeof Block>;

export const Column = React.forwardRef((props: ElementPropsT, ref: RefT) => {
  const element = React.createElement(Block, {
    display: "flex",
    flexDirection: "column",
    ...props,
    ref: ref,
  });
  return element;
});
Column.displayName = "Column";

export const Row = React.forwardRef(
  (props: ElementPropsT & { gap: number | undefined }, ref: RefT) => {
    return React.createElement(Block, {
      display: "flex",
      flexDirection: "row",
      ...props,
      ref: ref,
    });
  },
);
Row.displayName = "Row";

export const DynamicFlex = React.forwardRef(
  (props: ElementPropsT & { flexDirection: "column" | "row" }, ref: RefT) => {
    if (!props.flexDirection) {
      throw "flexDirection prop is required on <DynamicFlex/>";
    }
    const elementProps = {
      display: "flex",
      ...props,
      ref: ref,
    } as const;
    return React.createElement(Block, elementProps);
  },
);
DynamicFlex.displayName = "DynamicFlex";

export const NoShrinkContainer = styled<
  "div",
  {
    $width: number;
  }
>("div", ({ $width }) => ({
  display: "flex",
  flexShrink: 0,
  width: `${$width}px`,
}));
