import { useStyletron } from "baseui";
import * as React from "react";

import { Column, Row } from "../containers";
import Footer from "../footer";
import Header from "../header";
import { STYLES } from "./styles";

const MasterPage = ({
  children,
  preHeader,
}: {
  children?: React.ReactNode;
  preHeader?: React.ReactNode;
}): React.ReactNode => {
  const [css, theme] = useStyletron();

  return (
    <React.Fragment>
      {preHeader}
      <Header />
      <Row className={css(STYLES.container(theme))}>
        <Column className={css(STYLES.innerContainer(theme))}>
          {children}
        </Column>
      </Row>
      <Footer />
    </React.Fragment>
  );
};

export default MasterPage;
