import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
} from "baseui/header-navigation";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import { scrollWithOffset } from "../../utils";
import { Mixpanel } from "../../utils/mixpanel";
import { Row } from "../containers";
import If from "../if";
import { Logo } from "../logo/logo";
import { LP_BUTTON_PRIMARY } from "../overrides";
import support from "./assets/support.svg";
import { STYLES } from "./styles";

const Header = () => {
  const { width: screenWidth } = useScreenSize();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [css, theme] = useStyletron();

  const showPurchaseMenu = ["/access"].some((path) =>
    window.location.pathname.startsWith(path),
  );
  const showSupportMenu = [
    "/",
    "/thank-you",
    "/esim-installation-guide",
  ].includes(window.location.pathname);
  const showManageMenu = [
    "/",
    "/thank-you",
    "/esim-installation-guide",
  ].includes(window.location.pathname);

  return (
    <React.Fragment>
      <HeaderNavigation
        overrides={{
          Root: {
            props: {
              id: "header-navigation",
            },
            style: () => STYLES.container(theme),
          },
        }}
      >
        <div className={css(STYLES.innerContainer)}>
          <StyledNavigationList $align={ALIGN.left}>
            <StyledNavigationItem
              style={{
                paddingLeft: theme.sizing.scale600,
              }}
            >
              <Row style={{ alignItems: "center" }}>
                <Logo
                  fullWidth={true}
                  onClick={() => {
                    Mixpanel.track("CTA", {
                      action: "click",
                      element: "logo",
                      section: "header",
                    });
                    navigate("/");
                  }}
                  size={40}
                />
              </Row>
            </StyledNavigationItem>
          </StyledNavigationList>
          <StyledNavigationList
            $align={ALIGN.center}
            style={{ paddingLeft: "0", paddingRight: "0" }}
          />
          <StyledNavigationList $align={ALIGN.right}>
            <StyledNavigationItem
              style={{
                paddingLeft: "unset",
                paddingRight: theme.sizing.scale600,
              }}
            >
              <Row>
                <If condition={showSupportMenu}>
                  <Button
                    kind="tertiary"
                    onClick={() => {
                      Mixpanel.track("CTA", {
                        action: "click",
                        element: "support",
                        section: "header",
                      });
                      scrollWithOffset("#lp-faq-section", -60);
                    }}
                    shape="pill"
                    size="mini"
                    startEnhancer={
                      screenWidth > 600
                        ? () => <img src={support} />
                        : undefined
                    }
                    style={{
                      marginRight: theme.sizing.scale300,
                      textAlign: "center",
                    }}
                  >
                    {screenWidth > 600 ? (
                      translate("header.short.support")
                    ) : (
                      <img src={support} />
                    )}
                  </Button>
                </If>
                <If condition={showManageMenu}>
                  <Button
                    onClick={() => {
                      Mixpanel.track("CTA", {
                        action: "click",
                        element: "manage-your-esim",
                        section: "header",
                      });
                      navigate("/access");
                    }}
                    overrides={LP_BUTTON_PRIMARY(theme)}
                    shape="pill"
                    size="compact"
                  >
                    {screenWidth > 380
                      ? translate("header.long.manage")
                      : translate("header.short.manage")}
                  </Button>
                </If>
                <If condition={showPurchaseMenu}>
                  <Button
                    onClick={() => {
                      Mixpanel.track("CTA", {
                        action: "click",
                        element: "purchase-yours",
                        section: "header",
                      });
                      navigate("/");
                    }}
                    overrides={LP_BUTTON_PRIMARY(theme)}
                    shape="pill"
                    size="compact"
                  >
                    {screenWidth > 380
                      ? translate("header.long.purchase")
                      : translate("header.short.purchase")}
                  </Button>
                </If>
              </Row>
            </StyledNavigationItem>
          </StyledNavigationList>
        </div>
      </HeaderNavigation>
    </React.Fragment>
  );
};

export default Header;
