export const getLineDetail = (color: string): string => {
  const icon = `<svg
      fill="none"
      height="2581"
      view-box="0 0 1731 2581"
      width="1731"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1717 383L1305.5 20H20V2561H1717"
        stroke="${color}"
        stroke-line-join="round"
        stroke-width="40"
      />
    </svg>`;
  return `data:image/svg+xml;base64,${btoa(icon)}`;
};
