import * as React from "react";

import { API } from "../../api";
import { useTranslation } from "../../hooks/translate";
import { ApiContextT } from "./types";

export const ApiContext = React.createContext<ApiContextT>({
  bundles: undefined,
  storeFirebaseMessagingToken: undefined,
  terms: undefined,
});

export const ApiProvider = ({ children }: { children?: React.ReactNode }) => {
  const { translate } = useTranslation();
  const [bundles, setBundles] = React.useState<ApiContextT["bundles"]>();
  const [terms, setTerms] = React.useState<ApiContextT["terms"]>();
  const [fcmData, setFcmData] = React.useState<{
    customerId?: string;
    token?: string;
  }>({
    customerId: undefined,
    token: undefined,
  });

  const fetchBundles = React.useCallback(async () => {
    try {
      if (!bundles) {
        const fetchedBundles = await API.bundles({
          body: {
            country: encodeURIComponent(translate("country.isos.country")),
            display: encodeURIComponent(translate("country.name")),
            network: encodeURIComponent(translate("country.isos.network")),
          },
        });
        setBundles(fetchedBundles);
      }
    } catch (error) {
      console.error(error);
      setTimeout(fetchBundles, 2500);
    }
  }, [bundles]);

  const fetchTerms = React.useCallback(async () => {
    try {
      if (!terms) {
        const fetchedTerms = await API.terms();
        setTerms(fetchedTerms);
      }
    } catch (error) {
      console.error(error);
      setTimeout(fetchTerms, 2500);
    }
  }, [terms]);

  const storeFirebaseMessagingToken = React.useCallback(
    async ({ customerId, token }: { customerId?: string; token?: string }) => {
      if (token) {
        setFcmData((data) => ({ ...data, token }));
      }
      if (customerId) {
        setFcmData((data) => ({ ...data, customerId }));
      }

      const currentToken = token || fcmData.token;
      const currentCustomerId = customerId || fcmData.customerId;

      try {
        if (currentToken) {
          await API.storeFirebaseMessagingToken({
            body: {
              customer_id: currentCustomerId,
              origin: window.location.origin,
              token: currentToken,
            },
          });
        }
      } catch (error) {
        console.error("unexpected error storing fcm token");
      }
    },
    [fcmData.customerId, fcmData.token],
  );

  React.useEffect(() => {
    fetchBundles();
    fetchTerms();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        bundles,
        storeFirebaseMessagingToken,
        terms,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
