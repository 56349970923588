import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme) => ({
    alignItems: "center",
    background: "#fff",
    justifyContent: "center",
    minHeight: "190px",
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale900}`,
  }),
  grid: (theme: Theme, screenWidth: number) =>
    ({
      display: "grid",
      gap: theme.sizing.scale600, // grid-gap
      gridTemplateColumns:
        screenWidth > theme.breakpoints.medium
          ? `repeat(auto-fit, calc(50% - ${theme.sizing.scale300}))`
          : `repeat(1, calc(${screenWidth}px - 64px)))`,
      gridTemplateRows: "repeat(auto-fit, minmax(165px, 2fr))",
      justifyContent: "center",
      width: "100%",
    }) as const,
  gridItem: (theme: Theme) =>
    ({
      alignItems: "center",
      border: "1px solid #d3d3d3",
      borderRadius: theme.sizing.scale600,
      justifyContent: "space-between",
      padding: theme.sizing.scale600,
      width: `calc(100% - 2 * ${theme.sizing.scale600})`,
    }) as const,
  headingWrapper: (theme: Theme) => ({
    marginBottom: theme.sizing.scale900,
  }),
  wrapper: {
    maxWidth: "1144px",
    width: "100%",
  },
};
