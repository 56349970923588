const validateFullName = (fullName: string) => {
  // Regular expression to match a full name (first and last name) with Unicode support
  const nameRegex = /^[\p{L}\p{M}\s]+$/u;
  // Check if the input matches the regular expression
  if (nameRegex.test(fullName)) {
    // Ensure the name contains at least two words
    const nameParts = fullName.trim().split(/\s+/);
    if (nameParts.length >= 2) {
      return true;
    }
  }
  return false;
};

const validateZipCode = (zipCode: string) => {
  // General regular expression to match common zip code formats
  const genericZipCodeRegex = /^[a-zA-Z0-9\s-]{4,15}$/;
  // Check if the input matches the regular expression
  return genericZipCodeRegex.test(zipCode);
};

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const checkValueFor = (value: string | number | object | undefined | null) => {
  if (!value) {
    return false;
  }
  return true;
};

export { checkValueFor, validateEmail, validateFullName, validateZipCode };
