import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  container: (theme: Theme) => ({
    backgroundColor: (theme as CustomTheme).custom.foregroundColor,
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "1920px",
    width: "100%",
  }),
  innerContainer: (theme: Theme) => ({
    backgroundColor: (theme as CustomTheme).custom.backgroundColor,
    maxWidth: "1920px",
    // viewport.height - header.height - footer.height
    minHeight: "calc(100vh - 132px - 240px)",
    overflow: "hidden",
    width: "100%",
  }),
};
