import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import {
  HeadingSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../../hooks/translate";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import { FontFamily, LP_BUTTON_PRIMARY, USE_FONT } from "../../overrides";
import { PromoButton } from "../../promo-button";
import hero from "./assets/hero.png";
import hero2x from "./assets/hero@2x.png";
import { STYLES } from "./styles";

export const LpHeroSection = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();

  const items = [
    {
      caption: translate("lp.hero.benefits.first.caption"),
      title: translate("lp.hero.benefits.first.heading"),
    },
    {
      caption: translate("lp.hero.benefits.second.caption"),
      title: translate("lp.hero.benefits.second.heading"),
    },
    {
      caption: translate("lp.hero.benefits.third.caption"),
      title: translate("lp.hero.benefits.third.heading"),
    },
    {
      caption: translate("lp.hero.benefits.fourth.caption"),
      title: translate("lp.hero.benefits.fourth.heading"),
    },
  ];

  return (
    <Row className={css(STYLES.container(theme))}>
      <Column>
        <Row className={css(STYLES.wrapper(theme))}>
          <img
            className={css(STYLES.image(theme))}
            src={hero}
            srcSet={`${hero2x} 2x`}
          />
          <Column className={css(STYLES.textWrapper(theme))}>
            <Column className={css(STYLES.headingWrapper(theme))}>
              <HeadingSmall
                className={css(STYLES.heading(theme))}
                style={{
                  ...USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "28px"),
                  marginBottom: theme.sizing.scale300,
                }}
              >
                {translate("lp.hero.heading")}
              </HeadingSmall>
              <ParagraphMedium
                style={USE_FONT(FontFamily.DMSans, 400, "24px", "16px")}
              >
                {translate("lp.hero.caption")}
              </ParagraphMedium>
            </Column>
            <Column className={css(STYLES.benefitsWrapper(theme))}>
              {items.map((item, index) => (
                <ParagraphSmall
                  key={`hero-benefit-item-${index}`}
                  style={{
                    ...USE_FONT(FontFamily.DMSans, 400, "32px", "16px"),
                    marginBottom: theme.sizing.scale300,
                  }}
                >
                  <strong
                    style={{
                      ...USE_FONT(
                        FontFamily.SatoshiVariable,
                        700,
                        "32px",
                        "16px",
                      ),
                      display:
                        screenWidth > theme.breakpoints.large
                          ? "inline"
                          : "block",
                      marginRight: theme.sizing.scale100,
                    }}
                  >
                    {item.title}
                  </strong>
                  {item.caption}
                </ParagraphSmall>
              ))}
            </Column>
          </Column>
        </Row>
        <PromoButton
          content="Save BIG Today"
          style={{
            marginBottom: theme.sizing.scale600,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.sizing.scale800,
          }}
        >
          <Button
            onClick={() => {
              Mixpanel.track("CTA", {
                action: "click",
                element: "purchase-now",
                section: "hero-section",
              });
              scrollWithOffset("#purchase-form", -240);
            }}
            overrides={LP_BUTTON_PRIMARY(theme, {
              ...USE_FONT(FontFamily.DMSans, 500, "20px", "18px"),
              paddingLeft: theme.sizing.scale1200,
              paddingRight: theme.sizing.scale1200,
            })}
          >
            {translate("lp.hero.button")}
          </Button>
        </PromoButton>
      </Column>
    </Row>
  );
};
