import mixpanel, { Dict } from "mixpanel-browser";

mixpanel.init("131061dd3d8e05908d2faa2d1f7283a7");
const env = process.env.NODE_ENV as string;
const enabled = ["production"];
const userAgent = navigator.userAgent;
// exclude events from non-production envs and from headless browsers
const isEnabled =
  enabled.includes(env) && !userAgent.toLowerCase().includes("headless");

const actions = {
  alias: (id: string) => {
    if (isEnabled) mixpanel.alias(id);
  },
  identify: (id: string | undefined) => {
    if (isEnabled) mixpanel.identify(id);
  },
  people: {
    set: (props: Dict) => {
      if (isEnabled) mixpanel.people.set(props);
    },
  },
  track: (name: string, props?: Dict | undefined) => {
    if (isEnabled) mixpanel.track(name, { ...(props || {}), env });
  },
};

export const Mixpanel = actions;
