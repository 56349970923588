import base from "./base";

// used to query the API
// injected into translations
// used to query lp banner image at:
// public/images/{{country.name}}/banner.jpg
// public/images/{{country.name}}/banner@2x.jpg
const country = {
  country: {
    isos: {
      country: "Europe+",
      network: "IT",
    },
    isRegional: true,
    name: "Italy",
    region: "Europe",
  },
};

// used to personalize theme colors
const theme = {
  theme: {
    colors: {
      logo: {
        country: "#f70d1a",
        frame: "#3A4155",
        primary: "#3e7e1e",
        secondary: "#f70d1a",
      },
      lp: {
        backgroundPattern: "linear-gradient(121deg, #3e7e1e 40%, #D52B1E 95%)",
        benefitsIconDetail: "#f70d1a",
        primaryButton: "#008C8C",
        primaryButtonHover: "#006C6C",
        purchaseFormLineDetail: "#f70d1a",
      },
    },
    custom: {
      backgroundColor: "#F3F3F3",
      banner: {
        backgroundPosition: "right -25px",
        backgroundPositionLarge: "right -25px",
        backgroundPositionMedium: "right -25px",
        useBannerAsPattern: {
          backgroundPosition: "center -25px",
          isEnabled: "true",
          opacity: "0.4",
        },
      },
      footerColor: "#444444",
      foregroundColor: "#F3F3F3",
      headerColor: "#FFF",
    },
  },
};

export default {
  ...base,
  ...country,
  ...theme,
};
