import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementReadyEvent,
} from "@stripe/stripe-js";
import { toaster } from "baseui/toast";
import * as React from "react";

import { PurchaseRequestT } from "../../api/types";
import { ApiContext } from "../../providers/api-provider";
import { StripeWalletContext } from "../../providers/api-provider/stripe";
import { Mixpanel } from "../../utils/mixpanel";
import { EnumPurchaseFormType } from "../purchase-form";

export const StripeWallet = ({
  checkForm,
  form,
  formType,
  isHidden,
  isLoading,
  onWalletSubmit,
}: {
  checkForm: (card: boolean) => boolean;
  form: PurchaseRequestT | PurchaseRequestT;
  formType: EnumPurchaseFormType;
  isHidden: boolean;
  isLoading: boolean;
  onWalletSubmit: (
    form: object,
  ) => Promise<{ clientSecret?: string; status: boolean }>;
  targetId: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const apiContext = React.useContext(ApiContext);
  const { setSupportWallet, supportWallet } =
    React.useContext(StripeWalletContext);
  // Optional: If you're doing custom animations, hide the Element
  const [visibility, setVisibility] = React.useState<"hidden" | "initial">(
    "hidden",
  );
  const bundle = React.useMemo(() => {
    return apiContext.bundles?.find((b) => b.name === form.bundle_name);
  }, [apiContext.bundles, form.bundle_name]);

  React.useEffect(() => {
    if (!elements || !bundle) {
      return;
    }
    elements.update({
      amount:
        formType === EnumPurchaseFormType.Purchase
          ? bundle?.price * form.quantity * 100
          : bundle?.price * 100,
      currency: "usd",
      mode: "payment",
    });
  }, [elements, bundle, formType, form.quantity]);

  const onReady = React.useCallback(
    ({ availablePaymentMethods }: StripeExpressCheckoutElementReadyEvent) => {
      if (!availablePaymentMethods) {
        // No buttons will show
        if (supportWallet) {
          setSupportWallet(false);
        }
      } else {
        // Optional: Animate in the Element
        if (!supportWallet) {
          setSupportWallet(true);
        }
        setVisibility("initial");
      }
    },
    [supportWallet, setSupportWallet, setVisibility],
  );

  const onClick = React.useCallback(
    ({
      expressPaymentType,
      resolve,
    }: StripeExpressCheckoutElementClickEvent) => {
      if (!checkForm(false) || !bundle) {
        return;
      }
      Mixpanel.track("Purchase", {
        action: "selected",
        method: expressPaymentType,
        type: formType.toString().toLocaleLowerCase(),
      });
      const item = {
        amount:
          formType === EnumPurchaseFormType.Purchase
            ? bundle?.price * form.quantity * 100
            : bundle?.price * 100,
        name: bundle.desc,
      };
      const options = {
        business: {
          name: "Stay Connected",
        },
        emailRequired: true,
        lineItems: item ? [item] : undefined,
      };
      resolve(options);
    },
    [checkForm, bundle, formType, form],
  );

  const onConfirm = React.useCallback(
    async (event: StripeExpressCheckoutElementConfirmEvent) => {
      if (!stripe) {
        // Stripe.js hasn't loaded yet.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (!elements) {
        return;
      }

      if (!bundle) {
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        toaster.negative(submitError.message);
        return;
      }

      Mixpanel.track("Purchase", {
        action: "confirmed",
        bundle: form.bundle_name,
        method: event.expressPaymentType,
        price: bundle.price,
        quantity: form.quantity,
        total: bundle.price * form.quantity,
        type: formType.toString().toLocaleLowerCase(),
      });

      // Create a ConfirmationToken using the details collected by the Express Checkout Element
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: event.billingDetails?.name,
          },
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
        toaster.negative(error.message);
        return;
      }

      // Send the ConfirmationToken ID to your server for additional logic and attach the ConfirmationToken
      const { clientSecret, status } = await onWalletSubmit({
        ...form,
        is_wallet: true,
        payer_email: event.billingDetails?.email,
        payer_name: event.billingDetails?.name,
        payment_method_id: paymentMethod.id,
        payment_type: event.expressPaymentType,
      });

      if (!status || !clientSecret) {
        event.paymentFailed({
          reason: "fail",
        });
        return;
      }

      // Confirm the PaymentIntent
      const { error: confirmError } = await stripe.confirmPayment({
        clientSecret,
        confirmParams: {
          payment_method: paymentMethod.id,
          return_url:
            formType === EnumPurchaseFormType.Purchase
              ? `${window.location.origin}/thank-you`
              : window.location.href,
        },
        redirect: "if_required",
      });

      if (confirmError) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        toaster.negative(confirmError.message);
      } else {
        // The payment UI automatically closes with a success animation.
        // Your customer is redirected to your `return_url`.
        console.log("success");
      }
    },
    [stripe, elements, bundle, form, formType],
  );

  return (
    <div
      id="checkout-page"
      style={{
        display: isLoading || isHidden ? "none" : "block",
        marginTop: "8px",
      }}
    >
      <div
        id="express-checkout-element"
        style={{ visibility: isLoading || isHidden ? "hidden" : visibility }}
      >
        <ExpressCheckoutElement
          onClick={onClick}
          onConfirm={onConfirm}
          onReady={onReady}
          options={{
            buttonHeight: 48,
            buttonType: {
              applePay: "plain",
              googlePay: "plain",
            },
            layout: {
              maxColumns: 1,
              maxRows: 2,
            },
            paymentMethods: {
              amazonPay: "never",
              applePay: "always",
              googlePay: "always",
              link: "never",
              paypal: "never",
            },
          }}
        />
      </div>
    </div>
  );
};
