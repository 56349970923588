import { Theme } from "baseui";

export const STYLES = {
  banner: (
    theme: Theme,
    banner: string,
    banner2x: string,
    backgroundPosition: string,
    backgroundPositionLarge: string,
    backgroundPositionMedium: string,
  ) =>
    ({
      background: `image-set(url(${banner}) 1x, url(${banner2x}) 2x) repeat`,
      flexGrow: "1",
      maxWidth: "1920px",
      minWidth: "50%",
      [`@media screen and (min-width: ${theme.breakpoints.large + 300}px)`]: {
        backgroundPosition: backgroundPosition,
      },
      [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
        backgroundPosition: backgroundPositionLarge,
      },
      [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
        backgroundPosition: backgroundPositionMedium,
      },
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        display: "none",
      },
    }) as const,
  contentWrapper: (theme: Theme) =>
    ({
      alignItems: "start",
      maxWidth: "410px",
      textAlign: "left",
      zIndex: "1",
      [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
        paddingLeft: theme.sizing.scale900,
        paddingRight: "256px",
      },
      [`@media screen and (min-width: ${theme.breakpoints.medium}px)`]: {
        paddingLeft: theme.sizing.scale900,
        paddingRight: theme.sizing.scale900,
      },
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        maxWidth: "100%",
        paddingLeft: theme.sizing.scale800,
        paddingRight: theme.sizing.scale800,
      },
    }) as const,
  pattern: (theme: Theme) =>
    ({
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
        alignItems: "end",
      },
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "center",
      },
    }) as const,
  wrapper: {
    maxHeight: "560px",
    minHeight: "560px",
    zIndex: 0,
  },
};
