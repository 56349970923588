import { Theme } from "baseui";

import { FontFamily, USE_FONT } from "../overrides";

export const STYLES = {
  listItemOption: (theme: Theme, isLast: boolean) =>
    ({
      ...USE_FONT(FontFamily.DMSans, 500, "20px", "16px"),
      [":hover"]: {
        background: theme.colors.gray100,
      },
      alignItems: "center",
      borderBottom: isLast
        ? "none"
        : `1px solid ${theme.borders.border100.borderColor}`,
      cursor: "pointer",
      display: "flex",
      minHeight: theme.sizing.scale1200,
      padding: `0 ${theme.sizing.scale600}`,
    }) as const,
  listItemRow: {
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "200px",
  },
};
