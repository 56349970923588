import { Theme } from "baseui";

export const STYLES = {
  baseSection: (theme: Theme) => ({
    alignItems: "center",
    margin: "0 auto",
    marginBottom: theme.sizing.scale600,
    maxWidth: "1244px",
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale600}`,
  }),
  container: (theme: Theme) => ({
    background: "white",
    maxWidth: "1920px",
    minHeight: "60vh",
    paddingTop: theme.sizing.scale900,
  }),
  itemsCard: (theme: Theme) => ({
    alignItems: "center",
    background: "#fff",
    border: "2px solid #E8E8E8",
    borderRadius: theme.sizing.scale500,
    justifyContent: "center",
    marginBottom: theme.sizing.scale600,
    marginLeft: theme.sizing.scale600,
    marginRight: theme.sizing.scale600,
    minHeight: "250px",
    minWidth: "250px",
    padding: theme.sizing.scale900,
    width: "calc(100% / 3 - 32px)",
  }),
  itemsContainer: (theme: Theme) => ({
    margin: `${theme.sizing.scale900} auto`,
    maxWidth: "1244px",
    padding: `${theme.sizing.scale900} 0px`,
    width: `calc(100% - ${theme.sizing.scale1600})`,
  }),
  itemsContainerWrapper: {
    background: "#F3F3F3",
    margin: "0 auto",
  },
  orderSection: {
    margin: "0 auto",
    maxWidth: "700px",
    width: "100%",
  },
};
