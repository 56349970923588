import * as React from "react";
import { useLocation } from "react-router-dom";

import { Mixpanel } from "../utils/mixpanel";

export const Analytics = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const location = useLocation();

  React.useEffect(() => {
    Mixpanel.track("PageView");
  }, [location.pathname]);

  return <React.Fragment>{children}</React.Fragment>;
};
