import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Card, StyledBody } from "baseui/card";
import { ProgressBar, SIZE } from "baseui/progress-bar";
import { StyledBodyCell, StyledHeadCell, StyledTable } from "baseui/table-grid";
import {
  LabelLarge,
  LabelMedium,
  ParagraphSmall,
  ParagraphXSmall,
} from "baseui/typography";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";

import { EsimDetailsResponseT, OrderFragmentT } from "../../api/types";
import { useScreenSize } from "../../hooks/use-screen-size";
import { Column, Row } from "../containers";
import If from "../if";
import { LiquidChart } from "../liquid-chart";
import Loading from "../loading";
import { STYLES } from "./styles";

export const EsimCard = ({
  currentOrder,
  esimDetails,
  isParentLoading,
  orderId,
}: {
  currentOrder: OrderFragmentT | undefined;
  esimDetails: EsimDetailsResponseT | undefined;
  isParentLoading: boolean;
  orderId?: string;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { width: screenWidth } = useScreenSize();

  const qrcode = `LPA:1$${esimDetails?.esim?.status?.smdpAddress}$${esimDetails?.esim?.status?.matchingId}`;

  const installedAt = React.useMemo(() => {
    if (esimDetails?.esim?.status?.firstInstalledDateTime) {
      return moment(esimDetails?.esim?.status?.firstInstalledDateTime).format(
        "YYYY-MM-DD",
      );
    }
    return "Not Installed";
  }, [esimDetails?.esim?.status?.firstInstalledDateTime]);

  const progressBarValue = React.useCallback(
    (remaining: number, initial: number, reverse: boolean) => {
      const percentage = remaining / initial;
      if (reverse) {
        return parseFloat(
          (
            100 - parseFloat((Math.abs(percentage - 1) * 100).toFixed(1))
          ).toFixed(1),
        );
      }
      return parseFloat((Math.abs(percentage - 1) * 100).toFixed(1));
    },
    [],
  );

  const dateFormatted = React.useCallback(
    (date: Date | undefined) => {
      if (date) {
        const formattedDate = moment(date).format(
          screenWidth > theme.breakpoints.small
            ? "YYYY-MM-DD HH:mm"
            : "YYYY-MM-DD",
        );
        return screenWidth > theme.breakpoints.small
          ? `${formattedDate} GMT`
          : formattedDate;
      }
      return "-";
    },
    [screenWidth],
  );

  const bundleSections = React.useMemo(() => {
    const sections = [];
    const activeBundles = esimDetails?.esim?.bundles
      ?.map((bundle) => {
        const assignments = bundle.assignments.filter(
          (assignment) => assignment.bundleState === "active",
        );
        return assignments.map((assignment) => ({
          ...assignment,
          bundle: { ...bundle, assignments: undefined },
        }));
      })
      .flat()
      .sort(
        (a, b) =>
          +new Date(a.assignmentDateTime) - +new Date(b.assignmentDateTime),
      );
    if (activeBundles?.length) {
      sections.push({
        assignments: activeBundles,
        name: "Active Data Bundles",
      });
    }

    const queuedBundles = esimDetails?.esim?.bundles
      ?.map((bundle) => {
        const assignments = bundle.assignments.filter(
          (assignment) => assignment.bundleState === "queued",
        );
        return assignments.map((assignment) => ({
          ...assignment,
          bundle: { ...bundle, assignments: undefined },
        }));
      })
      .flat()
      .sort(
        (a, b) =>
          +new Date(a.assignmentDateTime) - +new Date(b.assignmentDateTime),
      );
    if (queuedBundles?.length) {
      sections.push({
        assignments: queuedBundles,
        name: "Queued Data Bundles",
      });
    }

    return sections;
  }, [orderId, esimDetails?.esim]);

  return (
    <Column>
      {/* TODO: translate */}
      <Card
        overrides={{ Root: { style: { marginBottom: "16px" } } }}
        title={
          <Column>
            <LabelMedium margin={0}>Assigned eSIM</LabelMedium>
            <ParagraphXSmall
              margin={0}
            >{`For Order ${orderId}`}</ParagraphXSmall>
          </Column>
        }
      >
        <Loading isLoading={isParentLoading && !esimDetails}>
          <StyledBody>
            <Row style={{ marginBottom: "8px" }}>
              <Column
                style={{
                  marginRight: "8px",
                  overflowWrap: "anywhere",
                  width: "calc(50% - 4px)",
                }}
              >
                <LabelMedium margin={0}>eSIM</LabelMedium>
                <ParagraphSmall margin={0}>
                  {esimDetails?.esim?.status?.iccid}
                </ParagraphSmall>
              </Column>
              <Column style={{ width: "calc(50% - 4px)" }}>
                <LabelMedium margin={0}>Status</LabelMedium>
                <ParagraphSmall margin={0}>
                  {esimDetails?.esim?.status?.profileStatus}
                </ParagraphSmall>
              </Column>
            </Row>
            <Row>
              <Column
                style={{
                  flexWrap: "wrap",
                  marginRight: "8px",
                  width: "calc(50% - 4px)",
                }}
              >
                <LabelMedium margin={0}>PIN</LabelMedium>
                <ParagraphSmall margin={0}>
                  {esimDetails?.esim?.status?.pin}
                </ParagraphSmall>
              </Column>
              <Column style={{ width: "calc(50% - 4px)" }}>
                <LabelMedium margin={0}>PUK</LabelMedium>
                <ParagraphSmall margin={0}>
                  {esimDetails?.esim?.status?.puk}
                </ParagraphSmall>
              </Column>
            </Row>
          </StyledBody>
        </Loading>
      </Card>
      <Card
        overrides={{ Root: { style: { marginBottom: "16px" } } }}
        title={
          <Column>
            <LabelMedium margin={0}>Your Setup QR-Code</LabelMedium>
            <ParagraphXSmall
              margin={0}
            >{`For Order ${orderId}`}</ParagraphXSmall>
          </Column>
        }
      >
        <Loading isLoading={isParentLoading && !esimDetails}>
          <StyledBody>
            <Block
              style={{
                display: "flex",
                flexDirection: screenWidth > 580 ? "row" : "column",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            >
              <Block style={{ position: "relative" }}>
                <img
                  src={`https://email.stayconnectedsim.com/qr-code?data=${encodeURIComponent(qrcode)}`}
                  style={{
                    height: "100%",
                    left: 0,
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                  }}
                />
                <QRCodeSVG
                  bgColor="#FFF"
                  fgColor="#3B3B3B"
                  height={screenWidth > 420 ? "300px" : "auto"}
                  imageSettings={{
                    excavate: true,
                    height: 23,
                    src: `${window.location.origin}/logo152.png`,
                    width: 23,
                  }}
                  value={qrcode}
                  width={screenWidth > 580 ? "300px" : "100%"}
                />
              </Block>
              <Column
                style={{
                  marginLeft: screenWidth > 580 ? "16px" : "0px",
                  marginTop: screenWidth > 580 ? "0px" : "16px",
                }}
              >
                <Column style={{ marginBottom: "8px" }}>
                  <LabelMedium margin={0}>First Installed Date</LabelMedium>
                  <ParagraphSmall margin={0}>{installedAt}</ParagraphSmall>
                </Column>
                <Column style={{ marginBottom: "8px" }}>
                  <LabelMedium margin={0}>SMDP+ Address</LabelMedium>
                  <ParagraphSmall margin={0}>
                    {esimDetails?.esim?.status?.smdpAddress}
                  </ParagraphSmall>
                </Column>
                <Column>
                  <LabelMedium margin={0}>SMDP+ ID</LabelMedium>
                  <ParagraphSmall margin={0}>
                    {esimDetails?.esim?.status?.matchingId}
                  </ParagraphSmall>
                </Column>
              </Column>
            </Block>
          </StyledBody>
        </Loading>
      </Card>
      <If
        condition={
          !isParentLoading && Boolean(currentOrder?.autoTopUps?.length || 0)
        }
      >
        <Column style={{ marginBottom: "16px", textAlign: "center" }}>
          <LabelLarge>Current Auto Top-Up Settings</LabelLarge>
        </Column>
        <Card overrides={{ Root: { style: { marginBottom: "16px" } } }}>
          <StyledBody>
            <LabelMedium margin={0}>Auto Top-Up Bundle</LabelMedium>
            <ParagraphSmall margin={0}>
              {currentOrder?.autoTopUps[0]?.bundle?.description}
            </ParagraphSmall>
          </StyledBody>
        </Card>
      </If>
      <If condition={!isParentLoading && Boolean(esimDetails)}>
        {(bundleSections || [])
          .map((section, index) => {
            return (
              <React.Fragment key={`${section.name}-${index}`}>
                <Column style={{ marginBottom: "16px", textAlign: "center" }}>
                  <LabelLarge>{section.name}</LabelLarge>
                </Column>
                {section.assignments.map((assignment) => (
                  <Card
                    key={assignment.id}
                    overrides={{ Root: { style: { marginBottom: "16px" } } }}
                    title={
                      <Column>
                        <LabelMedium margin={0}>
                          {assignment.bundle.description}
                        </LabelMedium>
                        <ParagraphXSmall
                          margin={0}
                        >{`For Order ${orderId} - (${assignment.bundleState})`}</ParagraphXSmall>
                      </Column>
                    }
                  >
                    <If condition={assignment.bundleState === "active"}>
                      <StyledBody>
                        <Row
                          style={{
                            flexDirection:
                              screenWidth > theme.breakpoints.small
                                ? "row"
                                : "column",
                          }}
                        >
                          <LiquidChart
                            margin={
                              screenWidth > theme.breakpoints.small
                                ? "0 auto"
                                : "0 auto 8px auto"
                            }
                            radius={
                              screenWidth > theme.breakpoints.small ? 46 : 58
                            }
                            value={progressBarValue(
                              assignment.remainingQuantity,
                              assignment.initialQuantity,
                              true,
                            )}
                          />
                          <Column
                            style={{
                              height: "85px",
                              width:
                                screenWidth > theme.breakpoints.small
                                  ? "calc(100% - 120px)"
                                  : "100%",
                            }}
                          >
                            <StyledTable
                              $gridTemplateColumns={["50%", "50%"].join(" ")}
                              $style={STYLES.table}
                              role="grid"
                            >
                              <div className={css(STYLES.tableRow)} role="row">
                                <StyledHeadCell>
                                  {screenWidth > theme.breakpoints.small
                                    ? "Start Time"
                                    : "Start"}
                                </StyledHeadCell>
                                <StyledHeadCell>
                                  {screenWidth > theme.breakpoints.small
                                    ? "End Time"
                                    : "End"}
                                </StyledHeadCell>
                              </div>
                              <div className={css(STYLES.tableRow)} role="row">
                                <StyledBodyCell>
                                  {dateFormatted(assignment.startTime)}
                                </StyledBodyCell>
                                <StyledBodyCell>
                                  {dateFormatted(assignment.endTime)}
                                </StyledBodyCell>
                              </div>
                            </StyledTable>
                          </Column>
                        </Row>
                        <ProgressBar
                          getProgressLabel={(value) => `${value}% used`}
                          showLabel={true}
                          size={SIZE.large}
                          value={progressBarValue(
                            assignment.remainingQuantity,
                            assignment.initialQuantity,
                            false,
                          )}
                        />
                      </StyledBody>
                    </If>
                  </Card>
                ))}
              </React.Fragment>
            );
          })
          .flat()}
      </If>
    </Column>
  );
};
