import { BaseProvider, createTheme, Theme } from "baseui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export type CustomTheme = Theme & {
  colors: {
    lp: {
      backgroundPattern: string;
      primaryButton: string;
      primaryButtonHover: string;
      purchaseFormLineDetail: string;
    };
  };
  custom: {
    backgroundColor: string;
    footerColor: string;
    foregroundColor: string;
    headerColor: string;
  };
};

const theme = createTheme({
  breakpoints: {
    large: 1244,
    medium: 804,
    small: 570,
  },
});

const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const defaultCustomTheme = i18n.getResourceBundle("base", "translation");
  const saltCustomTheme = i18n.getResourceBundle(i18n.language, "translation");

  const customTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      ...(defaultCustomTheme.theme.colors || {}),
      ...(saltCustomTheme?.theme?.colors || {}),
    },
    custom: {
      ...(defaultCustomTheme.theme.custom || {}),
      ...(saltCustomTheme?.theme?.custom || {}),
    },
  };

  React.useEffect(() => {
    if (window) {
      const { body } = window.document;
      body.style.backgroundColor = (
        customTheme as CustomTheme
      ).custom.backgroundColor;
    }
  }, []);

  return <BaseProvider theme={customTheme}>{children}</BaseProvider>;
};

export default ThemeProvider;
