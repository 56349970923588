import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledDivider } from "baseui/divider";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { HeadingMedium, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Column } from "../../components/containers";
import { FaqSection } from "../../components/landing-page/faq-section";
import MasterPage from "../../components/master-page";
import { FontFamily, USE_FONT } from "../../components/overrides";
import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import email from "./assets/email.png";
import esim from "./assets/esim.png";
import gg from "./assets/gg.svg";
import question from "./assets/question.png";
import { STYLES } from "./styles";

const ThankYouPage = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { width: screenWidth } = useScreenSize();

  const items = [
    {
      caption: translate("typ.items.first.caption"),
      icon: email,
      title: translate("typ.items.first.title"),
    },
    {
      caption: translate("typ.items.second.caption"),
      icon: esim,
      title: translate("typ.items.second.title"),
    },
    {
      caption: translate("typ.items.third.caption"),
      icon: question,
      title: translate("typ.items.third.title"),
    },
  ];

  const purchase = React.useMemo(() => {
    const purchaseResponseJson = localStorage.getItem("purchase-data");
    if (!purchaseResponseJson) {
      return undefined;
    }
    const purchaseResponse = JSON.parse(purchaseResponseJson as string);
    if (moment() > purchaseResponse.expiresAt) {
      return undefined;
    }
    return purchaseResponse.purchase;
  }, []);

  React.useEffect(() => {
    if (!purchase) {
      navigate("/");
    }
  }, [purchase]);

  if (!purchase) {
    return <></>;
  }

  return (
    <MasterPage>
      <Block className={css(STYLES.container(theme))}>
        <Column className={css(STYLES.baseSection(theme))}>
          <img
            height={"96px"}
            src={gg}
            style={{ marginBottom: theme.sizing.scale900 }}
            width={"96px"}
          />
          <HeadingMedium
            color={"#3A4155"}
            style={{
              ...USE_FONT(FontFamily.SatoshiVariable, 700, "40px", "32px"),
              marginBottom: theme.sizing.scale300,
              textAlign: "center",
            }}
          >
            {translate("typ.headings.title")}
          </HeadingMedium>
          <ParagraphMedium
            style={{
              ...USE_FONT(FontFamily.DMSans, 500, "24px", "16px"),
              marginBottom: theme.sizing.scale300,
              maxWidth: "700px",
              textAlign: "center",
            }}
          >
            {translate("typ.caption")}
          </ParagraphMedium>
          <StyledDivider
            $size={"section"}
            style={{
              marginBottom: theme.sizing.scale1200,
              marginTop: theme.sizing.scale1200,
              width: "100%",
            }}
          />
          <Block className={css(STYLES.orderSection)}>
            <ParagraphMedium
              color={"#3A4155"}
              style={{
                ...USE_FONT(FontFamily.SatoshiVariable, 700, "32px", "28px"),
                marginBottom: theme.sizing.scale900,
                textAlign: "center",
                width: "100%",
              }}
            >
              {translate("typ.headings.order")}
            </ParagraphMedium>
            <TableBuilder
              data={[purchase?.customer]}
              divider="grid"
              overrides={{
                Root: {
                  style: {
                    marginBottom: theme.sizing.scale600,
                  },
                },
                Table: {
                  style: {
                    minWidth: "100%",
                  },
                },
              }}
            >
              <TableBuilderColumn header={translate("typ.tables.customer")}>
                {() => (
                  <ParagraphMedium margin={0}>
                    {purchase?.customer?.name}
                  </ParagraphMedium>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={translate("typ.tables.email")}>
                {() => (
                  <ParagraphMedium margin={0}>
                    {purchase?.customer?.email}
                  </ParagraphMedium>
                )}
              </TableBuilderColumn>
            </TableBuilder>

            <TableBuilder
              data={[purchase.autoTopUp]}
              divider="grid"
              overrides={{
                Root: {
                  style: {
                    marginBottom: theme.sizing.scale600,
                  },
                },
                Table: {
                  style: {
                    minWidth: "100%",
                  },
                },
              }}
            >
              <TableBuilderColumn header={translate("typ.tables.autoTopUp")}>
                {(hasAutoTopUp) =>
                  hasAutoTopUp ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: translate("typ.tables.autoTopUpStatus", {
                          status: "<strong>enabled</strong>",
                        }),
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: translate("typ.tables.autoTopUpStatus", {
                          status: "<strong>disabled</strong>",
                        }),
                      }}
                    />
                  )
                }
              </TableBuilderColumn>
            </TableBuilder>

            <TableBuilder
              data={purchase.orderIds}
              divider="grid"
              overrides={{
                Table: {
                  style: {
                    minWidth: "100%",
                  },
                },
              }}
            >
              <TableBuilderColumn header={translate("typ.tables.order")}>
                {(orderId: string) => (
                  <Button
                    onClick={() => {
                      navigate(`/access/${orderId}/resend`);
                    }}
                    size="mini"
                  >
                    {orderId}
                  </Button>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={translate("typ.tables.bundle")}>
                {() => (
                  <ParagraphMedium margin={0}>
                    {purchase?.bundle?.description}
                  </ParagraphMedium>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header={translate("typ.tables.price")}>
                {() => (
                  <ParagraphMedium margin={0}>
                    {`$${parseFloat(purchase?.bundle?.price).toFixed(2)}`}
                  </ParagraphMedium>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          </Block>
        </Column>
        <Column className={css(STYLES.itemsContainerWrapper)}>
          <Column className={css(STYLES.itemsContainer(theme))}>
            <ParagraphMedium
              color={"#3A4155"}
              style={{
                ...USE_FONT(FontFamily.SatoshiVariable, 700, "32px", "28px"),
                marginBottom: theme.sizing.scale600,
                textAlign: "center",
                width: "100%",
              }}
            >
              {translate("typ.headings.next")}
            </ParagraphMedium>
            <Block
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: screenWidth > 1244 ? "row" : "column",
                marginTop: theme.sizing.scale600,
              }}
            >
              {items.map((item, index) => (
                <Column
                  className={css(STYLES.itemsCard(theme))}
                  key={`typ-item-${index}`}
                >
                  <img
                    height={104}
                    src={item.icon}
                    style={{ marginBottom: theme.sizing.scale600 }}
                    width={112}
                  />
                  <HeadingMedium
                    color={"#3A4155"}
                    style={{
                      ...USE_FONT(
                        FontFamily.SatoshiVariable,
                        700,
                        "28px",
                        "20px",
                      ),
                      marginBottom: theme.sizing.scale300,
                      textAlign: "center",
                    }}
                  >
                    {item.title}
                  </HeadingMedium>
                  <ParagraphMedium
                    style={{
                      ...USE_FONT(FontFamily.DMSans, 500, "20px", "14px"),
                      textAlign: "center",
                    }}
                  >
                    {item.caption}
                  </ParagraphMedium>
                </Column>
              ))}
            </Block>
          </Column>
        </Column>
        <FaqSection showAccordion={false} />
      </Block>
    </MasterPage>
  );
};
export default ThankYouPage;
