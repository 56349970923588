/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as i18next } from "i18next";
import { initReactI18next } from "react-i18next";

import { locales } from "../locales";

// check if every locale has exactly the same keys
const validateLocales = () => {
  const toEqualKeys = (
    obj1: { [x: string]: any },
    obj2: { [x: string]: any },
  ): boolean => {
    return Object.keys(obj1).every((k) => {
      const result =
        k in obj2 &&
        (typeof obj1[k] != "object" ||
          (typeof obj2[k] == "object" && toEqualKeys(obj1[k], obj2[k])));
      if (!result) {
        console.log(`the key ${k} is a mismatch`);
      }
      return result;
    });
  };

  for (const key of Object.keys(locales)) {
    if (key !== "base") {
      // @ts-expect-error keys matcher
      const rightSideCheck = toEqualKeys(locales["base"], locales[key]);
      if (!rightSideCheck) {
        throw `Key mismatch on locale "${key}" compared to "base"`;
      }
      // @ts-expect-error keys matcher
      const leftSideCheck = toEqualKeys(locales[key], locales["base"]);
      if (!leftSideCheck) {
        throw `Key mismatch on locale "base" compared to "${key}"`;
      }
    }
  }
  console.log("Locales validation complete");
};

if (process.env.NODE_ENV !== "production") {
  validateLocales();
}

// @ts-expect-error some navigators don't support userLanguage
const userLang = (navigator?.language || navigator?.userLanguage)?.split(
  "-",
)[0];
let fallback = "base";
if (userLang && Object.keys(locales).includes(userLang)) {
  fallback = userLang;
}

const isLocalHost = window.location.origin.includes("localhost");
const query = new URLSearchParams(window.location.search);
let currentByDomain =
  (isLocalHost && query.get("t")) || window.location.host.split(".")[0];
if (!Object.keys(locales).includes(currentByDomain)) {
  console.log(`translation file "${currentByDomain}" not found using base`);
  currentByDomain = fallback;
}

i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: fallback,
  interpolation: {
    escapeValue: false,
  },
  lng: currentByDomain,
  resources: {
    ...locales,
  },
});
