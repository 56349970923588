import axios from "axios";
import Cookies from "universal-cookie";

import {
  AuthTokenRequestT,
  BundleRequestT,
  EnableAutoTopUpRequestT,
  FirebaseMessagingTokenRequestT,
  GetPartialEmailRequestT,
  OptOupConfirmRequestT,
  OrderEsimDetailsRequestT,
  OrderPinNotificationRequestT,
  PurchaseRequestT,
  PurchaseTopUpRequestT,
} from "./types";

const getBaseURL = () => {
  if (window.location.host === "localhost:3000") {
    return "http://localhost:3005";
  }
  if (window.location.host.includes("ngrok-free.app")) {
    return "http://localhost:3005";
  }
  return "https://api.stayconnectedsim.com";
};

const getAuthHeaders = () => {
  const headers: { authorization?: string } = {};
  const cookies = new Cookies();
  const accessToken = cookies.get("_sc_auth");
  if (accessToken) {
    headers.authorization = `bearer ${accessToken}`;
  }
  return headers;
};

const purchase = async ({ body }: { body?: PurchaseRequestT }) => {
  return (await axios.post(`${getBaseURL()}/purchase`, body)).data;
};

const bundles = async ({ body }: { body?: BundleRequestT }) => {
  return (await axios.post(`${getBaseURL()}/bundles`, body)).data;
};

const terms = async () => {
  return (await axios.get(`${getBaseURL()}/terms`)).data;
};

const storeFirebaseMessagingToken = async ({
  body,
}: {
  body?: FirebaseMessagingTokenRequestT;
}) => {
  return (
    await axios.post(`${getBaseURL()}/store-firebase-messaging-token`, body, {
      headers: getAuthHeaders(),
    })
  ).data;
};

const getAuthPin = async ({
  body,
}: {
  body?: OrderPinNotificationRequestT;
}) => {
  return (await axios.post(`${getBaseURL()}/auth-pin`, body)).data;
};

const getPartialEmail = async ({
  body,
}: {
  body?: GetPartialEmailRequestT;
}) => {
  return (await axios.post(`${getBaseURL()}/get-partial-email`, body)).data;
};

const getAuthToken = async ({ body }: { body?: AuthTokenRequestT }) => {
  return (await axios.post(`${getBaseURL()}/auth-token`, body)).data;
};

const purchaseHistory = async () => {
  return (
    await axios.get(`${getBaseURL()}/purchase-history`, {
      headers: getAuthHeaders(),
    })
  ).data;
};

const orderEsimDetails = async ({
  body,
}: {
  body?: OrderEsimDetailsRequestT;
}) => {
  return (
    await axios.post(`${getBaseURL()}/order-esim-details`, body, {
      headers: getAuthHeaders(),
    })
  ).data;
};

const optOutConfirm = async ({ body }: { body?: OptOupConfirmRequestT }) => {
  return (
    await axios.post(`${getBaseURL()}/opt-out-auto-top-up`, body, {
      headers: getAuthHeaders(),
    })
  ).data;
};

const enableAutoTopUp = async ({
  body,
}: {
  body?: EnableAutoTopUpRequestT;
}) => {
  return (
    await axios.post(`${getBaseURL()}/enable-auto-top-up`, body, {
      headers: getAuthHeaders(),
    })
  ).data;
};

const purchaseTopUp = async ({ body }: { body?: PurchaseTopUpRequestT }) => {
  return (
    await axios.post(`${getBaseURL()}/purchase/top-up`, body, {
      headers: getAuthHeaders(),
    })
  ).data;
};

export const API = {
  bundles,
  enableAutoTopUp,
  getAuthPin,
  getAuthToken,
  getPartialEmail,
  optOutConfirm,
  orderEsimDetails,
  purchase,
  purchaseHistory,
  purchaseTopUp,
  storeFirebaseMessagingToken,
  terms,
};
