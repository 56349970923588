import base from "./base";

// used to query the API
// injected into translations
// used to query lp banner image at:
// public/images/{{country.name}}/banner.jpg
// public/images/{{country.name}}/banner@2x.jpg
const country = {
  country: {
    isos: {
      country: "CL",
      network: "CL",
    },
    isRegional: false,
    name: "Chile",
    region: "LATAM",
  },
};

// used to personalize theme colors
const theme = {
  theme: {
    colors: {
      logo: {
        country: "#0f69b4",
        frame: "#3A4155",
        primary: "#0f69b4",
        secondary: "#eb3c46",
      },
      lp: {
        backgroundPattern:
          "linear-gradient(121deg, rgb(15, 105, 180) 40%, #F44336 95%)",
        benefitsIconDetail: "#ed565f",
        primaryButton: "#eb3c46",
        primaryButtonHover: "#c93039",
        purchaseFormLineDetail: "#ed565f",
      },
    },
    custom: {
      backgroundColor: "#F3F3F3",
      banner: {
        backgroundPosition: "60% 0%",
        backgroundPositionLarge: "60% 0%",
        backgroundPositionMedium: "60% 0%",
        useBannerAsPattern: {
          backgroundPosition: "center",
          isEnabled: "true",
          opacity: "0.5",
        },
      },
      footerColor: "#444444",
      foregroundColor: "#F3F3F3",
      headerColor: "#FFF",
    },
  },
};

export default {
  ...base,
  ...country,
  ...theme,
};
