import { LabelMedium } from "baseui/typography";
import React from "react";
import { exportComponentAsPNG } from "react-component-export-image";

import { useTranslation } from "../../hooks/translate";
import { Column, Row } from "../containers";
import If from "../if";
import { FontFamily, USE_FONT } from "../overrides";

type PropsT = {
  clickToDownload?: boolean;
  countryNameColor?: string;
  frameColor?: string;
  fullWidth: boolean;
  omitCountry?: boolean;
  onClick?: () => void;
  primaryColor?: string;
  secondaryColor?: string;
  size: number;
};

export const LogoSvg = ({
  frameColor,
  height,
  primaryColor,
  secondaryColor,
  width,
}: {
  frameColor: string;
  height: number;
  primaryColor: string;
  secondaryColor: string;
  width: number;
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 385 411"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M228 241.75C231.311 251.517 233 261.761 233 272.074V272.074C233 261.761 231.311 251.517 228 241.75V241.75ZM227.798 241.154V241.154C224.617 231.77 220.042 222.892 214.253 214.851V214.851C220.047 222.899 224.612 231.763 227.798 241.154ZM213.191 213.377V213.377C207.757 205.829 201.28 199.067 193.977 193.309V193.309C201.284 199.072 207.751 205.826 213.191 213.377ZM190.119 190.267V190.267C182.744 184.452 174.549 179.705 165.838 176.193V176.193V176.193C154.056 171.442 141.47 169 128.766 169V169C141.47 169 154.056 171.442 165.838 176.193C174.559 179.709 182.734 184.448 190.119 190.267Z"
        fill={secondaryColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M155.421 270.248C155.805 272.075 156 273.941 156 275.807V275.807C156 273.941 155.805 272.075 155.421 270.248V270.248ZM149.52 258.273C152.459 261.703 154.49 265.828 155.421 270.248V270.248C154.49 265.828 152.459 261.703 149.52 258.273V258.273ZM146.197 255.015C147.397 256.003 148.509 257.093 149.52 258.273V258.273C148.509 257.093 147.397 256.003 146.197 255.015V255.015ZM142.935 252.329V252.329C140.66 250.455 138.1 248.955 135.352 247.887V247.887V247.887C132.145 246.64 128.735 246 125.294 246V246C128.735 246 132.145 246.64 135.352 247.887C138.1 248.955 140.66 250.455 142.935 252.329Z"
        fill={secondaryColor}
        fillRule="evenodd"
      />
      <path
        d="M233 299.5H283.5V271.6C283.5 259.577 282.055 247.598 279.195 235.92L277.5 229L268 205L263.152 196.459C259.39 189.83 255.108 183.51 250.346 177.558C245.789 171.861 240.805 166.518 235.439 161.575L224.5 151.5L215.646 145.143C208.567 140.061 200.984 135.724 193.015 132.199C183.706 128.081 173.934 125.101 163.911 123.323L161 122.807C152.35 121.272 143.582 120.5 134.797 120.5H106.5V169H128.766V169C141.47 169 154.056 171.442 165.838 176.193V176.193V176.193C174.549 179.705 182.744 184.452 190.119 190.267V190.267L190.144 190.287L193.97 193.303L193.977 193.309V193.309C201.28 199.067 207.757 205.829 213.191 213.377V213.377L213.208 213.399L214.253 214.851V214.851C220.042 222.892 224.617 231.77 227.798 241.154V241.154L227.806 241.178L228 241.75V241.75C231.311 251.517 233 261.761 233 272.074V272.074V299.5Z"
        fill={primaryColor}
      />
      <path
        d="M353 111L316 147L333.5 164.5V360H231V411H354.668C363.454 411 371.809 407.19 377.57 400.556C382.361 395.038 385 387.976 385 380.668V144.5L353 111Z"
        fill={frameColor}
      />
      <path
        d="M156 299.5H208.5V272.049C208.5 261.817 206.171 251.72 201.69 242.521C199.9 238.848 197.781 235.345 195.357 232.056L191.097 226.275C187.053 220.786 182.251 215.898 176.835 211.756C170.98 207.278 164.473 203.724 157.541 201.217L157.006 201.024C148.732 198.031 140 196.5 131.201 196.5H103V246H125.294V246C128.735 246 132.145 246.64 135.352 247.887V247.887V247.887C138.1 248.955 140.66 250.455 142.935 252.329V252.329L146.197 255.015V255.015C147.397 256.003 148.509 257.093 149.52 258.273V258.273V258.273C152.459 261.703 154.49 265.828 155.421 270.248V270.248V270.248C155.805 272.075 156 273.941 156 275.807V275.807V299.5Z"
        fill={secondaryColor}
      />
      <path
        d="M300 58.5L237.5 0H31.1849C22.1726 0 13.6009 3.89895 7.67858 10.6922C2.7276 16.3713 0 23.6507 0 31.1849V111H51V52.5H221L266 94L300 58.5Z"
        fill={frameColor}
      />
      <path
        d="M166 408.5V360H51V171.075H0V378.991C0 387.332 3.25633 395.344 9.0756 401.321C15.1012 407.509 23.372 411 32.0094 411L166 408.5Z"
        fill={frameColor}
      />
    </svg>
  );
};

export const Logo = ({
  clickToDownload,
  countryNameColor,
  frameColor,
  fullWidth,
  omitCountry,
  onClick,
  primaryColor,
  secondaryColor,
  size,
}: PropsT): React.ReactNode => {
  const { translate } = useTranslation();
  const ref = React.useRef(null);

  const height = size;
  const width = height * 0.95;
  const fontSize = size * 0.4;
  const countryFontSize = size * 0.35;
  const gap = size * 0.2;
  const countryName = translate("country.name");
  const colors = {
    country: countryNameColor || translate("theme.colors.logo.country"),
    frame: frameColor || translate("theme.colors.logo.frame"),
    primary: primaryColor || translate("theme.colors.logo.primary"),
    secondary: secondaryColor || translate("theme.colors.logo.secondary"),
  };

  const onClickDownload = React.useCallback(() => {
    if (ref.current) {
      exportComponentAsPNG(ref, {
        html2CanvasOptions: { backgroundColor: null },
      });
    }
  }, [ref.current]);

  return (
    <Row
      onClick={
        clickToDownload
          ? onClickDownload
          : onClick
            ? () => onClick()
            : undefined
      }
      ref={ref}
      style={{
        alignItems: omitCountry ? "end" : "initial",
        cursor: onClick || clickToDownload ? "pointer" : "default",
      }}
    >
      <LogoSvg
        frameColor={colors.frame}
        height={height}
        primaryColor={colors.primary}
        secondaryColor={colors.secondary}
        width={width}
      />
      <If condition={fullWidth}>
        <Column style={{ marginLeft: `${gap}px` }}>
          <LabelMedium
            color={colors.frame}
            style={USE_FONT(
              FontFamily.SatoshiVariable,
              700,
              `${fontSize}px`,
              `${fontSize}px`,
            )}
          >
            Stay
          </LabelMedium>
          <LabelMedium
            color={colors.frame}
            style={USE_FONT(
              FontFamily.SatoshiVariable,
              700,
              `${fontSize}px`,
              `${fontSize}px`,
            )}
          >
            Connected
          </LabelMedium>
          <If condition={!omitCountry}>
            <LabelMedium
              color={colors.country}
              style={{
                ...USE_FONT(
                  FontFamily.SatoshiVariable,
                  700,
                  `${countryFontSize}px`,
                  `${countryFontSize}px`,
                ),
                textAlign: "right",
                width: "100%",
              }}
            >
              {countryName}
            </LabelMedium>
          </If>
        </Column>
      </If>
    </Row>
  );
};
