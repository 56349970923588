import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  container: (theme: Theme) =>
    ({
      backgroundColor: (theme as CustomTheme).custom.headerColor,
      borderBottomColor: theme.colors.gray100,
      height: "47px",
      margin: "0 auto",
      maxWidth: "1920px",
    }) as const,
  innerContainer: {
    display: "flex",
    margin: "0 auto",
    maxWidth: "1366px",
    width: `calc(100%)`,
  },
  links: {
    cursor: "pointer",
  },
};
