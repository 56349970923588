import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import pattern from "./assets/pattern.png";
import pattern2x from "./assets/pattern@2x.png";
import { STYLES } from "./styles";

export const Pattern = ({
  useBanner,
}: {
  useBanner: boolean;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();
  const isUsingBanner = useBanner && screenWidth <= theme.breakpoints.medium;
  const banner = `${window.location.origin}/images/${translate("country.name").toLocaleLowerCase()}/banner.jpg`;
  const banner2x = `${window.location.origin}/images/${translate("country.name").toLocaleLowerCase()}/banner@2x.jpg`;
  const bannerBackgroundPosition = translate(
    "theme.custom.banner.useBannerAsPattern.backgroundPosition",
  );
  const gradientBannerOpacity = translate(
    "theme.custom.banner.useBannerAsPattern.opacity",
  );

  return (
    <React.Fragment>
      <Block
        className={css(
          STYLES.imageBlock(
            isUsingBanner,
            banner,
            banner2x,
            bannerBackgroundPosition,
            pattern,
            pattern2x,
          ),
        )}
      />
      <Block
        className={css(
          STYLES.gradientBlock(theme, isUsingBanner, gradientBannerOpacity),
        )}
      />
    </React.Fragment>
  );
};
