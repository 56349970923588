import { Theme } from "baseui";

export const STYLES = {
  container: {
    alignItems: "left",
    background: "#FFF",
    maxHeight: "max(60vh, calc(100vh - 350px))",
    overflowY: "scroll",
    overscrollBehavior: "contain",
  } as const,
  textWrapper: (theme: Theme) => ({
    marginBottom: theme.sizing.scale600,
  }),
  wrapper: {
    maxWidth: "700px",
  },
};
