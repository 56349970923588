import { AxiosError } from "axios";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { PinCode } from "baseui/pin-code";
import { useStyletron } from "baseui/styles";
import { toaster } from "baseui/toast";
import {
  HeadingMedium,
  LabelSmall,
  ParagraphMedium,
  ParagraphXSmall,
} from "baseui/typography";
import * as React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { API } from "../../api";
import { ValidationError } from "../../api/types";
import { Column } from "../../components/containers";
import If from "../../components/if";
import MasterPage from "../../components/master-page";
import { FontFamily, USE_FONT } from "../../components/overrides";
import { useTranslation } from "../../hooks/translate";
import { ApiContext } from "../../providers/api-provider";
import login from "./assets/login.png";

const OrderAccessPage = (): React.ReactNode => {
  const cookies = new Cookies();
  const [, theme] = useStyletron();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const routeParams = useParams();
  const apiContext = React.useContext(ApiContext);
  const [orderId, setOrderId] = React.useState<string>(routeParams.id || "");
  const [isLoading, setIsLoading] = React.useState(false);
  const [partialEmail, setPartialEmail] = React.useState("");
  const [pinCode, setPinCode] = React.useState(Array(6).fill(""));
  const cardState = routeParams.id ? 2 : 1;
  const shouldRedirect = Boolean(cookies.get("_sc_auth"));

  React.useEffect(() => {
    if (routeParams.id && location.pathname.includes("/resend")) {
      manageOrder(true);
      navigate(`/access/${routeParams.id}`, { replace: true });
    }
  }, [routeParams.id]);

  const getCookieConfiguration = React.useCallback(
    (
      expiresAt: Date,
    ): {
      expires: Date;
      path: string;
      sameSite: "strict";
      secure: boolean;
    } => {
      const secure = window.location.protocol === "https:";
      return {
        expires: expiresAt || new Date(+new Date() + 3600 * 1000),
        path: "/",
        sameSite: "strict",
        secure,
      };
    },
    [],
  );

  const getPartialEmail = React.useCallback(async () => {
    try {
      const result = await API.getPartialEmail({
        body: { order_id: orderId },
      });
      if (result.status) {
        setPartialEmail(result.partialEmail);
      }
    } catch (error) {
      console.error(error);
    }
  }, [orderId]);

  const manageOrder = React.useCallback(
    async (fromResend: boolean) => {
      try {
        if (cardState === 1 || fromResend) {
          setIsLoading(true);
          const result = await API.getAuthPin({
            body: { order_id: orderId, origin: window.location.hostname },
          });
          if (result.status) {
            navigate(`/access/${orderId}`);
          } else {
            toaster.warning("Order not found");
          }
          setIsLoading(false);
        } else if (cardState === 2) {
          setIsLoading(true);
          const authResponse = await API.getAuthToken({
            body: {
              order_id: orderId,
              origin: window.location.hostname,
              pin_code: pinCode.join(""),
            },
          });
          if (!authResponse) {
            toaster.negative("Invalid Credentials");
          } else {
            if (apiContext.storeFirebaseMessagingToken) {
              await apiContext.storeFirebaseMessagingToken({
                customerId: authResponse?.customerId,
              });
            }
            cookies.set(
              "_sc_auth",
              authResponse.token,
              getCookieConfiguration(new Date(authResponse.expiresAt)),
            );
            navigate(`/order/${orderId}`);
          }
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error &&
          ((error as AxiosError).response?.data as ValidationError)
            ?.isValidation
        ) {
          toaster.negative(
            ((error as AxiosError).response?.data as ValidationError)?.message,
          );
        }
        setIsLoading(false);
        console.error(error);
      }
    },
    [orderId, pinCode, cardState, apiContext],
  );

  React.useEffect(() => {
    if (routeParams.id) {
      getPartialEmail();
    }
  }, [routeParams.id]);

  if (shouldRedirect) {
    return <Navigate to="/order" />;
  }

  return (
    <MasterPage>
      <Block
        style={{
          background: "white",
          maxWidth: "1920px",
          minHeight: "60vh",
          padding: "32px",
          width: "calc(100% - 64px)",
        }}
      >
        <Column
          style={{
            margin: "0 auto",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <If condition={cardState === 1}>
            <Column style={{ alignItems: "center" }}>
              <img height={152} src={login} width={240} />
              <HeadingMedium
                color={"#3A4155"}
                style={{
                  ...USE_FONT(FontFamily.SatoshiVariable, 700, "40px", "32px"),
                  marginBottom: theme.sizing.scale300,
                  marginTop: theme.sizing.scale900,
                  textAlign: "center",
                }}
              >
                {translate("access.title")}
              </HeadingMedium>
              <ParagraphMedium
                style={{
                  ...USE_FONT(FontFamily.DMSans, 500, "24px", "16px"),
                  marginBottom: theme.sizing.scale900,
                  maxWidth: "700px",
                  textAlign: "center",
                }}
              >
                {translate("access.caption")}
              </ParagraphMedium>
              <FormControl label={() => translate("access.form.order")}>
                <Input
                  maxLength={12}
                  onChange={(e) => setOrderId(e.target.value.toUpperCase())}
                  value={orderId}
                />
              </FormControl>
            </Column>
          </If>
          <If condition={cardState === 2}>
            <Column style={{ alignItems: "center" }}>
              <img height={152} src={login} width={240} />
              <FormControl
                caption={
                  <Column>
                    <LabelSmall margin={0} style={{ textAlign: "center" }}>
                      {translate("access.confirmation.caption")}
                    </LabelSmall>
                    <ParagraphXSmall margin={0} style={{ textAlign: "center" }}>
                      {translate("access.confirmation.hintLeft")}
                      <strong>{` ${partialEmail} `}</strong>
                      {translate("access.confirmation.hintRight")}
                    </ParagraphXSmall>
                  </Column>
                }
                label={
                  <LabelSmall
                    color={"#3A4155"}
                    margin={0}
                    style={{
                      ...USE_FONT(
                        FontFamily.SatoshiVariable,
                        700,
                        "24px",
                        "20px",
                      ),
                      marginTop: theme.sizing.scale600,
                      textAlign: "center",
                    }}
                  >
                    {translate("access.confirmation.title")}
                  </LabelSmall>
                }
              >
                <Column
                  style={{
                    alignItems: "center",
                    margin: "16px 0px",
                    width: "100%",
                  }}
                >
                  <PinCode
                    onChange={({ values }) => {
                      setPinCode(values);
                    }}
                    values={pinCode}
                  />
                </Column>
              </FormControl>
            </Column>
          </If>
          <Column>
            <Button
              isLoading={isLoading}
              onClick={() => manageOrder(false)}
              style={{ marginBottom: "16px" }}
            >
              {cardState === 1
                ? translate("access.buttons.enter")
                : translate("access.buttons.confirm")}
            </Button>
            <Button
              kind="secondary"
              onClick={() =>
                cardState === 1 ? navigate("/") : navigate("/access")
              }
            >
              {translate("access.buttons.cancel")}
            </Button>
          </Column>
        </Column>
      </Block>
    </MasterPage>
  );
};
export default OrderAccessPage;
