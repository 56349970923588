import { AxiosError } from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { FILL, Tab, Tabs } from "baseui/tabs-motion";
import { toaster } from "baseui/toast";
import { LabelSmall, ParagraphXSmall } from "baseui/typography";
import * as React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { API } from "../../api";
import {
  EsimDetailsResponseT,
  PurchaseHistoryResponseT,
} from "../../api/types";
import { AutoTopUpCard } from "../../components/auto-top-up-card";
import { Column } from "../../components/containers";
import { CustomerCard } from "../../components/customer-card";
import EmptyState from "../../components/empty-state";
import { EsimCard } from "../../components/esim-card";
import If from "../../components/if";
import MasterPage from "../../components/master-page";
import { OptOutCard } from "../../components/opt-out-card";
import { PaymentsCard } from "../../components/payments-card";
import { TopUpCard } from "../../components/top-up-card";
import { useTranslation } from "../../hooks/translate";
import { useScreenSize } from "../../hooks/use-screen-size";
import { ApiContext } from "../../providers/api-provider";

const ManageOrderPage = (): React.ReactNode => {
  const cookies = new Cookies();
  const shouldRedirect = !cookies.get("_sc_auth");
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const routeParams = useParams();
  const apiContext = React.useContext(ApiContext);
  const orderId = routeParams.id as string;

  const [css, theme] = useStyletron();
  const { width: screenWidth } = useScreenSize();
  const [activeTabKey, setActiveTabKey] = React.useState<React.Key>("0");
  const [purchaseNotFound, setPurchaseNotFound] = React.useState(false);
  const [esimNotFound, setEsimNotFound] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingEsim, setIsLoadingEsim] = React.useState(false);
  const [esimDetails, setEsimDetails] = React.useState<
    EsimDetailsResponseT | undefined
  >(undefined);
  const [purchaseDetails, setPurchaseDetails] = React.useState<
    PurchaseHistoryResponseT | undefined
  >(undefined);

  const fetchPurchaseDetails = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedPurchaseHistory = await API.purchaseHistory();
      if (!fetchedPurchaseHistory) {
        setPurchaseNotFound(true);
      } else {
        setPurchaseDetails(fetchedPurchaseHistory);
        setIsLoading(false);

        if (apiContext.storeFirebaseMessagingToken) {
          await apiContext.storeFirebaseMessagingToken({
            customerId: fetchedPurchaseHistory.customer.id,
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      if ((error && (error as AxiosError)?.response?.status) === 401) {
        logout(true, "/access");
      } else {
        console.error(error);
      }
    }
  }, [orderId]);

  const fetchEsimDetails = React.useCallback(async () => {
    try {
      setIsLoadingEsim(true);
      setEsimDetails(undefined);
      const fetchedEsimDetails = await API.orderEsimDetails({
        body: {
          order_id: orderId,
        },
      });
      if (!fetchedEsimDetails.status) {
        setEsimNotFound(true);
      } else {
        setEsimDetails(fetchedEsimDetails);
        setIsLoadingEsim(false);
      }
    } catch (error) {
      setIsLoadingEsim(false);
      if ((error && (error as AxiosError)?.response?.status) === 401) {
        logout(true, "/access");
      } else {
        console.error(error);
      }
    }
  }, [orderId]);

  const logout = React.useCallback(
    async (sessionExpired: boolean, redirectTo: string) => {
      if (cookies.get("_sc_auth")) {
        if (sessionExpired) {
          toaster.warning("Your session has expired, please enter again.");
        }
        cookies.remove("_sc_auth", { path: "/" });
        navigate(redirectTo);
        window.scrollTo(0, 0);
      }
    },
    [],
  );

  const isDeclined = React.useMemo(() => {
    if (!purchaseDetails) {
      return false;
    }
    return (
      purchaseDetails.customer.payments.filter(
        (payment) =>
          payment.type === "PURCHASE" && payment.status === "DECLINED",
      ).length > 0
    );
  }, [orderId, purchaseDetails]);

  const currentOrder = React.useMemo(() => {
    if (!purchaseDetails) {
      return undefined;
    }
    return (purchaseDetails as PurchaseHistoryResponseT).orders.find(
      (order: { id: string }) => order.id === orderId,
    );
  }, [orderId, purchaseDetails]);

  React.useEffect(() => {
    fetchPurchaseDetails();
  }, []);

  React.useEffect(() => {
    if (currentOrder?.id) {
      fetchEsimDetails();
    }
  }, [currentOrder?.id]);

  React.useEffect(() => {
    if (purchaseNotFound) {
      toaster.negative("Purchase not found");
      navigate("/access");
    }
  }, [purchaseNotFound]);

  if (shouldRedirect) {
    return <Navigate to="/access" />;
  }

  return (
    <MasterPage>
      <Block
        className={css({
          background: "white",
          maxWidth: "1920px",
          minHeight: "60vh",
          padding: screenWidth > theme.breakpoints.small ? "32px" : "8px",
          width:
            screenWidth > theme.breakpoints.small
              ? "calc(100% - 64px)"
              : "calc(100% - 16px)",
        })}
      >
        <Column
          className={css({
            margin: "0 auto",
            maxWidth: "600px",
            width: "100%",
          })}
        >
          <If condition={isDeclined}>
            <Column>
              <Block className={css({ marginBottom: "24px" })}>
                <EmptyState
                  isEmpty={isDeclined}
                  subtitle={translate("manage.declined.caption")}
                  title={translate("manage.declined.title")}
                />
              </Block>
              <Block className={css({ marginBottom: "24px" })}>
                <PaymentsCard
                  isParentLoading={isLoading}
                  purchaseDetails={purchaseDetails}
                />
              </Block>
              <Button kind="primary" onClick={() => logout(false, "/")}>
                {translate("manage.buttons.logout")}
              </Button>
            </Column>
          </If>
          <If condition={!isDeclined}>
            <Block className={css({ marginBottom: "16px" })}>
              <CustomerCard
                isDeclined={isDeclined}
                isParentLoading={isLoading}
                logout={logout}
                orderId={orderId}
                purchaseDetails={purchaseDetails}
              />
            </Block>
            <Tabs
              activateOnFocus
              activeKey={activeTabKey}
              fill={FILL.fixed}
              onChange={({ activeKey }) => {
                setActiveTabKey(activeKey);
              }}
            >
              <Tab
                title={
                  <Column>
                    <LabelSmall margin={0}>
                      {translate("manage.tabs.first")}
                    </LabelSmall>
                    <ParagraphXSmall margin={0}>{orderId}</ParagraphXSmall>
                  </Column>
                }
              >
                {/* TODO: translate */}
                <EmptyState
                  isEmpty={!orderId || esimNotFound}
                  subtitle={
                    !orderId
                      ? "Select an order to see esim details"
                      : "Your esim will be issued after payment confirmation"
                  }
                  title={!orderId ? "No order selected" : "There's no data yet"}
                >
                  <EsimCard
                    currentOrder={currentOrder}
                    esimDetails={esimDetails}
                    isParentLoading={isLoading || isLoadingEsim}
                    orderId={orderId}
                  />
                </EmptyState>
              </Tab>
              <Tab title={translate("manage.tabs.second")}>
                <PaymentsCard
                  isParentLoading={isLoading}
                  purchaseDetails={purchaseDetails}
                />
              </Tab>
              <Tab title={translate("manage.tabs.third")}>
                <Column>
                  <Block className={css({ marginBottom: "24px" })}>
                    {/* TODO: translate */}
                    <EmptyState
                      isEmpty={!orderId}
                      subtitle="Select an order to see the top-up settings"
                      title={"No order selected"}
                    >
                      <Block className={css({ marginBottom: "24px" })}>
                        <TopUpCard
                          customerName={purchaseDetails?.customer.name || ""}
                          isAutoTopUpEnabled={
                            (currentOrder?.autoTopUps.length || 0) === 0
                          }
                          isParentLoading={isLoading}
                          logout={logout}
                          orderId={orderId}
                          reloadPurchase={fetchPurchaseDetails}
                        />
                      </Block>
                      <If
                        condition={(currentOrder?.autoTopUps.length || 0) !== 0}
                      >
                        <OptOutCard
                          isDisabled={
                            (currentOrder?.autoTopUps.length || 0) === 0
                          }
                          isParentLoading={isLoading}
                          logout={logout}
                          orderId={orderId}
                          reloadPurchase={fetchPurchaseDetails}
                        />
                      </If>
                      <If
                        condition={(currentOrder?.autoTopUps.length || 0) === 0}
                      >
                        <AutoTopUpCard
                          isDisabled={
                            (currentOrder?.autoTopUps.length || 0) !== 0
                          }
                          isParentLoading={isLoading}
                          logout={logout}
                          orderId={orderId}
                          reloadPurchase={fetchPurchaseDetails}
                        />
                      </If>
                    </EmptyState>
                  </Block>
                </Column>
              </Tab>
            </Tabs>
          </If>
        </Column>
      </Block>
    </MasterPage>
  );
};
export default ManageOrderPage;
