import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { ChevronRight } from "baseui/icon";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";
import { Mixpanel } from "../../utils/mixpanel";
import { Column, Row } from "../containers";
import { STYLES } from "./styles";

export const MoreInformation = ({
  setIsCompatibleDeviceListOpen,
  setIsCoverageInformationOpen,
  setIsDataGuideOpen,
}: {
  setIsCompatibleDeviceListOpen: (isOpen: boolean) => void;
  setIsCoverageInformationOpen: (isOpen: boolean) => void;
  setIsDataGuideOpen: (isOpen: boolean) => void;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();

  return (
    <StatefulPopover
      autoFocus
      content={({ close }) => (
        <Column>
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
            }}
          >
            <li
              className={css(STYLES.listItemOption(theme, false))}
              onClick={() => {
                close();
                Mixpanel.track("CTA", {
                  action: "click",
                  element: "coverage-information",
                  section: "purchase-form",
                });
                setIsCoverageInformationOpen(true);
              }}
            >
              <Row className={css(STYLES.listItemRow)}>
                {translate("purchase.more.coverageInfoButton")}
                <ChevronRight />
              </Row>
            </li>
            <li
              className={css(STYLES.listItemOption(theme, false))}
              onClick={() => {
                close();
                Mixpanel.track("CTA", {
                  action: "click",
                  element: "compatible-devices",
                  section: "purchase-form",
                });
                setIsCompatibleDeviceListOpen(true);
              }}
            >
              <Row className={css(STYLES.listItemRow)}>
                {translate("purchase.more.compatibilityButton")}
                <ChevronRight />
              </Row>
            </li>
            <li
              className={css(STYLES.listItemOption(theme, true))}
              onClick={() => {
                close();
                Mixpanel.track("CTA", {
                  action: "click",
                  element: "data-usage-guide",
                  section: "purchase-form",
                });
                setIsDataGuideOpen(true);
              }}
            >
              <Row className={css(STYLES.listItemRow)}>
                {translate("purchase.more.dataGuideButton")}
                <ChevronRight />
              </Row>
            </li>
          </ul>
        </Column>
      )}
      overrides={{
        Arrow: {
          style: {
            backgroundColor: "#fff",
          },
        },
        Inner: {
          style: {
            backgroundColor: "#fff",
            overflow: "hidden",
          },
        },
      }}
      placement={PLACEMENT.bottomRight}
      returnFocus
      showArrow
    >
      <Button
        kind={"primary"}
        size={"mini"}
        style={{ marginLeft: theme.sizing.scale300 }}
      >
        <strong>{translate("purchase.more.entry")}</strong>
      </Button>
    </StatefulPopover>
  );
};
