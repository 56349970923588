import { useStyletron } from "baseui";
import { Badge, COLOR } from "baseui/badge";
import { Block } from "baseui/block";
import { ChevronLeft, ChevronRight } from "baseui/icon";
import {
  HeadingLarge,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Pattern } from "../../../app/pattern";
import { useTranslation } from "../../../hooks/translate";
import { usePromotions } from "../../../hooks/use-promotions";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { ApiContext } from "../../../providers/api-provider";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import If from "../../if";
import { FontFamily, USE_FONT } from "../../overrides";
import arrow from "./assets/corner.png";
import star from "./assets/star.svg";
import { STYLES } from "./styles";

export const LpPricingSection = (): React.ReactNode => {
  const apiContext = React.useContext(ApiContext);
  const navigate = useNavigate();
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();
  const [refreshHasScroll, setRefreshHasScroll] = React.useState(0);
  const { isPromo, percentOff, previousValue } = usePromotions();

  React.useEffect(() => {
    setRefreshHasScroll((r) => r + 1);
  }, [apiContext.bundles]);

  const onSelectBundle = React.useCallback(
    (bundleName: string, price: number) => {
      Mixpanel.track("Pricing", {
        action: "selected",
        at: "pricing-section",
        bundle: bundleName,
        price: price,
      });
      navigate(`/bundle/${bundleName}`);
      scrollWithOffset("#purchase-form", -240);
    },
    [navigate, scrollWithOffset],
  );

  const hasScroll = React.useMemo(() => {
    if (screenWidth < theme.breakpoints.medium) {
      return false;
    }
    const scrollable = document.getElementById("scrollable-pricing-content");
    if (scrollable && scrollable.scrollWidth > scrollable.clientWidth) {
      return true;
    }
    return false;
  }, [screenWidth, refreshHasScroll]);

  return (
    <Column className={css(STYLES.pattern(theme))} id={"pricing-section"}>
      <Pattern useBanner={false} />
      <Column className={css(STYLES.headingWrapper(theme))}>
        <HeadingLarge
          color={theme.colors.contentInversePrimary}
          style={{
            ...USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "32px"),
            marginBottom: theme.sizing.scale600,
            textShadow: theme.lighting.shadow700,
          }}
        >
          {translate("lp.pricing.heading")}
        </HeadingLarge>
        <ParagraphMedium
          color={theme.colors.contentInversePrimary}
          style={{
            ...USE_FONT(FontFamily.DMSans, 400, "24px", "16px"),
            textShadow: theme.lighting.shadow700,
          }}
        >
          {translate("lp.pricing.caption")}
        </ParagraphMedium>
      </Column>
      <Row style={{ justifyContent: "center", zIndex: "1" }}>
        <ChevronLeft
          color="white"
          onClick={() =>
            document.getElementById("scrollable-pricing-content")?.scrollBy({
              behavior: "smooth",
              left: -256,
            })
          }
          size={theme.sizing.scale2400}
          style={{
            cursor: "pointer",
            display: hasScroll ? "block" : "none",
            marginTop: "128px",
          }}
        />
        <Row
          className={css(STYLES.scrollable(theme))}
          id="scrollable-pricing-content"
        >
          <Row className={css(STYLES.scrollableContent(theme, hasScroll))}>
            {apiContext.bundles?.map((bundle, index) => (
              <Badge
                color={COLOR.negative}
                content={translate("lp.offers.percent", {
                  percent: percentOff(index, bundle.price),
                })}
                key={`pricing-badge-${index}`}
                overrides={{
                  Badge: {
                    style: {
                      border: "2px solid white",
                      display: isPromo(index) ? "flex" : "none",
                      justifyContent: "center",
                      minHeight: "24px",
                    },
                  },
                }}
              >
                <Column
                  className={css(
                    STYLES.bundleWrapper(
                      theme,
                      index === 1,
                      index === (apiContext.bundles?.length || 0) - 1,
                    ),
                  )}
                  id={`bundle-card-${index}`}
                  key={bundle.name}
                >
                  <Column
                    className={css(STYLES.bundleItem(theme, screenWidth))}
                    onClick={() => onSelectBundle(bundle.name, bundle.price)}
                  >
                    <Column
                      className={css(STYLES.bundleAttributesWrapper(theme))}
                    >
                      <HeadingLarge
                        style={{
                          ...USE_FONT(
                            FontFamily.SatoshiVariable,
                            700,
                            "32px",
                            "28px",
                          ),
                          marginBottom: theme.sizing.scale100,
                        }}
                      >
                        {bundle.attributes.amount}
                      </HeadingLarge>
                      <ParagraphMedium
                        style={{
                          ...USE_FONT(FontFamily.DMSans, 400, "24px", "18px"),
                          marginBottom: theme.sizing.scale100,
                        }}
                      >
                        {translate("lp.pricing.item.duration", {
                          duration: bundle.attributes.duration,
                        })}
                      </ParagraphMedium>
                      <If
                        condition={
                          index === 1 && screenWidth > theme.breakpoints.small
                        }
                      >
                        <ParagraphSmall
                          style={{
                            ...USE_FONT(
                              FontFamily.DMSansItalic,
                              400,
                              "18px",
                              "14px",
                            ),
                            padding: `0 ${theme.sizing.scale300}`,
                            textAlign: "center",
                          }}
                        >
                          {translate("lp.pricing.item.caption", {
                            duration: bundle.attributes.duration,
                          })}
                        </ParagraphSmall>
                      </If>
                    </Column>
                    <Column className={css(STYLES.bundlePricingWrapper(theme))}>
                      <Column
                        style={{
                          alignItems: "center",
                          marginBottom: theme.sizing.scale500,
                        }}
                      >
                        <HeadingLarge
                          style={{
                            ...USE_FONT(
                              FontFamily.SatoshiVariable,
                              700,
                              "32px",
                            ),
                            position: "relative",
                          }}
                        >
                          {isPromo(index) ? (
                            <span
                              style={{
                                fontSize: "15px",
                                left: "-26px",
                                position: "absolute",
                                top: "-17px",
                              }}
                            >
                              {`$${previousValue(index, bundle.price)}`}
                              <img
                                src={arrow}
                                style={{
                                  left: "7px",
                                  position: "absolute",
                                  top: "22px",
                                }}
                                width={16}
                              />
                            </span>
                          ) : null}
                          {`$${bundle.price}`}
                        </HeadingLarge>
                        <ParagraphSmall
                          style={{
                            ...USE_FONT(FontFamily.DMSans, 400, "20px", "14px"),
                            marginTop: theme.sizing.scale100,
                            textAlign: "center",
                          }}
                        >
                          {translate("lp.pricing.item.each")}
                        </ParagraphSmall>
                      </Column>
                      <Block
                        style={{
                          ...USE_FONT(
                            FontFamily.DMSansBold,
                            700,
                            "20px",
                            "16px",
                          ),
                          background: index === 1 ? "#f7b500" : "transparent",
                          border: `2px solid ${index === 1 ? "#f7b500" : "#09150D"}`,
                          borderRadius: theme.sizing.scale4800,
                          boxShadow: theme.lighting.shadow700,
                          padding: `${theme.sizing.scale300} ${theme.sizing.scale600}`,
                          textWrap: "nowrap",
                        }}
                      >
                        {translate("lp.pricing.item.button")}
                      </Block>
                    </Column>
                  </Column>
                  <Row
                    className={css(STYLES.bestValueWrapper(theme, index === 1))}
                  >
                    <img
                      height={26}
                      src={star}
                      style={{ marginRight: theme.sizing.scale300 }}
                      width={26}
                    />
                    <ParagraphMedium
                      style={{
                        ...USE_FONT(
                          FontFamily.SatoshiVariable,
                          700,
                          "28px",
                          "22px",
                        ),
                        color: "#312400",
                      }}
                    >
                      <strong>{translate("lp.pricing.item.highlight")}</strong>
                    </ParagraphMedium>
                  </Row>
                </Column>
              </Badge>
            ))}
          </Row>
        </Row>
        <ChevronRight
          color="white"
          onClick={() =>
            document.getElementById("scrollable-pricing-content")?.scrollBy({
              behavior: "smooth",
              left: 256,
            })
          }
          size={theme.sizing.scale2400}
          style={{
            cursor: "pointer",
            display: hasScroll ? "block" : "none",
            marginTop: "128px",
          }}
        />
      </Row>
    </Column>
  );
};
