import { Block } from "baseui/block";
import React from "react";

import android from "./assets/android.png";
import iphone from "./assets/iphone.png";

export const PhoneFrame = ({
  image,
  type,
}: {
  image: string;
  type: "iPhone" | "Android";
}): React.ReactNode => {
  const imageStyle = {
    borderRadius: type === "iPhone" ? "8%" : "8%",
    height: type === "iPhone" ? "96%" : "88%",
    left: type === "iPhone" ? "5%" : "6%",
    position: "absolute",
    top: type === "iPhone" ? "2%" : "8%",
    width: type === "iPhone" ? "90%" : "87%",
  } as const;

  return (
    <Block
      style={{
        aspectRatio: "244 / 528",
        position: "relative",
        width: "244px",
      }}
    >
      <img alt="Open Settings" src={image} style={imageStyle} />
      <img
        src={type === "iPhone" ? iphone : android}
        style={{
          height: "100%",
          left: "0px",
          position: "absolute",
          top: "0px",
          width: "100%",
        }}
      />
    </Block>
  );
};
