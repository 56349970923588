import { useElements } from "@stripe/react-stripe-js";
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import Amex from "payment-icons/min/flat/amex.svg";
import Diners from "payment-icons/min/flat/diners.svg";
import Discover from "payment-icons/min/flat/discover.svg";
import Jcb from "payment-icons/min/flat/jcb.svg";
import MasterCard from "payment-icons/min/flat/mastercard.svg";
import UnionPay from "payment-icons/min/flat/unionpay.svg";
import Visa from "payment-icons/min/flat/visa.svg";
import GenericCard from "payment-icons/min/mono/default.svg";
import * as React from "react";

import { Row } from "../containers";
import { STYLES } from "./styles";

export const StripeInput = ({
  elementType,
  setIsValid,
  targetId,
}: {
  elementType: string;
  setIsValid: (isValid: boolean) => void;
  targetId: string;
}) => {
  const [css, theme] = useStyletron();
  const [cardBrand, setCardBrand] = React.useState(GenericCard);
  const elements = useElements();

  const addEventListeners = React.useCallback(
    (currentElement: StripeCardElement) => {
      if (!currentElement) {
        return;
      }
      currentElement.off("focus");
      currentElement.off("blur");
      currentElement.off("change");
      currentElement.on("focus", () => {
        const parent = document.getElementById(targetId);
        if (parent) {
          parent.style.borderColor = "rgb(0, 0, 0)";
        }
      });
      currentElement.on("blur", () => {
        const parent = document.getElementById(targetId);
        if (parent) {
          parent.style.borderColor = "rgb(238, 238, 238)";
        }
      });
      currentElement.on("change", (event: StripeCardElementChangeEvent) => {
        setIsValid(event.complete);
        if (event.brand) {
          switch (event.brand) {
            case "visa":
              setCardBrand(Visa);
              break;
            case "mastercard":
              setCardBrand(MasterCard);
              break;
            case "amex":
              setCardBrand(Amex);
              break;
            case "discover":
              setCardBrand(Discover);
              break;
            case "diners":
              setCardBrand(Diners);
              break;
            case "jcb":
              setCardBrand(Jcb);
              break;
            case "unionpay":
              setCardBrand(UnionPay);
              break;
            default:
              setCardBrand(GenericCard);
          }
        }
      });
    },
    [],
  );

  const createStripeElement = (elementType: string, targetId: string) => {
    // @ts-expect-error strict elementType error
    const currentElement = elements?.getElement(elementType);
    if (!currentElement) {
      // @ts-expect-error elementType error
      const cardElement = elements?.create(elementType, {
        style: {
          base: {
            backgroundColor: "rgb(238, 238, 238)",
            fontSize: "16px",
          },
        },
      });
      cardElement?.mount(`#${targetId}`);
      addEventListeners(cardElement as StripeCardElement);
    } else {
      const target = document.getElementById(targetId);
      if (target) {
        target.textContent = "";
        currentElement.mount(`#${targetId}`);
        addEventListeners(currentElement as StripeCardElement);
      }
    }
  };

  React.useEffect(() => {
    createStripeElement(elementType, targetId);
  }, [elementType, elements]);

  if (elementType === "cardNumber" && cardBrand) {
    return (
      <Row className={css(STYLES.stripeElementWithCardWrapper)}>
        <img src={cardBrand} width={theme.typography.HeadingMedium.fontSize} />
        <Block className={css(STYLES.stripeElementWithCard)} id={targetId} />
      </Row>
    );
  }

  return (
    <React.Fragment>
      <Block className={css(STYLES.stripeElementWrapper)} id={targetId} />
    </React.Fragment>
  );
};
