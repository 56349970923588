import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme) =>
    ({
      background: "#FFF",
      borderRadius: theme.sizing.scale300,
      bottom: theme.sizing.scale1600,
      boxShadow: "rgba(0, 0, 0, 0.4) 0px 8px 24px",
      left: "50%",
      maxWidth: "480px",
      padding: theme.sizing.scale800,
      position: "fixed",
      transform: "translateX(-50%)",
      width: "80%",
    }) as const,
};
