export const FAQ: {
  items: Array<{
    answer: string;
    id: string;
    link?: { label: string; shouldScroll: boolean; to: string };
    question: string;
  }>;
} = {
  items: [
    {
      answer:
        "An eSIM, or “embedded SIM,” is a digital version of a traditional SIM card, already integrated into your smartphone or tablet. It's the next step in SIM card evolution—no more tiny slots or plastic cards to manage! With eSIM, you can say goodbye to swapping SIM cards and embrace a seamless, more convenient way to <strong>Stay Connected</strong>. <br><br> Before purchasing, make sure your device is both network unlocked and eSIM-compatible. All iPhones since 2018 and most newer Android models support eSIM. You can check your device's compatibility using the link below.",
      id: "faq-what-is-esim-device-compatibility",
      link: {
        label: "Check your device compatibility",
        shouldScroll: false,
        to: "/?device-compatibility",
      },
      question: "What's an eSIM? Can my phone use eSIMs?",
    },
    {
      answer:
        "Setting up your Stay Connected eSIM is simple, and we'll provide a step-by-step guide after your purchase via email). You can also visit our eSIM installation guide through the link below.",
      id: "faq-how-install-esim",

      link: {
        label: "eSIM Installation Guide",
        shouldScroll: true,
        to: "/esim-installation-guide",
      },

      question: "How do I install my Stay Connected eSIM on my phone?",
    },
    {
      answer:
        "You won't be able to make traditional network calls or send SMS using your Stay Connected eSIM, but you can keep your existing SIM active to retain access to these services. Just be sure to check with your network provider about any potential charges before you travel. <br><br> Remember, while Stay Connected eSIM plans are data-only, you can still use apps like WhatsApp, Messenger, iMessage, and others for messaging and voice calls. These apps work seamlessly with the data connection provided by your Stay Connected eSIM.",
      id: "faq-esim-can-I-make-calls-or-sms",
      link: undefined,
      question: "Can I make calls and send SMS with my Stay Connected eSIM?",
    },
    {
      answer:
        "Yes, many dual-SIM devices allow you to use both an eSIM and a physical SIM card at the same time, enabling you to manage two phone numbers or plans on one device. This setup is ideal for keeping work and personal calls separate or using local and international plans simultaneously. <br><br> This means you can use your Stay Connected eSIM for data while keeping your physical SIM inserted into your phone (just make sure to disable data on it while abroad to avoid roaming fees!).",
      id: "faq-esim-use-alongside-physical-sim",
      link: undefined,
      question:
        "Can I use my eSIM alongside a physical SIM card in dual-SIM devices?",
    },
    {
      answer:
        "You can manage your SIM settings in your device's mobile data options, choosing which SIM to enable or disable. To ensure your Stay Connected eSIM is used while traveling, turn on Data Roaming for your Stay Connected eSIM and turn it off for your regular SIM. Additionally, make sure your Stay Connected eSIM is set as the preferred SIM for mobile data.",
      id: "faq-phone-use-esim-instead-of-sim",
      link: undefined,
      question: "How does my phone know to use my eSIM over my regular SIM?",
    },
    {
      answer:
        "We'll send you regular updates (at 50%, 80% and 100% data used), and you can check the data used on your Stay Connected eSIM in our <u>Manage My Order</u> page. You can also use the order page to top-up (manually or automatically) in case you need more data or days. <br><br> We will notify you when you're nearing your data limit, but if you do run out, topping up or purchasing another eSIM is simple—just make sure you're connected to Wi-Fi first. Unlike your regular network provider, we'll never let you exceed your data allowance and charge you extra!",
      id: "faq-remaining-data-run-out",
      link: undefined,
      question: "How do I know how much I have used? What if my data runs out?",
    },
    {
      answer:
        "Stay Connected eSIM plans simplify staying connected to the internet while you travel. Whether you're checking social media, navigating unfamiliar areas, or streaming movies on the go, we have the perfect plan for you. <br><br> Unsure how much data to purchase? We have quick a guide on how much data popular online activities typically use. But <strong>please note</strong> that your accommodation and other locations will likely offer Wi-Fi so you can use it and not consume from your eSIM data plan.",
      id: "faq-how-much-data-I-need",
      link: {
        label: "Check how much data do you need",
        shouldScroll: false,
        to: "/?data-usage-guide",
      },
      question: "How much data will I need?",
    },
  ],
};
