import { useStyletron } from "baseui";
import { Spinner } from "baseui/spinner";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";

const Loading = ({
  children,
  isLoading,
}: {
  children?: React.ReactNode;
  isLoading: boolean;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();

  if (!isLoading) {
    return children || null;
  }

  return (
    <div
      className={css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      })}
    >
      <Spinner
        $borderWidth={theme.sizing.scale100}
        $size={theme.sizing.scale1600}
      />
      <div
        className={css({
          ...theme.typography.HeadingXSmall,
          paddingBlockEnd: theme.sizing.scale500,
          paddingBlockStart: theme.sizing.scale650,
        })}
      >
        {translate("loading.title")}
      </div>
    </div>
  );
};

export default Loading;
