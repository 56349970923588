import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export enum FontFamily {
  UberMoveText = "UberMoveText",
  DMSans = "DMSans-Regular",
  DMSansMedium = "DMSans-Medium",
  DMSansBold = "DMSans-Bold",
  DMSansItalic = "DMSans-Italic",
  SatoshiVariable = "Satoshi-Variable",
}

export const USE_FONT = (
  font: FontFamily | string,
  weight: number,
  lineHeight: string,
  size?: string,
) => ({
  fontFamily: `${font}, UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif`,
  fontSize: size,
  fontStyle: font === FontFamily.DMSansItalic ? "italic" : "normal",
  fontWeight: weight,
  lineHeight,
  marginBottom: "0px",
  marginLeft: "0px",
  marginRight: "0px",
  marginTop: "0px",
});

export const STRONG_TEXT_SHADOW = (color: string) =>
  `-0.5px -0.5px 0 ${color},-0.5px -0.5px 0 ${color},-0.5px 0px 0 ${color},-0.5px 0.5px 0 ${color},-0.5px 0.5px 0 ${color},-0.5px -0.5px 0 ${color},-0.5px -0.5px 0 ${color},-0.5px 0px 0 ${color},-0.5px 0.5px 0 ${color},-0.5px 0.5px 0 ${color},0px -0.5px 0 ${color},0px -0.5px 0 ${color},0px 0px 0 ${color},0px 0.5px 0 ${color},0px 0.5px 0 ${color},0.5px -0.5px 0 ${color},0.5px -0.5px 0 ${color},0.5px 0px 0 ${color},0.5px 0.5px 0 ${color},0.5px 0.5px 0 ${color},0.5px -0.5px 0 ${color},0.5px -0.5px 0 ${color},0.5px 0px 0 ${color},0.5px 0.5px 0 ${color},0.5px 0.5px 0 ${color}`;

export const LP_BUTTON_PRIMARY = (
  theme: Theme,
  additionalStyles: object | undefined = {},
) => ({
  BaseButton: {
    style: {
      ":active": {
        backgroundColor: (theme as CustomTheme).colors.lp.primaryButtonHover,
      },
      ":hover": {
        backgroundColor: (theme as CustomTheme).colors.lp.primaryButton,
      },
      backgroundColor: (theme as CustomTheme).colors.lp.primaryButton,
      ...additionalStyles,
    },
  },
});

export const FORM_CONTROL_CAPTION_OVERRIDE = {
  Caption: {
    style: {
      marginBottom: "0",
    },
  },
};

export const FORM_CONTROL_LABEL_OVERRIDE = {
  Label: {
    style: {
      ...USE_FONT(FontFamily.DMSans, 500, "20px", "16px"),
    },
  },
};
