import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { HeadingSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../../hooks/translate";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { scrollWithOffset } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel";
import { Column, Row } from "../../containers";
import { FontFamily, LP_BUTTON_PRIMARY, USE_FONT } from "../../overrides";
import { PromoButton } from "../../promo-button";
import { LockerIcon } from "./assets/locker";
import { SimIcon } from "./assets/sim";
import { ThunderIcon } from "./assets/thunder";
import { WorldIcon } from "./assets/world";
import { STYLES } from "./styles";

export const LpBenefitsSection = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const { width: screenWidth } = useScreenSize();

  const getIcon = (icon: string) => {
    switch (icon) {
      case "thunder":
        return (
          <ThunderIcon
            color={translate("theme.colors.lp.benefitsIconDetail")}
            height={screenWidth > theme.breakpoints.medium ? "140" : "90"}
            width={screenWidth > theme.breakpoints.medium ? "140" : "90"}
          />
        );
      case "world":
        return (
          <WorldIcon
            color={translate("theme.colors.lp.benefitsIconDetail")}
            height={screenWidth > theme.breakpoints.medium ? "140" : "90"}
            width={screenWidth > theme.breakpoints.medium ? "140" : "90"}
          />
        );
      case "sim":
        return (
          <SimIcon
            color={translate("theme.colors.lp.benefitsIconDetail")}
            height={screenWidth > theme.breakpoints.medium ? "140" : "90"}
            width={screenWidth > theme.breakpoints.medium ? "140" : "90"}
          />
        );
      case "locker":
        return (
          <LockerIcon
            color={translate("theme.colors.lp.benefitsIconDetail")}
            height={screenWidth > theme.breakpoints.medium ? "140" : "90"}
            width={screenWidth > theme.breakpoints.medium ? "140" : "90"}
          />
        );
    }
    return null;
  };

  const items = [
    {
      description: translate("lp.benefits.items.first.caption"),
      icon: getIcon(translate("lp.benefits.items.first.icon")),
      title: translate("lp.benefits.items.first.heading"),
    },
    {
      description: translate("lp.benefits.items.second.caption"),
      icon: getIcon(translate("lp.benefits.items.second.icon")),
      title: translate("lp.benefits.items.second.heading"),
    },
    {
      description: translate("lp.benefits.items.third.caption"),
      icon: getIcon(translate("lp.benefits.items.third.icon")),
      title: translate("lp.benefits.items.third.heading"),
    },
    {
      description: translate("lp.benefits.items.fourth.caption"),
      icon: getIcon(translate("lp.benefits.items.fourth.icon")),
      title: translate("lp.benefits.items.fourth.heading"),
    },
  ];

  return (
    <Row className={css(STYLES.container(theme))}>
      <Column className={css(STYLES.wrapper)}>
        <Column className={css(STYLES.headingWrapper(theme))}>
          <HeadingSmall
            style={USE_FONT(FontFamily.SatoshiVariable, 700, "48px", "28px")}
          >
            {translate("lp.benefits.heading")}
          </HeadingSmall>
          <ParagraphMedium
            style={USE_FONT(FontFamily.DMSans, 400, "24px", "16px")}
          >
            {translate("lp.benefits.caption")}
          </ParagraphMedium>
        </Column>
        <Row className={css(STYLES.grid(theme, screenWidth))}>
          {items.map((item, index) => (
            <Row
              className={css(STYLES.gridItem(theme))}
              key={`benefit-${index}`}
            >
              <Column
                style={{
                  marginBottom: theme.sizing.scale600,
                  maxWidth: "435px",
                }}
              >
                <LabelMedium
                  style={USE_FONT(
                    FontFamily.SatoshiVariable,
                    700,
                    "32px",
                    "20px",
                  )}
                >
                  {item.title}
                </LabelMedium>
                <ParagraphMedium
                  style={USE_FONT(FontFamily.DMSans, 400, "26px", "14px")}
                >
                  {item.description}
                </ParagraphMedium>
              </Column>
              {item.icon}
            </Row>
          ))}
        </Row>
        <PromoButton
          content="Claim Discount"
          style={{
            marginBottom: theme.sizing.scale600,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.sizing.scale900,
          }}
        >
          <Button
            onClick={() => {
              Mixpanel.track("CTA", {
                action: "click",
                element: "get-access-now",
                section: "benefits",
              });
              scrollWithOffset("#purchase-form", -240);
            }}
            overrides={LP_BUTTON_PRIMARY(theme, {
              ...USE_FONT(FontFamily.DMSans, 500, "20px", "18px"),
              paddingLeft: theme.sizing.scale1200,
              paddingRight: theme.sizing.scale1200,
            })}
          >
            {translate("lp.benefits.button")}
          </Button>
        </PromoButton>
      </Column>
    </Row>
  );
};
