import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Block } from "baseui/block";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import { useTranslation } from "../hooks/translate";
import { ApiProvider } from "../providers/api-provider";
import { StripeWalletProvider } from "../providers/api-provider/stripe";
import AppRoutes from "./routes";
import ThemeProvider from "./theme";

const engine = new Styletron();
const stripePromise = loadStripe(
  process.env.NODE_ENV === "development"
    ? "pk_test_51Pb5czGEW7E4y3uyvCe5DfulfAfLULHLykmpUk835t7d3KPsMIwK6XRfDvUA26ewgi8CJZTlRnUQ2FMfBRz5Y9Ub009apE3sNg"
    : "pk_live_51Pb5czGEW7E4y3uyf2AKSACXdOeW9XbOnXOa1AKlpDP6TyD47xIeGMnWCa2u9cOUtFHpfbmaL1puqo4uyEkAXioZ00DRIMPs7F",
);

const Root = (): React.ReactNode => {
  const { translate } = useTranslation();
  const country = translate("country.name");

  return (
    <StyletronProvider debugAfterHydration value={engine}>
      <HelmetProvider>
        <Helmet>
          <title>Stay Connected {country}</title>
          <meta
            content="width=device-width, initial-scale=1 initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            name="viewport"
          />
          <style type="text/css">{"body{margin: 0px;}"}</style>
        </Helmet>
        <ThemeProvider>
          <ApiProvider>
            <Elements
              options={{
                amount: 1099,
                currency: "usd",
                mode: "payment",
                paymentMethodCreation: "manual",
              }}
              stripe={stripePromise}
            >
              <StripeWalletProvider>
                <AppRoutes />
                <ToasterContainer
                  autoHideDuration={2500}
                  placement={PLACEMENT.topRight}
                />
                <Block id="modal-mount-node" />
              </StripeWalletProvider>
            </Elements>
          </ApiProvider>
        </ThemeProvider>
      </HelmetProvider>
    </StyletronProvider>
  );
};

export default Root;
