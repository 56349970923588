import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme, padding: string | undefined) => ({
    alignItems: "center",
    background: "#FFF",
    justifyContent: "center",
    padding: padding || theme.sizing.scale1600,
    [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: padding || `${theme.sizing.scale1600} ${theme.sizing.scale600}`,
    },
  }),
  textWrapper: (theme: Theme) => ({
    marginBottom: theme.sizing.scale600,
  }),
  wrapper: (maxWidth: string | undefined) => ({
    maxWidth: maxWidth || "600px",
    width: "100%",
  }),
};
