import { AxiosError } from "axios";
import { Badge, COLOR } from "baseui/badge";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { toaster } from "baseui/toast";
import { LabelMedium, ParagraphXSmall } from "baseui/typography";
import * as React from "react";
import Swal from "sweetalert2";

import { API } from "../../api";
import { useTranslation } from "../../hooks/translate";
import { Column } from "../containers";
import If from "../if";
import Loading from "../loading";

export const OptOutCard = ({
  isDisabled,
  isParentLoading,
  logout,
  orderId,
  reloadPurchase,
}: {
  isDisabled: boolean;
  isParentLoading: boolean;
  logout: (sessionExpired: boolean, redirectTo: string) => void;
  orderId: string;
  reloadPurchase: () => void;
}): React.ReactNode => {
  const { translate } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cardState, setCardState] = React.useState(1);

  const optOutConfirm = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await API.optOutConfirm({
        body: { order_id: orderId },
      });
      if (result.status) {
        Swal.fire({
          confirmButtonColor: "#000",
          confirmButtonText: translate(
            "manage.cards.optOut.states.third.button",
          ),
          icon: "success",
          text: translate("manage.cards.optOut.states.third.description"),
          title: "Auto top-up disabled",
        });
        reloadPurchase();
      } else {
        toaster.warning("Something went wrong, please try again.");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if ((error && (error as AxiosError)?.response?.status) === 401) {
        logout(true, "/access");
      } else {
        console.error(error);
      }
    }
  }, [orderId]);

  return (
    <Card
      title={
        <Column>
          <LabelMedium margin={0}>
            {translate("manage.cards.optOut.title")}
          </LabelMedium>
          <ParagraphXSmall margin={0}>
            {translate("manage.cards.optOut.subtitle", { order: orderId })}
          </ParagraphXSmall>
          <If condition={isDisabled}>
            <Block>
              <Badge
                color={COLOR.warning}
                content={translate("manage.cards.optOut.hint")}
              />
            </Block>
          </If>
        </Column>
      }
    >
      <Loading isLoading={isParentLoading}>
        <If condition={cardState === 1}>
          <StyledBody>
            {translate("manage.cards.optOut.states.first.description")}
          </StyledBody>
          <StyledAction>
            <Button
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() => {
                setCardState(2);
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              {translate("manage.cards.optOut.states.first.button")}
            </Button>
          </StyledAction>
        </If>
        <If condition={cardState === 2}>
          <StyledBody>
            {translate("manage.cards.optOut.states.second.description")}
          </StyledBody>
          <StyledAction>
            <Column>
              <Button
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={optOutConfirm}
                overrides={{
                  BaseButton: {
                    style: { marginBottom: "8px", width: "100%" },
                  },
                }}
              >
                {translate("manage.cards.optOut.states.second.button")}
              </Button>
              <Button
                kind="tertiary"
                onClick={() => {
                  setCardState(1);
                }}
                overrides={{ BaseButton: { style: { width: "100%" } } }}
              >
                {translate("manage.cards.optOut.states.second.dismiss")}
              </Button>
            </Column>
          </StyledAction>
        </If>
      </Loading>
    </Card>
  );
};
