import * as React from "react";

const If = ({
  children,
  condition,
}: {
  children?: React.ReactNode;
  condition: boolean;
}): React.ReactNode => {
  if (condition) {
    return children;
  }
  return null;
};

export default If;
