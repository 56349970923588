import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import {
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
  ParagraphLarge,
} from "baseui/typography";
import * as React from "react";

import { useTranslation } from "../../hooks/translate";
import { ApiContext } from "../../providers/api-provider";
import { NetworkT } from "../../providers/api-provider/types";
import { Column } from "../containers";
import If from "../if";
import { STYLES } from "./styles";

// TO-DO: make table look nicer (same font as rest of page?)

export const CoverageInformation = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { translate } = useTranslation();
  const isRegional = Boolean(translate("country.isRegional"));

  const apiContext = React.useContext(ApiContext);
  const networks = React.useMemo(
    () =>
      ((apiContext.bundles || [])[0]?.networks || []).reduce(
        (accumulator: Array<NetworkT>, current) => {
          const exists = accumulator.find((item: NetworkT) => {
            return item.name === current.name;
          });
          if (!exists) {
            accumulator = accumulator.concat(current);
          }
          return accumulator;
        },
        [],
      ),
    [apiContext.bundles],
  );

  return (
    <Modal
      animate
      autoFocus
      closeable
      isOpen={isOpen}
      mountNode={document.querySelector("#modal-mount-node") as HTMLElement}
      onClose={() => setIsOpen(false)}
      role={ROLE.dialog}
      size={SIZE.auto}
    >
      <ModalHeader>
        <HeadingMedium margin={0}>{translate("coverage.title")}</HeadingMedium>
      </ModalHeader>
      <ModalBody>
        <Column className={css(STYLES.container)}>
          <Column className={css(STYLES.wrapper)}>
            <HeadingSmall
              style={{ marginBottom: theme.sizing.scale600, marginTop: 0 }}
            >
              {translate("coverage.network.title")}
            </HeadingSmall>
            <Column className={css(STYLES.textWrapper(theme))}>
              <TableBuilder
                data={networks}
                divider="grid"
                overrides={{
                  Root: {
                    style: {
                      marginBottom: theme.sizing.scale600,
                      maxWidth: "calc(100vw - 80px)",
                    },
                  },
                  TableBodyCell: {
                    style: {
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingTop: "8px",
                      verticalAlign: "center",
                    },
                  },
                  TableHeadCell: {
                    style: {
                      paddingBottom: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingTop: "8px",
                    },
                  },
                }}
              >
                <TableBuilderColumn
                  header={translate("coverage.network.provider")}
                >
                  {(row: NetworkT) => row.brandName}
                </TableBuilderColumn>
                <TableBuilderColumn
                  header={translate("coverage.network.speed")}
                >
                  {(row: NetworkT) => row.speed?.join(", ")}
                </TableBuilderColumn>
              </TableBuilder>
            </Column>
            <HeadingXSmall
              style={{ marginBottom: theme.sizing.scale300, marginTop: 0 }}
            >
              {translate("coverage.info.title")}
            </HeadingXSmall>
            <ul style={{ marginBottom: theme.sizing.scale300, marginTop: 0 }}>
              <If condition={isRegional}>
                <li>
                  <ParagraphLarge margin={0}>
                    {translate("coverage.info.extended", {
                      region: translate("country.region"),
                    })}
                  </ParagraphLarge>
                </li>
              </If>
              <li>
                <ParagraphLarge margin={0}>
                  {translate("coverage.info.first")}
                </ParagraphLarge>
              </li>
              <li>
                <ParagraphLarge margin={0}>
                  {translate("coverage.info.second")}
                </ParagraphLarge>
              </li>
              <li>
                <ParagraphLarge margin={0}>
                  {translate("coverage.info.third")}
                </ParagraphLarge>
              </li>
              <li>
                <ParagraphLarge margin={0}>
                  {translate("coverage.info.fourth")}
                </ParagraphLarge>
              </li>
            </ul>
          </Column>
        </Column>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)} style={{ width: "100%" }}>
          {translate("coverage.confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
