import * as React from "react";

import { ApiContext } from "../providers/api-provider";

export const usePromotions = () => {
  const apiContext = React.useContext(ApiContext);

  const shouldSurfacePromos = React.useCallback(() => true, []);

  const isPromo = React.useCallback(
    (index: number) => {
      if (!shouldSurfacePromos()) {
        return false;
      }
      return index <= 2 && index !== (apiContext.bundles?.length || 0) - 1;
    },
    [apiContext.bundles],
  );

  const basePercentOff = React.useCallback(
    (index: number) => {
      if (!shouldSurfacePromos()) {
        return 0;
      }
      if (!isPromo(index)) {
        return 0;
      }
      return parseInt(`${index + 1}0`);
    },
    [apiContext.bundles],
  );

  const percentOff = React.useCallback(
    (index: number, price: number) => {
      if (!shouldSurfacePromos()) {
        return 0;
      }
      if (!isPromo(index)) {
        return 0;
      }
      const previousPrice = parseFloat(previousValue(index, price).toString());
      // eslint-disable-next-line prettier/prettier
      return Math.ceil(((previousPrice-price)/previousPrice) * 100);
    },
    [apiContext.bundles],
  );

  const previousValue = React.useCallback(
    (index: number, price: number) => {
      if (!shouldSurfacePromos()) {
        return price;
      }
      if (!isPromo(index)) {
        return price;
      }
      const percentage = (100 - basePercentOff(index)) / 100;
      return Math.ceil(price / percentage).toFixed(0);
    },
    [apiContext.bundles],
  );

  return { isPromo, percentOff, previousValue, shouldSurfacePromos };
};
