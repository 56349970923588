import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  aboutContainer: (theme: Theme) =>
    ({
      alignItems: "end",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: "start",
        flexDirection: "column",
      },
    }) as const,
  aboutTextContainer: (theme: Theme) =>
    ({
      justifyContent: "center",
      marginLeft: theme.sizing.scale900,
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        marginLeft: "0px",
        marginTop: theme.sizing.scale800,
      },
    }) as const,
  container: (theme: Theme) => ({
    backgroundColor: (theme as CustomTheme).custom.footerColor,
    margin: "0 auto",
    maxWidth: "1920px",
    minHeight: "230px",
    paddingBottom: theme.sizing.scale900,
    paddingTop: theme.sizing.scale900,
  }),
  innerContainer: (theme: Theme) =>
    ({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: "0 auto",
      maxWidth: "1244px",
      padding: `${theme.sizing.scale900}`,
    }) as const,
  linksContainer: (theme: Theme) =>
    ({
      display: "flex",
      flexDirection: "row",
      marginTop: theme.sizing.scale1200,
      width: "100%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        flexDirection: "column",
        marginTop: theme.sizing.scale800,
      },
    }) as const,
  linksItemContainer: (theme: Theme, isLast: boolean) =>
    ({
      alignItems: "center",
      justifyContent: isLast ? "end" : "start",
      width: "50%",
      [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
        justifyContent: "start",
        marginTop: isLast ? theme.sizing.scale800 : "0px",
        width: "100%",
      },
    }) as const,
  socialMediaContainer: (theme: Theme) => ({
    color: theme.colors.contentInversePrimary,
    fontSize: theme.typography.ParagraphLarge.fontSize,
    justifyContent: "center",
    marginLeft: theme.sizing.scale600,
    [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
      justifyContent: "center",
    },
  }),
};
