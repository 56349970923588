import { useStyletron } from "baseui";
import { StyledBodyCell, StyledHeadCell, StyledTable } from "baseui/table-grid";
import moment from "moment";
import * as React from "react";

import { PurchaseHistoryResponseT } from "../../api/types";
import { useTranslation } from "../../hooks/translate";
import Loading from "../loading";
import { STYLES } from "./styles";

export const PaymentsCard = ({
  isParentLoading,
  purchaseDetails,
}: {
  isParentLoading: boolean;
  purchaseDetails: PurchaseHistoryResponseT | undefined;
}): React.ReactNode => {
  const [css] = useStyletron();
  const { translate } = useTranslation();

  const payments = React.useMemo(() => {
    return (purchaseDetails?.customer?.payments || []).sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at),
    );
  }, [purchaseDetails?.customer?.payments]);

  const rows = payments.map((payment) => [
    payment.type === "PURCHASE"
      ? payment.orders.map((order) => order.id).join(" ")
      : payment.topUps.map((topUp) => topUp.order_id).join(", "),
    payment.type === "PURCHASE" ? "Purchase" : "Top-Up",
    `${payment.status.charAt(0).toUpperCase()}${payment.status.slice(1).toLocaleLowerCase()}`,
    `$${parseFloat(payment.price).toFixed(2)}`,
    payment.paid_at ? moment(payment.paid_at).format("YYYY-MM-DD") : "-",
  ]);

  return (
    <Loading isLoading={isParentLoading}>
      <StyledTable
        $gridTemplateColumns={[
          "minmax(125px, 1fr)",
          "100px",
          "100px",
          "100px",
          "115px",
        ].join(" ")}
        $style={STYLES.table}
        role="grid"
      >
        <div className={css(STYLES.tableRow)} role="row">
          <StyledHeadCell>
            {translate("manage.cards.payments.firstColumn")}
          </StyledHeadCell>
          <StyledHeadCell>
            {translate("manage.cards.payments.secondColumn")}
          </StyledHeadCell>
          <StyledHeadCell>
            {translate("manage.cards.payments.thirdColumn")}
          </StyledHeadCell>
          <StyledHeadCell>
            {translate("manage.cards.payments.fourthColumn")}
          </StyledHeadCell>
          <StyledHeadCell>
            {translate("manage.cards.payments.fifthColumn")}
          </StyledHeadCell>
        </div>
        {rows.map((row, index: number) => {
          const striped = index % 2 === 0;
          return (
            <div className={css(STYLES.tableRow)} key={index} role="row">
              <StyledBodyCell $striped={striped}>{row[0]}</StyledBodyCell>
              <StyledBodyCell $striped={striped}>{row[1]}</StyledBodyCell>
              <StyledBodyCell $striped={striped}>{row[2]}</StyledBodyCell>
              <StyledBodyCell $striped={striped}>{row[3]}</StyledBodyCell>
              <StyledBodyCell $striped={striped}>{row[4]}</StyledBodyCell>
            </div>
          );
        })}
      </StyledTable>
    </Loading>
  );
};
