import base from "./base";
import brazil from "./brazil";
import chile from "./chile";
import italy from "./italy";
import malta from "./malta";

export const locales = {
  base: {
    translation: base,
  },
  brazil: {
    translation: brazil,
  },
  chile: {
    translation: chile,
  },
  italy: {
    translation: italy,
  },
  malta: {
    translation: malta,
  },
};
